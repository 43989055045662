$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;
.MuiDialog-paperWidthSm {
    max-width: 480px;
}
#customized-dialog-title {
    border-bottom: 1px solid #f1efef;
}
.c-dialog-container {
    padding: 24px;
    .logo {
        text-align: center;
        img {
            height: 92px;
        }
    }
    p {
        width: 100%;
    }

    .login-container {
        .button-box {
            padding-left: 12px;
            .sign-btn {
                background-color: #ee2738 !important;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
            }
        }

        .input-box {
            .inputField {
                width: 100%;
                height: 42px;
            }
        }
    }
    .coupon-list-container {
        margin-top: 32px;
        ul {
            width: 100%;
            li {
                border: 1px solid #f1efef;
                .avatar{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                }
                .c-text {
                    padding: 6px;
                    h3 {
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                    p {
                        font-size: 12px;
                        span {
                            color: #ee2738;
                        }
                    }
                }
                .redIcon {
                    color: #ee2738;
                    font-size: 30px;
                }
            }
        }
    }

}
