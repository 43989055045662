.venue_manager_venue_add{
  margin-top: 59px;
  font-family: Raleway;
  width: 100%;
  // padding-bottom: 110px;
  .plantext {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #62717a;
    // margin: 30px auto 0;
    width: 50%;
    text-align: center;
    a{
      cursor: pointer;
    }

  .red-text {
    color: #f12331;
    text-decoration: underline;
  }
}
.unsubscribe {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #62717a;
  // margin: 30px auto 0;
  width: 50%;
  float: right;
  padding-right: 6px;
  padding-top: 4px;

  a {
    cursor: pointer;
  }

  .red-text {
    color: #f12331;
    text-decoration: underline;
  }
}
  .venue-manager-header {
    padding: 0 30px;
    @media screen and (max-width:767px){
      padding: 0px 5px;
    }
    .desktop-disp-none{
      @media screen and (min-width:768px){
        display: none;
      }
      .plantext{
        width: 100%;
        padding-bottom: 20px;
        text-align: left;
        padding-left: 15px;
      }
    }

    .header {
      border-bottom-style: none !important;
      height: auto;
      display: flex;
      align-items: center;
      .venue-details{
        width: 36%;
      }
      .mobile-disp-none{
        @media screen and (max-width:767px){
          display: none;
        }
      }
      .plan-validity-text{
        .plantext{
          width: 100%;
        }
      }
    }

    .venue-manager-image {
      float: left;
      text-align: left;
      padding: 0 0 ;
      @media screen and (max-width:767px){
        padding: 0px;
      }

      .image-div {
        margin: 10px 0 0 10px;
        height: auto;
        background: none;
      }

      .image {
        width: 60px;
        height: 60px;
        border: solid 2px #1397e1;
        border-radius: 50%;
      }
    }

    .venue-details {
      padding: 10px 0 0 10px;
      margin: 0;
      // width: auto;
      @media screen and (max-width:767px){
          width:70%;
          padding: 0px;
      }

      .details {
        margin: 10px 0 0 10px;

        .title {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          word-wrap: break-word;
          color: var(--black);
        }

        .blue-text {
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
          color: #1397e1;
        }
      }
    }

    // .subtext {
    //   font-family: Raleway;
    //   font-size: 12px;
    //   font-weight: 500;
    //   font-style: normal;
    //   font-stretch: normal;
    //   line-height: 1.33;
    //   letter-spacing: normal;
    //   color: #62717a;
    //   margin: 30px auto 0;
    //   width: 50%;
    //   text-align: center;
    //   a{
    //     cursor: pointer;
    //   }

    //   .red-text {
    //     color: #f12331;
    //     text-decoration: underline;
    //   }
    // }
  }

   .venue-container {
     margin-bottom: 30px;
   }

  .grey-div {
    width: 100%;
    height: auto;
    vertical-align: center;
    background-color: #f0f0f0;
    padding-top: 40px;
    @media screen and (max-width:767px) {
      padding-top: 25px
    }
  }

  .light-grey-div {
    width: 100%;
    height: 374px;
    vertical-align: center;
    background-color: #f7f7f7;
    padding-top: 40px;
  }

  .title-header {
    width: 80%;
    margin: auto;
    @media screen and (max-width:767px) {
      width: 90% !important;
      padding: 0px !important;
    }
  }

  .my-venues {
    width: 80%;
    background-color: #ffffff;
    align-items: center;
    margin: auto;
    border: solid 1px #e1e1e1;
    @media screen and (max-width:767px){
      width: 90%;
      padding: 0px 15px;
    }
   .add-space-button-div{
     text-align: right !important;
     @media screen and (max-width:767px){
      text-align: left !important;
    }
    .white-btn-div {
      display: inline-block;
      @media screen and (max-width:767px){
        display: block;
      }
    }
   }
  }
  .button_alignment {
    padding-bottom: 140px;
  }
  .button_padding{
    padding: 0px 10px ;
  }
  .white-button {

    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738;
    margin: auto;
    text-decoration: none;
    display: inline-block;
 

    span {
      vertical-align: -webkit-baseline-middle;
      vertical-align: middle;
    }
  }

  .red-button {
    width: 144px;
    height: 36px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    padding: 5px;
    float: right;
 

    span {
      vertical-align: -webkit-baseline-middle;
      vertical-align: -moz-middle-with-baseline;

    }
  }

  .edit-button {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #b0b0b0;
    border: none;
    background: none;
    vertical-align: -webkit-baseline-middle;
    text-decoration: none;
    vertical-align: -moz-middle-with-baseline;
   }

  .add-space-button-div {
    width: 84%;
    float: left;
    text-align: right;
    @media screen and (max-width:767px) {
      width: 90%;
  }
  }

  .edit-button-div {
    width: 8%;
    float: left;
    text-align: center;
    padding-left: 10px;
  }

  .setting-button-div {
    width: 8%;
    float: left;
    text-align: center;
    // padding-left: 10px;
    padding-top: 8px;
    @media screen and (max-width:767px) {
        padding-left: 7px;
    }
}

  .edit-profile-section {
    width: 80%;
    background-color: #f7f7f7;
    align-items: center;
    margin: auto;
    border: solid 1px #e1e1e1;
    padding: 10px;
    @media screen and (max-width:767px) {
      width: 90%;
  }

  .venue-details{
    width: 48%;
  }

    .buyout-image {
      float: left;
      text-align: left;
      @media screen and (max-width:767px) {
       float: none;
    }

      .image {
        width: 60px;
        height: 60px;
        margin: 10px 0 10px 0;
        object-fit: cover;
        object-position: top center;
        @media screen and (max-width:767px) {
          width: 100%;
          height: 100%;
          margin: 10px auto 0px;
      }
      }
    }

    .subsection {
      width: 48.9%;
      height: 80px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      float: left;
      margin: 5px;
      padding: 0 10px;
      @media screen and (max-width:1329px) {
        width: 48.8%;
   }
   @media screen and (max-width:1200px) {
    width: 48%;
}
      @media screen and (max-width:1024px) {
           width: 48%;
      }
      @media screen and (max-width:767px){
        height:auto;
        width: 100%;
        margin: 5px 0px;
      }

      .venue-details {
        float: left;
      }

      .subsection-text {
        height: 60px;
        margin: 10px 0 10px 10px;
        font-family: Raleway;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        word-break: break-word;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--black);
        @media screen and (max-width:767px){
          height:auto;
          margin: 5px 0;
        }
      }

      .add-space-div {
        width: 35%;
        height: 100%;
        float: right;
        text-align: end;
        @media screen and (max-width:767px){
          width: 100%;
          text-align: left;
        }
        .published{
            padding-right: 0px;
            display: block;
            @media screen and (min-width:1200px){
             padding-right: 5px;
            }
        }
      }
    }

    // .subsection-empty {
    //   width: 49%;
    //   height: 80px;
    //   border-radius: 6px;
    //   border: solid 1px #e1e1e1;
    //   background-color: #ffffff;
    //   float: left;
    //   margin: 2px;
    //   padding: 0 10px;
    // }
  }

  .title-header {
    margin-bottom: 40px;
    height: 30px;
    padding: 0 5px;
    @media screen and (max-width:767px){
      width: 100%;
      margin: auto;
      height: 60px;
      text-align: center;
    }
  }

  .my-venues-title {
    width: auto;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    word-break: break-all;
    text-align: left;
    color: #202020;
    float: left;
    margin-top: auto;
    padding-top: 5px;
  }

  .venue-image {
    float: left;
    text-align: left;
    @media screen and (max-width:767px){
      width: 100% !important;
      height: 100%;
    }

    .image {
      width: 60px;
      height: 60px;
      margin: 10px 0 10px 10px;
      object-fit: cover;
      object-position: top center;
      @media screen and (max-width:767px){
        width: 100%;
        height: 100%;
        margin: 15px auto 0px;
      }
    }
  }

  .plan-details{
    width: 30%;
    float: left;
    padding-top: 30px;
    padding-bottom: 30px;
    @media screen and (max-width:991px) and (min-width:768px){
        width: 29%;
    }

    @media screen and (max-width:767px){
      width: 100%;
      padding-top: 0px;
      text-align: left;
      padding-bottom: 0px;
    }
    .plantext{
      width: auto;
      @media screen and (max-width:767px){
        text-align: left;
        padding: 5px 0px 0px;
      }
      .red-text{
        display: block;
      }
    }
  }

  .venue-details {
    width: 30%;
    float: left;

    .details {
      width: 100%;
      height: auto;
      margin: 10px 0 10px 10px;
      @media screen and (max-width:767px){
        margin: 5px 0px 0px;
      }

      .title {
        width: 100%;
        height: auto;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        word-wrap: break-word;
        color: #36434b;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
        word-break: break-word;
        margin: 0;
      }
    }
  }

  .add-space-div {
    width: 30%;
    float: right;
    text-align: right;
    margin: auto;
    padding-top: 10px;
    // @media screen and (max-width:1280px) and (min-width:992px){
    //   width: 40%;
    // }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .my-venues {
      width: 90%;
      justify-content: left;
      padding-right: 15px;
    }

    .venue-image {
      float: left;
      text-align: left;
    }
    .venue-details {
      float: left;
      @media screen and (max-width:767px){
        width: 38%;
      }
    }
    .add-space-div {
      float: right;
      text-align: center;
      padding: 10px !important;
      margin: auto;

      // .add-space-button-div {
      //   text-align: left;
      //   float: left;
      //   width: auto;
      // }

      .edit-button-div {
        float: left;
        padding-left: 8px;
      }
    }

    .title-header {
      width: 85%;
      margin: auto;
      height: 60px;
      text-align: center;

      .my-venues-title {
        text-align: left;
        float: left;
        width: auto;
      }

      .red-button {
        float: right;
      }
    }

    .red-button-div {
      width: 100%;
      text-align: center;
      margin: auto;
    }

    .venue-manager-header {
      .subtext {
        margin: 20px auto 20px 0;
        width: 100%;
      }

      .header {
        width: 100% !important;
        margin: auto !important;
        overflow: auto !important;
        border-bottom-style: none !important;
      }
    }

    .edit-profile-section {
      padding: 8px;
      width: 90%;

      .subsection {
        width: 100%;
        margin: 5px auto;
      }

      // .subsection-empty {
      //   width: 100%;
      //   margin: 5px auto;
      // }
    }

    .white-button {
      font-size: 12px;
       padding: 5px;
       @media screen and (max-width:767px){
         font-size: 11px;
       }
    }
  }
}

// css for createEvent
.create-event-header {
  .uploader{
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
  border-image-slice: 1;
  background-image: url(/static/media/perm_contact_calendar.dd9786b4.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-color: #f0f0f0;
}
.header{
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.header-text{
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #36434b;
  float: left;
  padding-top: 5px;
}
.uploader-content{
  width: 300px;
  height: 300px;
  font-family: material;
  font-size: 300px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #eae9e9;
  margin: 30px auto;
}
.red-text-content{
  font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin: 140px 0 5px 0;
}
.sm-text-event{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;
  margin: 0px;
}
.add-space-div{
  padding-top: 0px;
}
}
@media(max-width:767px){
  .create-event-header{
    .add-space-div{
      width: 30%;
      padding: 0 !important;
    }
    .header{
      padding: 15px 5px;
    }
  }
  .venue_manager_venue_add {
  .venue-manager-header {
  .header{
    padding-left: 0px;
    margin-left: 0px !important;
  }
}
}
}
.dont_p{
  .error{
    width:444px;
  }
}
// .success_modal_container ::-webkit-scrollbar ,.success_modal_container ::-webkit-scrollbar-track{
//   display: none;
// }
.published{
 margin:0px;
 padding-right: 20px;
 display: inline-block;
 font-size: 13px; 
 @media screen and (max-width:767px){
  padding-right: 12px;
  display: block;
}
}
.venue_manager_venue_add 
{
  .venue-details
  {
    @media screen and (max-width:767px){
        width: 100% !important;
    }
  }
  .add-space-button-div{
    width: 90% !important;
    text-align: left;
    padding-top: 15px;
  }
  .edit-button-div{
    padding-top: 15px;
  }
  .add-space-div{
    @media screen and (max-width:767px)
    {
      width: 100% !important;
      padding: 0px 0px 15px !important;
    }
  }
}

.venue-plan{
  .expired-msg{  
  background-color: #36434b;
  justify-content: center;
  align-items: center;
  display: flex;
  color:#ffffff;
  font-weight: 600;
  padding: 14px 0;
    .subscrib-btn{
      border-radius: 1px;
      color:#ffffff;
      font-weight: 600;
      background-color: #1397e1;
      &:hover{
        background-color: #1397e1;
      }
    }
  }
  .email-verification{
    background-color: #f0f0f0;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 0;
    p{
      font-size: 14px;
      a{
        color: #4a4a4a;
        text-decoration: underline;
      }
    }
  }

}
