.help-ui-wrapper{
    span.description {
        font-size: 16px;
        margin-top: 8px;
        display: block;
        font-weight: 500;
    }
    .btn {
        // margin-left: 190px;
        margin-bottom: 20px;
        background-color: #ee2738;
        color: #fff;
        padding: 8px 8px;
        border-radius: 2px;
        position: relative;
        display: inline-block;
    }
    .btn:hover{
        color: #ffffff !important;
    }

    .articles-result h2 {
        // margin-left: 190px;
        color: #424242;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    span.title {
        color: #ee2738;
        display: block;
        font-size: 17px;
        font-weight: 600;
    }
    li.article-list-item {
        background-color: #fff;
        border: solid 1px #dfe5ee;
        font-size: 14px;
        color: #424242;
        margin-bottom: 20px;
    }
    li.article-list-item:hover {
        box-shadow: 0 4px 6px 0 rgba(0,0,0,0.1);
    }
    li.article-list-item a {
        text-decoration: none;
        display: block;
        padding: 24px 32px;
    }
    li.article-list-item h3 {
        color: #ee2738;
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    .authors ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        height: 45px;
        margin-right: 8px;
    }
    .item-info {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        color: #666;
    }
    li.article-list-item p {
        color: #424242;
        font-size: 16px;
        margin-top: 10px;
    }
    .article-list-item-footer {
        margin-top: 16px;
    }
    .search-in span {
        font-size: 14px;
        font-weight: 500;
        color: #424242;
    }
    .search-in .link {
        font-size: 14px;
        font-weight: 500;
        color: #ee2738;
        opacity: 0.6;
    }
    .breadcrums-items li::after {
        content: "/";
        padding: 0 4px;
        display: inline;
        color: rgba(238, 39, 56, 0.8);
    }
    .breadcrums-items li:last-child::after {
        content: '';
    }

    .breadcrums-items {
        width: auto;
        max-width: 90%;
        float: none;
        display: block;
        margin-right: auto;
        margin-left: auto;
        padding-left: 0;
        padding-right: 0;
        list-style: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px 0;
    }
    @media only screen and (min-width:1024px){
        .breadcrums-items {
            max-width: 910px !important;
        }
    }
    .breadcrums-items li {
        display: inline;
    }
    .breadcrums-items li a {
        text-decoration: none;
        color: rgba(238, 39, 56, 0.8);
    }

    .article-body{
        ul {
            margin: 16px 0;
            padding: 0 0 0 40px;
            list-style-position: outside;
        }
        a {
            color: #ee2738;
        }
        .authors{
            li {
                list-style: none;
                margin-right: 8px;
            }
            ul {
                padding: 0;
            }
        } 
        }

    section.tags-section{
        a {
            color: #ee2738;
        }
    }
}