.featured_venue_slider{
    .featured_venue_heading{
        padding-bottom: 10px !important;
    }
    .featured_venue_carousel{
        background-color: #f7f7f7;
        padding: 16px 0px;
        .venueCardWithFav {
            @media screen and (max-width:767px){
                margin: 0px 15px;
             }
           
        }
    }
    .overlay_icon{
        right: 15px !important;
        @media screen and (max-width:767px){
            right: 5px !important;
         }
    }
    .venue_content{
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }
}