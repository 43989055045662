
.google_calendar{
    .radio_label{
        font-size: 16px !important;
        font-weight: bold !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b !important;
        padding-bottom: 5px;
    }
    .radio_options{
        color: #fff !important;
    }
    
    .main-form .custom_checkbox {
        float: right;
    }
    
    .custom_checkbox {
        padding-top: 10px;
    }
    .option-text{
        font-family: Raleway;
        font-size: 15px !important;
        font-weight: normal !important;
        color: #36434b !important;
        line-height: normal !important;
        padding-top: 10px;
    }
    .option{
        display: flex;
        flex-direction: row;
        div{
            width: 20px;
        }
    span:first-child{
        padding-left: 0px;
    }
    svg{
        font-size:14px;
        margin-left:15px;
    }
    }
    .heading {
        margin-bottom: 69px;
    }
}
