$white: #f7f7f7;
$grey: #e1e1e1;
$red: #FF0000;
$black: #202020;
.custom_input{
    label{
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
    }
    input{
        text-align: left;
        border-radius: 6px;
        border: solid 1px #e1e1e1 !important;
        background-color: $white;
        // height: 36px;
        width: 100%;
        padding: 9px 15px;
        margin-top: 4px;
        font-size: 15px;
        line-height: 1.07;
        text-align: left;
        color: $black;
        &::placeholder{
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: #b0b0b0;
        }
    }
    .error{
        color:$red;
        font-size: 11px;
        text-align: left;
        width: 333px;
    }   
    .eye_icon{
        font-size: 20px;
        position: absolute;
        right: 10px;
        cursor: pointer;
        bottom: 18px;
        color: #878787;
        cursor: pointer;
        top: 12px;
        @media screen and (max-width:767px){
            top:14px;
        }
    }
    .view-red-btn{
        color: #FF0000;
    }  
    .eye_icon_disable{
        font-size: 20px;
        position: absolute;
        right: 10px;
        bottom: 10px !important;
        color:#9aa7af;
        cursor: pointer;
        top: 12px;
        @media screen and (max-width:767px){
            top:14px;
        }
    }
}
.upper_case_input{
    
    input{
        text-transform: uppercase;
    }
}
