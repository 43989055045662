$white: #ffffff;
$off-white:#f0f0f0;
$water-blue:#1397e1;
$dark-grey: #62717a;

.blue_theme_date_picker{
    &:focus{
        font-size: 12px;
        font-weight: 600;
        line-height: 1.67;
        color: $water-blue;
    }
    width: 100%;
    height: 44px;
    padding: 15px;
    border-radius: 6px;
    border: solid 1px $off-white;
    background-color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    color: $water-blue;
    text-transform: uppercase;
    &::-webkit-input-placeholder {
        color: $water-blue !important;
        
    }
    
    &:-moz-placeholder {
        color: $water-blue;
        opacity: 1;
    }
    
    &::-moz-placeholder {
        color: $water-blue;
        opacity: 1;
    }
    
    &:-ms-input-placeholder {
        color: $water-blue;
    }
    @media screen and (max-width:375px){
         padding-left:15px;
         padding-right:10px;
         color: rgb(19, 151, 225);

    }
    @media screen and (min-width:376px) and (max-width:767px) {
        padding-left:15px;
        padding-right:15px;
        color: rgb(19, 151, 225);

    }
    @media screen and (min-width:1025px) {
        padding-left: 15px !important;
        padding-right:3px;
        color: rgb(19, 151, 225);
    }
}

.black_theme_date_picker{
    &:focus{
        font-size: 12px;
        font-weight: 600;
        line-height: 1.67;
        text-align: center;
        color: $water-blue;
    }
    width: 100%;
    height: 44px;
    border-radius: 6px;
    border: solid 1px $off-white;
    background-color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-align: center;
    color: $dark-grey;
    text-transform: uppercase;
    &::-webkit-input-placeholder {
        color: $dark-grey;
        text-align: center;
    }
    
    &:-moz-placeholder {
        color: $water-blue;
        opacity: 1;
        text-align: center;
    }
    
    &::-moz-placeholder {
        color: $water-blue;
        opacity: 1;
        text-align: center;
    }
    
    &:-ms-input-placeholder {
        color: $water-blue;
        text-align: center;
    }
}