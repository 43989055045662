$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;
$dark-grey: #62717a;

.add_space{
    padding-top: 65px;
    height: 100%;
    // start of add space name css
    .add_space_name{
        padding: 11px 40px 0px 40px;
        background-color: $white;
        @media screen and (max-width:767px) {
            padding: 15px;
            padding-bottom: 228px;
        }
        .add_space_name_container{
            border: solid 1px #e1e1e1;
            background-color: #ffffff;
            margin: 0px 0px 20px 0px;
            height: 505px;
            padding-top: 42px;
            @media screen and (max-width:767px){
                height: auto;
                padding: 15px;
                margin: 0px;
            }
        }
        .space_name{
            @media screen and (max-width:'767px'){
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
    // end of add space name css
    
    // start of add space capacity css
    .add_space_capacity_container {
        padding: 15px !important;
    }
    .add_space_rates{
        @media screen and (max-width:767px){
            padding-bottom: 0px !important;
        }
        .custom_select_with_scroll>div{
            padding: 0 0px 0 15px;
            margin: 0px 2px 0px 0px;
        }
        .help_icon {
            button {
                padding-left: 0px !important;
            }
            span{
                margin-left: 0px !important;
            }
        }
        .holiday_rates{
            h5{
                font-size: 14px;
                font-weight: 600;
            }
            .override-button{
                border-radius: 20px;
                color:#1397e1;
                border: 1px solid #1397e1;   
                width: 80%;
                margin:4px 0px 4px -8px;             
            }
            .override-button:hover{
                background: #1397e1;
                color:#ffffff;
            }
            .view-overrides{
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                width: 76%;
                cursor: pointer;
            }
        }

    }
    .add_space_capacity_container, .add_space_availability_container, .add_space_rates_container{
        background-color: $white;
        padding: 11px 20px 60px 20px;
        .add_space_capacity, .add_space_availability,.add_space_rates{
            background-color: #ffffff;
            box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
            .space_name_container{
                border: solid 1px #e1e1e1;
            }
            .upper_case{
                text-transform: uppercase;
            }
            .capitalise{
                text-transform: capitalize;
            }
            .space_name{
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: $water-blue;
                padding: 10px 0px 9px 0px;
            }
            .capacity{
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: $black;
            }
            .instructions{
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: normal;
                text-align: center;
                color: #62717a;
                padding-bottom: 21px;
                @media screen and (max-width:767px){
                    padding-bottom: 10px;
                }
            }
            .left_panel{
                padding: 10px 24px 10px 32px;
                border-right: solid 1px #e1e1e1;
                @media screen and (max-width:767px){
                    padding: 15px;
                }
            }
            .right_panel{
                padding: 10px 27px 10px 24px;
                @media screen and (max-width:767px){
                    padding: 15px;
                }
            }
            .how_can, .venue_and_space_name{
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: $black;
            }
            .how_can{
                padding-bottom: 0px;
            }
            .venue_and_space_name{
                padding-bottom: 30px;
                text-transform: capitalize;
            }
            .error{
                color: #FF0000;
                font-size: 11px;
                display: block;
                width:100%;
                text-align: left;
            }
        }
        .checkbox_div{
            padding-bottom: 5px;
            padding-top: 5px; 
        }
        .space_arrangement_container{
            padding: 10px 23px;
        }
        .arrangements_image{
            width: 110px
        }
        .capacity_info{
            padding: 10px 20px 17px 20px;
        }
        .text-on-arrangement-image{
            text-align: center;
            padding-bottom: 12px;
            font-size: 16px;
            font-weight: 600;
            color: #1f2836;
            padding-top: 10px;
        }
        .capacity-on-arrangement-image{
            text-align: center;
            padding-top: 6px;
            font-size: 16px;
            font-weight: 600;
            color: #1f2836;
            padding-bottom: 18px;
        }
        .arrangement-desc{
            background-color: #f7f7f7;
            border: solid 1.5px #ebebeb;
            border-radius: 5px;
            min-height: 230px;
        }
    }
    // end of add space capacity css

    //start of space arrangement
    .space_arrangement{
        .space_arrangement_description{
            width: 117px;
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $dark-grey;
        }
        .space_image{
            padding: 2px 0px;
        }
        .min_max{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: $dark-grey;
            padding-bottom: 3px;
        }
    }
    //end of space arrangement

    //start of space availability
    .add_space_availability_container, .add_space_rates_container{
        .add_space_availability,.add_space_rates{
            padding-bottom: 220px;
            .space_rates_heading{
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #36434b;
                padding: 20px 0px;
            }
            .add_space_availability_form{
                padding: 11px 20px;
            }
            .spaceAvailability-block {
                display: flex;
            }
            .add_space_rates_form{
                padding: 0px 20px 11px 20px;
                .help_icon{
                    button{
                        // padding-left: 10px !important;
                        // padding-top: 4px;
                        cursor: pointer;
                        background-color: transparent;
                        border: 0px;
                        box-shadow: none;
                        margin-left: -10px;
                        margin-top: -5px;
                    }

                    &.link_hint{
                        button{
                            margin-left: -4px !important;
                        }
                        button span{
                            margin-left: 2px !important;
                        }
                    }
                }
            }
            .space_availability_heading{
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: $black;
                .sub-heading{                    
                    font-size: 13px;
                    color: $black;
                }                
                a{
                    text-decoration: underline;
                }
                .select-one{
                    font-size: 14px;
                    margin-top: 10px;
                }
                @media screen and (max-width:767px) {
                    font-size: 15px;
                 }
                .copy-availability{
                    .custom_select_with_scroll{
                        max-width:72%;
                        @media screen and (max-width:767px) {
                            max-width: 100%;
                         }
                    }
                }
                .ques-mark{
                    display: inherit;
                    @media screen and (max-width:767px) {
                        display: inline-block;
                     }
                .help_icon {
                    @media screen and (max-width:767px) {
                       display: inline-block;
                       width: 8px;
                      
                    }
                    button{
                        background-color: transparent;
                        border: 0px;
                        @media screen and (max-width:767px) {
                            padding-top: 8px;                          
                        }
                    }
                }
                }
            }
            .checkbox_instructions{
                font-size: 11px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: $dark-grey;
                margin-top: -11px;
                padding-left: 22px;
            }
            .space_availability_checkboxes{
                padding: 10px 0px 10px 0px;
                .container{
                    max-width: 100%;
                }
                .help_icon{
                    button{
                        background-color: transparent;
                        border: 0px;
                        box-shadow:none;
                        margin-left: -15px;
                        margin-top: -5px;
                    }
                }
            }
            .form_index{
                background: #ee2738;
                border-radius: 50%;
                display: block;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                text-align: center;
                border: 1px solid #ee2738;
                align-items: center;
                color: #ffffff;
                padding-top: 2px;
            }
            .index_container{
                padding-top: 25px;
                @media screen and (max-width:767px) {
                    padding-bottom:0px;
                }
            }
            .rate_form_container{
                width: 100%;
                .add_variable{
                    @media screen and (max-width:767px) {
                        padding:10px 21px;
                    }
                    .additionalPricing{
                        @media screen and (max-width:767px) {
                            padding:0px;
                        }
                    }
                }
            }
            .nested_form_container{
                padding: 21px 21px 15px 21px;
                border-top: solid 1px #e1e1e1;
            }
            .nested_space_availability_form_container{
                border-radius: 6px;
                border: solid 1px #e1e1e1;
                padding: 17px 21px 20px 21px;
                margin-top: 39px;
                position: relative;
                @media screen and (max-width:767px) {
                    margin-top:10px;
                }
                .alignment_of_days{
                    padding: 36px 59px;
                    @media screen and (max-width:767px){
                        padding: 15px 20px;
                    }
                }
                .earliest_time{
                    padding-left: 63px;
                    font-size: 15px;
                    @media screen and (max-width:991px) {
                       padding-left: 0px;
                    }
                }
                .right-select , .right-field{
                    @media screen and (min-width:1024px) {
                        float: left;
                        padding-left: 57px;  
                    }
                }
                .right-field{
                    width: 60%;
                    @media screen and (max-width:767px){
                        width:100%;
                        padding-top: 12px;
                    }
                }
                .right-select{
                    width: 100%;
                    @media screen and (min-width:1024px) {
                        width: 20%;   
                    }
                    @media screen and (max-width:991px) and (min-width:768px){
                        width: 20%;   
                        float: left;
                        padding-right: 10px;
                    }
                }
                .max-hours-select , .max-hours , .min-add_time_slot{
                    float: left;
                }
                .max-hours-select , .min-add_time_slot{
                    padding-left: 10px;
                }
                .min-add_time_slot{
                    button{
                        border: none;
                        padding-left: 0px;
                    }
                }
                .text-muted {
                    font-size: 13px;
                    color: #565555;
                    padding: 7px 0px;
                }
                .additional-time {
                    width: 200px;
                    @media screen and (max-width:767px){
                        width:100%;
                    }
                }
                .right{
                    // padding-top: 20px;
                    padding-left: 60px;
                    @media screen and (min-width:1024px) {
                        padding-left: 38px;   
                    }
                    @media screen and (min-width:1300px) {
                        padding-left: 60px;   
                    }
                    @media screen and (max-width:767px) {
                        padding-left: 0px;  
                    }
                }
                .right_flexible{
                    padding-top: 20px;
                    padding-left: 0px;
                    @media screen and (min-width:1024px) {
                        padding-left: 38px;   
                    }
                    @media screen and (min-width:1300px) {
                        padding-left: 45px;   
                    }
                    @media screen and (max-width:767px) {
                        padding-left: 0px;  
                        padding-top: 0px;
                    }
                }
                .max-hours {
                    font-size: 13px;
                    color: #5a5a5a;
                    padding-top: 12px;
                } 
                .padding_left_min {
                    padding-left: 35px;
                    @media screen and (max-width:767px) {
                        padding-left: 0px;
                        padding: 5px 0px;
                    }
                }
                .max-hours-select{
                    width: 200px;
                    @media screen and (max-width:767px){
                        width: 100%;
                        padding-left: 0px;
                    }
                }  
                .min-add_time_slot{
                    padding-top: 6px;
                }   
                span.nested-block {
                    margin-bottom: 6px;
                    display: block;
                }          
            }
            .add_time_slot{
                padding: 20px 0px 54px 0px;
                @media screen and (max-width:767px){
                        padding: 20px 0px 10px 0px ;
                }
            }
            .pricing_form{
                border-radius: 6px;
                border: solid 1px #e1e1e1;
            }
            .additionalCost{
                @media screen and (min-width:1024px) {
                    padding-left: 55px;  
                }
            }
            .time_between_events{
                @media screen and (min-width:1024px) {
                    padding-left: 94px; 
                }
                @media screen and (min-width:1300px) {
                    padding-left: 102px;   
                }
            }
            .additionalPricing{
                margin-left: 60px;
                @media screen and (max-width:767px) {
                    padding-left: 30px;   
                    margin-left: 0px;
                    min-width:35%;
                    max-width:35%;
                    margin-left: 15px !important;
                    margin-top: 19px;
                }
                @media screen and (max-width:500px) {
                    min-width:100%;
                    max-width:100%;
                    margin-left: 0px !important;
                    margin-top: 0px;
                }
            }
            .optional{
                padding-bottom: 5px;
            }
            .pricingTypeName{
                margin-left: 60px;
            }
            .help_icon{
                padding-left: 0px;
                margin-left: -4px;
                box-shadow: none;
                span{
                    font-size: 12px !important;
                    padding-top: 0px;
                }
            }
            .add_variable{
                border-radius: 6px;
                border: solid 1px #e1e1e1;
                padding: 13px 5px 16px 8px;
                margin: 20px 0px 5px 0px;
                .add_icon_container{
                    margin-right: -42px !important;
                    @media screen and (min-width:1595px) {
                        margin-right: -50px;
                    }
                    @media screen and (max-width:1300px) and (min-width:1200px){
                        margin-right: -32px !important;
                    }
                    @media screen and (max-width:1024px) and (min-width:991px){
                        margin-right: -18px !important;
                    }
                    @media screen and (max-width:767px) {
                        margin-right: 0px !important;
                    }
                }
                .additionalPricing{
                    margin-left: 45px;
                    @media screen and (min-width:1599px) {
                        margin-left: 54px;
                    }
                    @media screen and (max-width:1300px) and (min-width:1200px){
                        margin-left: 36px;
                    }
                    @media screen and (max-width:1024px) and (min-width:992px){
                        margin-left: 20px !important;
                        padding-top: 10px;
                    }
                    @media screen and (max-width:991px) and (min-width:768px){
                        margin-left: 20px !important;
                    }
                    @media screen and (max-width:767px) {
                        margin-left: 0px;
                    }
                }
                
                @media screen and (max-width:'800px') {
                    padding: 13px 11px 16px 0px;
                }
                .btn-align-sm{
                    @media screen and (max-width:'767px') {
                    max-width:189px;
                }
                @media screen and (max-width:'320px') {
                    max-width:165px;
                    margin: 10px 15px;
                }
            }
                .custom_select_with_scroll div{
                    @media screen and (max-width:'767px') {
                        margin-right: 10px;
                    }
                svg {
                    @media screen and (max-width:767px){
                        right: -5px;
                    }
                }
                }
            }
            .formula{
                border-radius: 6px;
                border: solid 1px #e1e1e1;
                padding: 21px 20px;
                margin-bottom: 10px;
                position: relative;
                .formula_heading{
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: $black;
                }
                .time_slot{
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #36434b;
                    @media screen and (max-width:767px) {
                        display: inline;
                    }
                }
                .time{
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: right;
                    color: $water-blue;
                    @media screen and (max-width:767px) {
                        text-align: left;
                        display: block;
                        padding-left: 5px;
                    }
                }
                .space_availability_type{
                    padding: 0px 13px 0px 10px;
                    @media screen and (max-width:'767px') {
                        padding: 0px;
                    }
                }
                .time_alignment{
                    @media screen and (max-width:767px) {
                        justify-content:flex-start;
                        display: inline-block;
                    }
                    @media screen and (min-width:801px) {
                        justify-content:flex-end;
                    }
                }
                .select_days{
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #62717a;
                    padding-left: 9px;
                    padding-top: 5px;
                }
                .days{
                    padding-top: 9px;
                    padding-right: 25px;
                }
                .input_field{
                    border: solid 1px $grey;
                    background-color: $white;
                    font-size: 15px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.07;
                    letter-spacing: normal;
                    text-align: left;
                    color: $black;
                    height: 36px;
                    width: 250px;
                    border-radius: 6px;
                    padding-left: 15px;
                    padding-right: 15px;
                    @media screen and (max-width:1024px) and (min-width:768px){
                        width:200px;
                    }
                    @media screen and (max-width:767px) {
                        width:100%;
                    }
                }
                .input_label{
                    font-size: 11px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.45;
                    letter-spacing: normal;
                    text-align: left;
                    color: $dark-grey;
                    @media screen and (max-width:767px) {
                        width:90%;
                        float: left;
                    }

                }
                .additional_input_label{
                    font-size: 11px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.45;
                    letter-spacing: normal;
                    text-align: left;
                    color: $dark-grey;
                    text-transform: capitalize;
                }
                .rate_container{
                    @media screen and (min-width:800px) {
                        padding-left: 48px;
                        padding-right: 72px;
                        padding-top: 16px;   
                    }
                }
                .venueRentalFields{
                    @media screen and (min-width:800px) {
                        padding-left: 48px;
                        padding-right: 128px;
                        padding-top: 16px;   
                    }
                }
                .fAndBMin{
                    padding-top: 16px;
                }
                
                .field_hint{
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: left;
                    color: $dark-grey;
                    padding-top: 3px;

                }
                .pricing_category{
                    padding: 18px 20px;
                }
                .pricing_select{
                    @media screen and (min-width:'800px') {
                        padding-right: 81px;   
                    }
                    .custom_select_with_scroll{
                        div{
                            @media screen and (max-width:767px) {
                                padding-right: 10px;   
                            }
                        }
                    }
                }
                .add_icon_container{
                    @media screen and (min-width:1599px) {
                        margin-right: -52px !important;
                    }
                    @media screen and (min-width:'800px') {
                        margin-right: -58px;
                    }
                    @media screen and (max-width:767px) {
                        max-width: 100%;
                    }
                    button{
                        box-shadow: none;
                        @media screen and (max-width:767px) {
                            padding: 0px 12px 12px 0px;
                        }
                    }
                }
                .days_error{
                    padding-left: 45px;
                }
                .pricing_type_top_padding{
                    // border-top: solid 1px #e1e1e1;
                }
            }
        }
    }
    .add_space_availability{
        @media screen and (max-width:767px) {
            padding-bottom: 0px !important;  
        }
    }
    .venue_code{
        text-transform: uppercase;
        font-weight: bold;
    }
    .space_name_heading{
        text-transform: capitalize;
        font-weight: bold;
    }
    .delete_space_rates{
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 15px;
        cursor: pointer;
        display: block;
    }
    .close_button{
        font-size: 15px;
        margin: 12px;
        cursor: pointer;
    }
    .add_service{
        padding-top: 2px;
    }
}