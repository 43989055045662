$white: #ffffff;
$off-white:#f0f0f0;
$black: #202020;
.custom_select_with_scroll{  
  label{
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    margin-bottom: 4px;
  }
  .label_container{
    padding-bottom: 4px;
    padding-left: 0;
  }
}
::-webkit-scrollbar {
  width: 5px !important;
}
::-webkit-scrollbar-track {
  background: #ffffff !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #1397e1 !important; 
  border-radius: 10px;
}
 
.company-info-form .custom_select_with_scroll{
  margin-top: 8px;
  margin-bottom: 15px;
}