$white: #f7f7f7;
$grey: #e1e1e1;
$red: #FF0000;
$black: #202020;
$water-blue: #1397e1;
.custom_checkbox{
    .container {
        display: block;
        position: relative;
        padding-left: 22px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
    }
      
    /* Hide the browser's default checkbox */
    .container input, .blue_text_container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #ffffff;
        border: 1px solid #9aa7af;
        border-radius: 2px;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark, .blue_text_container:hover input ~ .checkmark {
        background-color: #ffffff;
    }
    
    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark, .blue_text_container input:checked ~ .checkmark {
        background-color: $water-blue;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after, .blue_text_container input:checked ~ .checkmark:after {
        display: block;
    }
    .container input:checked ~ .checkmark, .blue_text_container input:checked ~ .checkmark{
        border: 1px solid $water-blue;
    }
    /* Style the checkmark/indicator */
    .container .checkmark:after, .blue_text_container .checkmark:after {
        left: 5px;
        top: 2px;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    /* Style the checked checkbox label */
    .blue_text_container {
        display: block;
        position: relative;
        padding-left: 22px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: $water-blue ;
    }
    .disabled_day{
        color: #9aa7af;
    }
    .disabled_checkmark{
        position: absolute;
        top: 2px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #ffffff;
        border: 1px solid #d0d0d0;
        border-radius: 2px;        
    }
}

.signup__inner{
    .custom_checkbox .blue_text_container .checkmark:after,
    .checkbox .container .checkmark:after {
        left: 4px;
        top: 0px;
        width: 5px;
        height: 10px;
    }
}