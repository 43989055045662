/***
* == Notifiaction Popup Style
***/

@media (min-width: 600px)
{
  .event-header{
    header {
      border-bottom-style: solid !important;
      border-bottom-width: 1px!important;
      border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
      border-image-slice: 1;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}
.notification-block {
  // padding-top: 14px;
  display: inline-flex;
  button{
    @media screen and (max-width:767px){
      padding: 12px 5px;
  }
  }
}
.shadow-none{
  box-shadow: none;
}
.logo_and_search{
  display: flex;
  align-items: center;
}
.first_list_row{
  p{
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase
  }
  .gray{
    color:  #62717a;
  }
  .red{
    color: #ee2738;
    @media screen and (max-width:767px) {
      padding-left: 10px;
    }
  }
  .blue{
    color: #1397e1;
  }

}
.first_list_row:hover,.second_list_row:hover,.third_list_row:hover{
  background-color: #fff !important;
  cursor: default !important;
}
.third_list_row{
  justify-content: flex-end !important;
  border-bottom: 0px !important;
}
 
.second_list_row{
  h4,a{
    font-size: 13px;
    font-weight: 500;
    line-height: 1.23;
    color: #202020;    
    margin:0;
    @media screen and (max-width:767px) {
      // margin: 0px 10px;
      padding-left: 10px;
    }
  }
  h5{
    font-size: 13px;
    font-weight: bold;
    color: #62717a;
    margin: 15px 0;
    @media screen and (max-width:767px) {
      // margin: 15px 10px !important;
      padding-left: 10px;
    }

  }
  h6{
    font-size: 10px;
    font-weight: bold;
    color: #62717a;
    margin: 15px 0;
    @media screen and (max-width:767px) {
      margin: 8px 0px 0px !important;
      padding-left: 10px;
    }

  }
  .linkBox
  {
    display: flex;
    align-items: center;
    padding-left: 12px;
    a{
      font-size: 15px;
      color: #ee2738;
      font-weight: 600;
    }
    a:hover{
      color:#ee2738;
    }
  }

  
}
.request_button{
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  color: #ee2738;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
}
.book_button{
  border: solid 1px #ee2738;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  margin-left: 15px;
  cursor: pointer;
}
.buttons{
  justify-content: end !important;
}
.hr_tag{
color: #e1e1e1;
opacity: 0.3;
height: 1px;
}
.changeText {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;
  margin-top: 32px;
  cursor:pointer;  
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.noneText{
  display: block;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  color: black;
  font-size: 13px;
}
.main_section{
  height: 220px;
  padding-top: 20px;
  overflow-y: scroll;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12) !important;
@media screen and (max-width:767px){
  height:355px;
}
  .book_button:hover{
    background-image: linear-gradient(to bottom, #d80d2a, #d80d2a);
    color: #ffffff;
    opacity: 0.8;
  }
  .gc-box{
    float: left;
    width: 210px;
  }
  .ms-box{
    float: left;
    width: 210px;
    border-left: 1px solid #202020;
  }
  .ms-box.two, .ms-box.three {
    padding-top: 10px;
    border-top: 1px solid;
  }
  .ms-box.two{
    border-left: 0;
  }
}
.cal-text{
  text-align: center;
}
.MuiDialog-paper-229 {
 
  overflow: visible !important;
}
@media (max-width: 768px){
#menu-list-grow{
  left: -302px !important;
width: 370px;
top: 4px;
}
}
@media (max-width: 320px){
  #menu-list-grow{
    left: -274px !important;
    width: 315px;
  }
  }
  .notification-box{
    display: block;
    text-align: center;
    .notif-div{
      padding-left: 0px !important;
      display: block !important;
    }
    Button{
      margin-bottom: 20px;
      background-color: transparent;
      color: #ee2738;
      box-shadow: none;
      a{
        color: #ee2738;
      }
    }
  }
  .view-all-page{
    width: 100%;
    margin: 0px auto;
    display: block;
    background-color: #f7f7f7;
    padding-top: 80px;
    .loading{
      font-weight: bold;
      width: 100%;
      text-align: center;
      padding-top: 150px;
      font-size: 18px;
      height: 100vh;
    }
    .custom_menu_item{
      ul{
        background-color: #ffffff;
        padding: 10px 0px;
        width: 98%;
        margin: 0px auto 12px;        
      }
    }
  }