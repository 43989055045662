.matter{ font-family: Raleway !important; }

::-webkit-scrollbar-thumb {
  background: none !important;
  border-radius: 0;
}
.document_upload_file {
  opacity:0;
  display: none;
}
.space-profile-edit {
  overflow-x: hidden;
  padding-left: 10px;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;

  @media screen and (max-width:767px){
    padding-left: 0px;
  }

  .wid-100{
    @media screen and (max-width:767px) {
      width: 100% !important;
    }
  }

  .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 0 0;
  }

  .left-side{
    width: 50%;
    text-align: left;
    float: left;
      @media screen and (max-width:767px) {
        padding-top: 5px;
      }
  }

  .right-side{
    width: 50%;
    text-align: right;
    float: left;
  }

  .black-heading {
    width: 100%;
    float: left;
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0;
  }

  .right-container {
    width: 98% !important;
  }

  .seatindAndCapacityGrid {
    max-width: 100% !important;
    padding-bottom: 0px;
    position: relative;

    .prev-arrow {
      // background-color: #ee2738;
      cursor: pointer;
    }

    .prev-arrow:hover {
      color: black;
    }

    .next-arrow {
      // background-color: #ee2738;
      cursor: pointer;
    }
  }

  .textarea {
    width: 100%;
    height: 120px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    padding: 12px;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin: 10px 0 !important;
  }

  .textarea-small {
    width: 352px;
    height: 90px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
  }

  .rectangle {
    width: 100%;
    height: auto;
    border-radius: 6px;
    border: solid 1px #f0f0f0 !important;
    background-color: #ffffff;
    padding: 12px;
    margin: 0;
    box-shadow: none;
    margin: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;

    .amenities {
      width: 508px;
      background-color: var(--white);
    }
  }

  .expansionPanel {
    border: none;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
    position: inherit;
    margin: 0;
  }

  .expansionPanelSummary {
    padding: 0;
    margin: 0;
    min-height: 65px;

    div {
      padding: 0;
      margin: 20px 0 0 0;
      float: left;
      display: block;
    }

    div[role=button] {
      margin: 10px 0 0 0;
    }
  }

  .grid-section {
    width: 50%;
    float: left;
  }

  .grid-section-vertical {
    width: 100%;
    padding: 10px 0;
    overflow: auto;
    @media screen and (max-width:767px){
    //  overflow: visible;
    }
  }

  .check-box {
    width: 15px;
    height: 15px;
    background-color: #9aa7af;
  }

  .checkbox-heading {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: black;
    margin: 5px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
  }

  .check-box-label {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #62717a;
    margin: 5px;
  }

  .check-box-div {
    float: left;
    width: 100%;
    height: 25px;

    span {
      padding: 0px;
      color: #9aa7af;
    }
  }

  .grey-text {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #62717a;
  }

  .other-info-label {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.45;
    text-align: left;
    color: #62717a;
  }

  .input-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    padding-left: 5px;
  }

  .big {
    width: 250px;
  }

  .small {
    width: 90px;
    @media screen and (max-width:767px) {
      width: auto;
    }
  }

  select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    font-weight: normal;
    padding: 5px;
    -webkit-appearance: menulist;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: black;
    padding-left: 5px;
  }

  .grid-section-small {
    float: left;
    margin-right: 20px;
    .error{
      position: absolute;
    }
  }

  .white-button {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738;
    @media screen and (max-width:767px) {
      margin-top:0px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .grid-section {
      width: 100%;
      float: left;
    }

    .grid-section-small {
      float: left;
      margin-right: 10px;
      width: 20%;

      .input-field {
        width: 100%;
      }
    }

    .venue-space-edit {
      padding-left: 10px;
    }
  }
}
