.help-ui-wrapper{
    .page-body {
        background: #f5f7fa;
        padding: 50px 0;
    }
    .header-section {
        background-color: #1397e1 !important;
    }
    img.large {
        max-width: 200px;
        width: auto;
        max-height: 64px;
    }
    .header-body {
        width: auto;
        max-width: 90%;
        float: none;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
    a.company-logo {
        display: inline-block;
        line-height: 100%;
        position: relative;
        z-index: 1000;
    }
    .article-search-container {
        box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.17);
        border: solid 1px transparent;
        background: #ffffff;
        border-radius: 6px;
        position: relative;
    }
    .icon-search {
        position: absolute;
        top: 16px;
        left: 8px;
    }
    input::placeholder {
        font-size: 17px;
        font-weight: 500;
    }
    input[type="search"]:focus {
    outline: none;
    }
    input[type="search"] {
        padding: 0 16px 0 56px;
        position: relative;
        z-index: 10;
        height: 60px;
        border-radius: 6px;
        width: 100%;
        background: transparent;
        border: none;
    }
    form {
        margin: 0;
        border-radius: 6px;
    }
    .header-title h1{
        color: #ffffff;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 0;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
    }
    .header-title {
        margin: 110px 0 40px 0;
    }
    .sub-heading {
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
    }
    .header-wrap ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        height: 100%;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
    }

    .header-body {
        padding: 10px 8px 52px 8px;
    }
    .header-wrap ul li a {
        color: #fff;
        font-weight: 700;
    }
    nav.overlay-menu {
        position: static;
        height: auto;
        top: 0;
        right: 0;
        -webkit-transform: none;
        transform: none;
        text-align: right;
    }
    div#overlay {
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
        position: absolute;
        right: 0;
        height: auto;
        opacity: 1;
        background: transparent;
        visibility: visible;
    }
    .header-wrap {
        height: auto;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
    }
    .header-wrap li {
        opacity: 1;
        display: inline-block;
        vertical-align: top;
        margin-left: 24px;
    }

    .article-overview-list h2,
    .articles h2 {
        color: #424242;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .article-body h3 {
        font-weight: 700;
        color: #424242;
    }
    section.article-overview-list {
        margin-bottom: 40px;
    }

    .lists {
        margin: 0;
        padding: 0;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        flex-wrap: wrap;
    }


    @media only screen and (min-width:1024px){
        a.item{
            width: 31% !important;
        }
        .page-container {
            width: auto;
            max-width: 910px !important;
            float: none;
            display: block;
            margin-right: auto;
            margin-left: auto;
            padding-left: 0;
            padding-right: 0;
        }
        a.item {
            margin-right:20px !important;
        }
        .header-body {
            padding: 32px 8px 52px 8px !important;
            max-width: 910px !important; 
        }
        .header-title h1 {
            font-size: 30px;
        }
    }
    .header-nav-view{
        display: block !important;
    }
    .mobile-view-nav{
        display: none !important;
    }
    @media only screen and (max-width:767px){
        .mobile-view-nav{
            display: block !important;
        }
        .header-nav-view{
            display: none !important;
        }
        .page-body {
            padding: 20px 0;
        }
        section.article-overview-list {
            margin-bottom: 15px;
        }
        .header-title {
            margin: 50px 0 40px 0;
        }
    }
    a.item {
        text-decoration: none;
        min-width: 262px;
        width: 100%;
        margin-right: 32px;
        background: #fff;
        display: block;
        color: #666;
        // font: 14px/1.43 "Roboto";
        box-shadow: 0 1px 4px 0 #dfe5ee;
        border-radius: 4px;
        padding: 16px;
        margin: 0 0 16px 0;
        border: solid 1px #fff;
        transition: border 250ms ease;
        cursor: pointer;
    }
    a.item {
        margin-right: 0px;
    }
    .item:hover {
        border: solid 1px #ee2738;
    }
    span.description {
        font-size: 16px;
        margin-top: 8px;
        display: block;
        font-weight: 500;
    }
    span.title {
        color: #ee2738;
        display: block;
        font-size: 17px;
        font-weight: 600;
    }
    li.article-list-item {
        background-color: #fff;
        border: solid 1px #dfe5ee;
        font-size: 14px;
        color: #424242;
        margin-bottom: 20px;
    }
    li.article-list-item:hover {
        box-shadow: 0 4px 6px 0 rgba(0,0,0,0.1);
    }
    li.article-list-item a {
        text-decoration: none;
        display: block;
        padding: 24px 32px;
    }
    li.article-list-item h3 {
        color: #ee2738;
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    .authors ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        height: 45px;
        margin-right: 8px;
    }
    .item-info {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        color: #666;
    }
    @media only screen and (max-width:380px){
        .item-info {
            font-size: 12px !important;
        }
    }
    li.article-list-item p {
        color: #424242;
        font-size: 16px;
        margin-top: 10px;
    }
    .page-container {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
}