$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;
$red:#ee2738;
.MuiDialog-paperWidthSm {
    max-width: 480px;
}
.edit-pdf-dialog-container{
    padding: 24px;
    p {
        width: 100%;
    }
    .heading-1 {
        font-size: 18px;
        text-align: center;
        font-weight: 500;
    }
    .heading-2 {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    .bottom-heading {
        margin: 16px 0;
        font-size: 14px;
        text-align: center;
    }
    .bottom-link {
        margin: 4px 0 4px;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        a {
            color: $water-blue;
        }
    }
    .login-container {
        .button-box {
            margin-top: 18px;
            .sign-btn {
                background-color: $red;
                font-weight: 700;
                font-size: 16px;
            }
        }       
        .input-box{
            margin-top:12px;
            label{
                font-size: 14px;
                font-weight: 600;
                padding-left: 4px;
            }
            .inputField{
                width: 100%;
            }
        }
    }
}
