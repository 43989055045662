/***
* == Search Style
***/
@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap');
$primary: #ee2738;
$red: #FF0000;
$white: #ffffff;
$dark-grey:#36434b;
$off-white: #f0f0f0;
$grey: #62717a;
$light-grey: #9aa7af;
$secondary: #f1435e;
$blue: #1397e1;


/*search box css start*/
.suggestionDiv > .heading1, .heading2 {display:none;}
// .suggestionDiv > .heading1:nth-child(1), .suggestionDiv > .heading2:nth-child(1) {display:inline-block;}
.box {
  width: 300px;
  height: 50px;
}

.searchBgBox {
  width: 100%;
  max-width: 1130px; z-index: 9999999999;
  @media screen and (max-width:1024px) and (min-width:991px) {
    max-width: 985px;
  }
}

/* search box */

/*-------------------------------------*/
.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  zoom: 1;
  width: 100%;
}

/*-------------------------------------*/
.form-wrapper-2 {      
  box-shadow: 5px 10px 18px #04040438;
}


* {
  box-sizing: border-box;
}

.search_container {
  position: relative;
  width: 100% !important;
}

.search_container {

  input#states-autocomplete {
    position: relative;
    float: left;
    width: 100%;
    padding: 0px 15px 0px 69px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-style: normal;
    }
  }

  div {
    width: 83.3% !important;
    position: relative;
    @media screen and (max-width:767px) {
      max-width:80%;
    }
    @media screen and (max-width:349px) {
      max-width:79%;
    }
    @media screen and (max-width:325px) {
      max-width:77.7%;
    }
  }

}
.search_container2{
  .react-datepicker-popper{
    top: 40px !important;
  }
}
.search_container input:focus {
  outline: 0;
  background: #fff;
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, .8) inset;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .8) inset;
  box-shadow: 0 0 2px rgba(0, 0, 0, .8) inset;
}

.search_container input::-webkit-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic;
}

.search_container input:-moz-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic;
}

.form-wrapper-2 input:-ms-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic;
}

/* Style the submit button */
.search_container button {

  text-transform: capitalize;
  float: left;
  width: 16.7% !important;
  margin: 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  display: block;
  border-radius: 0px;
  height: 50px;
  background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  @media screen and (max-width:767px) {
    width:20% !important ;
    min-width: 69px !important;
  }
  @media screen and (max-width:320px) {
    min-width: 64px !important;
  }

  &:before {
    content: "Search";
    cursor: pointer;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    // padding: 0px 8px 1px 50px;

  }
}


/* Clear floats */
.search_container::after {
  content: "";
  clear: both;
  display: table;
}

.custom-select {
  position: relative;
  background: #f7f7f7;

  &:before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 0.8;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    color: #62717a;
    z-index: 0;
  }
}

select {
  text-transform: uppercase;
  font-family: 'Raleway';
  width: 99%;
  padding: 15px 25px 15px 15px;
  text-align: left;
  float: left;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #62717a;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: transparent;
  z-index: 3;
}
.searchSelect {
  option {

    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #62717a;
    border: 0;
    border: 0;
    padding: 8px !important;
    min-height: 50px !important;
    height: 30px !important;
    padding: 0px 12px;
  }
}




.search_container2 {
  background-color: #eee
}

.search_container2 input {
  width: 100%;
  z-index: 2;
  background-color: transparent;
  cursor: pointer;
}

div.react-datepicker__input-container {
  display: block;
 
  // .datepiker:focus {
  //   line-height: normal !important;
  // }
}

.search_container {
  >div:first-child {
    &:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      top: 15px;
      left: 25px;
      color: #ccc;
      z-index: 1;
    }
  }

  input[role="combobox"]+div {

    z-index: 9999;
    top: 50px !important;
    left: 0px !important;
    position: absolute !important;
    width: 120% !important;
    min-width: 80% !important;
    max-height: 145px !important;
    background: #fff;
    overflow-y: scroll;
    height:auto;
    box-shadow: 0px 15px 10px -15px #ccc;
    padding: 10px 0;
  }

  input[role="combobox"]+div .item {
    padding: 0px 65px;

    font-size: 15px !important;
    @media screen and (max-width:767px){
      padding: 0px 15px !important;
    }
    @media screen and (max-width:320px){
      padding-right: 0px !important;
      padding-left: 15px !important;
    }

  }

  input[role="combobox"]+div .item-highlighted {
    background-image: linear-gradient(to bottom, #eee, #eee);
    width: 100% !important;
    cursor: pointer;
    font-size: 12px;
  }
}

.home-search {
  margin-top: -90px;
}

.datepiker {
  position: relative;
  text-transform: uppercase;
  font-family: 'Raleway';
  width: 100%;
  padding:18px 44px 18px 22px;
  float: left;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: normal;
  color: #62717a;
  border: 0;
  outline: none;
  background: #f7f7f7;

  // border-radius: 3px 0 0 3px;
  &:before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    color: #62717a;
  }

}

.datepiker:focus {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
}

.suggestionDiv {
  width: 80% !important;
  position: relative;
  font-size: 12px;
  line-height: 2.3;
  word-break: break-word;

}

.react-datepicker-wrapper {
  display: block !important;
}

.search_bottom {
  width: 100% !important;
  padding: 6px 0px 0px 0px;

  >div:first-child {
    float: right;
   }

  span {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #62717a;
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
 
  .search_container2 {
    width: 100% !important;
    margin: 0px;
    input {
      width: 100% !important;
      padding: 19px 0px 11px 30px;
    }
    
}


.search_container {
  input#states-autocomplete {
    position: relative;
    float: left;
    width: 98%;
    padding: 4px 6px 5px 2px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff;
    border-radius: 0px;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-style: normal;
    }
  }

  div {
    width: 79% !important;
    position: relative;
  }

  >div:first-child {
    &:before {
      content: ' ';

    }
  }

  button {
    text-transform: capitalize;
    float: left;
    width: 17%;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    @media screen and (max-width:767px) {
      width:20% !important ;
    }

    &:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: contents;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1; top: 14px;
      text-align: center;
      left: 24px;
      padding: 0px;
    }


  }

  

  input[role="combobox"]+div .item {
    padding: 0px 15px;
    font-size: 15px !important;

  }
}



  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff;
  }

  .home-search {
    margin-top: 3px;
  }

  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7;
  }

  select {
    padding: 12px 40px 15px 39px;
}

  .search_bottom {
    padding: 5px 0px 0px 51px;
    @media screen and (max-width: 767px){
      padding: 5px 0px 0px 15px !important;
    }
    >div:first-child {
      float: right;
      width: 60%;
      @media screen and (max-width: 767px){
        width:100% !important;
        text-align: left;
      }

      &:before {
        content: '';

      }

    }

    span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a;
      @media screen and (max-width: 767px){
        text-align: center;
        display: block;
      }
    }
  }
}
 
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .search_container2 {
    width: 100% !important;
    margin: 0px;
    input {
      width: 100% !important;
      padding: 19px 0px 11px 30px;
    }
    
}
@media screen and (max-width: 768px) {
  // .search_container {
  //   padding-right: 25px;
  // }
  input#states-autocomplete{
    font-size: 11px !important;

  }
  .search_container input[role="combobox"] + div{
    min-width:100% !important;
  }
  .suggestionDiv div {
    max-width: 100% !important;
  }
}

.search_container {
  input#states-autocomplete {
    position: relative;
    float: left;
    width: 98%;
    padding: 4px 6px 5px 2px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal;
      color: #b0b0b0;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal;
      color: #b0b0b0;
    }
  }

  div {
    width: 76% !important;
    position: relative;
  }

  >div:first-child {
    &:before {
      content: ' ';

    }
  }

  button {
    text-transform: capitalize;
    float: left;
    width: 17%;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    @media screen and (max-width:767px) {
      width:20% !important ;
    }

    &:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1;
      padding: 0px;
      top: 14px;
      text-align: center;
      left: 24px;
    }


  }

  

  input[role="combobox"]+div .item {
    padding: 0px 15px;
    font-size: 15px !important;

  }
}



  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff;
  }

  .home-search {
    margin-top: 3px;
  }

  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7;
  }

  select {
    padding: 12px 40px 15px 39px;
}

  .search_bottom {
    padding: 5px 0px 0px 51px;
    >div:first-child {
      float: right;
      width: 60%;

      &:before {
        content: '';

      }

    }

    span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a;
    }
  }
}
@media only screen 
  and (min-device-width: 320px)
  and (max-device-width: 991px)
   {
    .search_container2 {
      width: 100% !important;
      margin: 0px;
      input {
        width: 100% !important;
        padding: 19px 0px 11px 30px;
      }
  }
  
  
  .search_container {
    input#states-autocomplete {
      position: relative;
      float: left;
      width:100%;
      padding: 4px 14px 5px 14px;
      height: 50px;
      font-size: 14px;
      border: 0;
      background: #fff;
      border-top: 1px solid #f7f7f7;
      border-left: 1px solid #f7f7f7;
  
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-style: normal;
      }
  
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-style: normal;
      }
  
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-style: normal;
      }
  
      &:-moz-placeholder {
        /* Firefox 18- */
        font-style: normal;
      }
    }
  
    div {
      width: 79% !important;
      position: relative;
    }
  
    >div:first-child {
      &:before {
        content: ' ';
  
      }
    }
  
    button {
      text-transform: capitalize;
      float: left;
      // width: 20%;
      margin: 0px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      border: none;
      border-radius: 0px;
      height: 50px;
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  
      &:before {
        content: 'search';
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: contents;
        white-space: nowrap;
        word-wrap: normal;
        position: absolute;
        color: #fff;
        z-index: 1; padding: 0px;
        top: 14px;
        text-align: center;
        left: 24px;
        padding: 0px;
      }
  
  
    }
  
    
  
    input[role="combobox"]+div .item {
      padding: 0px 15px;
      font-size: 15px !important;
  
    }
  }
  
  
  
    .form-wrapper-2 {
      box-shadow: 0px 0px 8px 0px #fff;
      padding: 0px 15px;
    }
  
    .home-search {
      margin-top: 3px;
    }
  
    .custom-select {
      width: 50%;
      position: relative;
      background: #f7f7f7;
    }
  
    select {
      padding: 12px 40px 15px 30px;
  }
  
    .search_bottom {
      text-align: right;
      padding: 5px 20px 0px 51px;
      >div:first-child {
        float: right;
        width: 60%;
  
        &:before {
          content: '';
  
        }
  
      }
  
      span {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #62717a;
      }
    }
   }
.date_pick{
  position: relative;
  &::before{
    content: 'arrow_drop_down';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 0.8;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 15px;
  right: 5px;
  z-index: 1;
  color: #62717a;
  }
  
}
 
.datepiker {
    color: #62717a !important;
}
@media only screen and (max-device-width: 991px) and (min-device-width: 320px)
{
  .date_pick {
    width: 50%;
    position: relative;
 }
}
@media only screen and (max-width: 991px) and (min-width: 768px){
  .search_container div{
    min-width: 83% !important; 
  }
}
@media only screen and (max-width: 767px) and (min-width: 375px){
  .search_container div{
    min-width: 80% !important; 
  }
}
input::-webkit-input-placeholder { /* WebKit browsers */
  color: #b0b0b0;
}
::placeholder {
  color: #b0b0b0;
}
