.calendar{
    // background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
      .button-calendar{
            display: block;
            text-align: center;
            border-bottom: 1px solid #f0f0f0;
            button{
              background-color: transparent;
              color: #ee2738;
              box-shadow: none;
              font-weight: 700;
              font-size: 18px;     
              @media screen and (max-width:767px){
                  min-width: 40px;
              }   
            }
            .today{
              color: #1397e1;
              min-width: 140px;
              font-family: Raleway;
              font-size: 16px;
              font-weight: bold;

            }
                      }
    .flexContainer{
        background-color: white; 
        .weekname{
            padding: 8px 0px;
            text-transform: uppercase;
        }
        .flexColumn{
            font-family: Raleway;
            font-size: 12px;
            // border: 1px solid #f0f0f0;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #202020;
        }
        
        .day{
            background-color: white !important;
            color: #36434b;
            height: 50px;
            font-weight: normal;
            font-size: 14px;
            cursor: pointer;
            .inner-grid{
                padding-left: 0px;
                position: relative;
                padding-right: 0px;
                .date{
                    text-align: left;
                    left: 5px;
                    display: block;
                    top: 0;
                    width: 100%;
                    position: absolute;
                    z-index: 2;
                }
           
                .event{
                    position: absolute;
                    width: 100%;
                    height: 10px;
                    top: 0px;
                }
                .single-event{
                    height: 100%;
                }
                .blockOut{
                    background-color: grey !important;
                    height: 100%;
                    cursor: default;
                
                }
                .makeAvailableClass{
                    background-color: #ffffff !important;
                    height: 100% !important;
              
                }
                .holdClassSecond{
                    top: 0px;
                }
                .holdClass{
                    top: 10px;
                }
                .holdClassSingle{
                    height: 100%;
                    background-color: #69c7ee !important
                }
                .blockClassSingle{
                    height: 100%;
                    background-color: grey !important;
                    cursor: default;
                }
                .noTimeSlotAvilable{
                    height: 100%;
                    background-color: #f7f7f7 !important;
                    pointer-events: none
                }
             
                //previous dates
                .previous_dates{
                    height: 100% !important;
                    background-color: #f7f7f7 !important;
                    cursor: default;
                }

                .holdClass{
                    // height: 100%;
                }
            }
        }
        .inactive{
            background-color: #f7f7f7;
            color: #e1e1e1;
        }
        .inner-grid{
            padding: 17px 8px;
            box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
            border-radius: 0!important;
            .event-slot{
                min-height: auto;
            }
        }
    }
                    
  }
