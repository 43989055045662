/***
* == Header Style
***/
@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap');
$primary: #ee2738;
$red: #FF0000;
$white: #ffffff;
$dark-grey:#36434b;
$off-white: #f0f0f0;
$grey: #62717a;
$light-grey: #9aa7af;
$secondary: #f1435e;
$blue: #2497e2;


span,
div,
p,
li,
a{
  font-family: 'Raleway', sans-serif;
}
input {
  font-family: 'Raleway', sans-serif !important;
}

label {
  font-size: 14px;
}

$primary: #ee2738;

.signup, .login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  background-color: $primary;
  height: auto;
  @media screen and (max-width:767px){
    height: 100vh;
  }
  &:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 50%;
    position: absolute;
    background: $off-white;
    border-radius: 0 0 100% 100% / 40%;
  }
  .signup-personal {
    background-color: #1397e1;
    label {
      position: relative;
    }
    label + div {
      margin: 0;
    }
    .checkbox-wrap {
      label {
        width: 100%;
        margin: 0;
      }
    
      span {
        padding: 0;
        p {
          font-family: 'Raleway', sans-serif;
          font-weight: 500;
          margin-left: 5px;
          font-size: 14px;
          text-align: left;
          line-height: normal;
        }
      }
    }
  }
  .company-info-form {
    background-color: #1397e1;
    label {
      position: relative;
    }
    label + div {
      margin: 0;
    }
  }

  .signup__inner {
    margin-top: 10px;
    height: 540px;
    position: relative;
    bottom: 0;
  }

  .firstHold {
    color: #36434b;
    font-size: 16px;
    font-weight: bold;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 27px;
  }

  .createAnAccount {
    color: #202020;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  .yourBusiness {
    font-size: 12px;
    font-weight: 500;
    color: $grey;
    margin-bottom: 35px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-top: 14px;
  }

  .signupForm {
    margin: 17px 0px 25px 0px;
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  }
  .createPasswordForm {
    margin: 80px 0px 25px 0px;
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  }
  .formFields {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .signupDiv {
    display: table;
    margin: 0 auto;
    padding-top: 43px;
    padding-bottom: 25px;
  }

  .signupButton {
    font-size: 13px;
    width: 130px;
    height: 36px;
    border-radius: 6px;
    background-image: linear-gradient($secondary,$primary);
    color: $white;
    font-weight: 500;
    border: none;
    margin-bottom: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    margin-top: 45px;
  }

  .greySignupButton {
    margin-top:45px;
  }
  .greySignupButton {   
    font-size: 13px;
    width: 130px;
    height: 36px;
    border-radius: 6px;
    color: $white;
    font-weight: 500;
    border: none;
    margin-bottom: 30px;
    background-color: $light-grey !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 30px;
  }

  .already {
    font-size: 12px;
    color: $white;
    font-weight: 500;
    margin-top: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .signinLink {
    color: $white;
    font-weight: bold;
    &:hover{
      text-decoration: underline;
    }
  }

  .textField {
    width: 339px;
    margin-bottom: 15px;
  }
  .image {
    width: 26px;
    height: 24px;
  }
  .mailIcon {
    padding: 0px 0px 0px 9px;
    &:focus {
      background: none;
    };
    &:hover {
      background: "none";
    }
  }

  .emailLabel {
    color: $grey;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    margin-bottom: 0px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    margin-top: 0px;
  }

  .red{
    color: #FF0000;
  }
  .agree_check{
    padding-top: 15px;
    width: 100%;
    display: block;
  }
  .special_offer_check{
    padding-bottom: 15px;
  }
  .select_tier{
    display: block;
    padding-top: 10px;
    width: 100%;
    div{
      justify-content: center;
    }
  }
  .agree_error{
    margin-top: -10px;
    padding-bottom: 10px;
    padding-left: 22px;
  }
  .agree-text-size{
    font-size: 12px;
    color:#62717a;
  }
  .plan_link{
    color: #1397e1;
    font-size: 12px;
  }
  .terms{
    color: #1397e1;
  }
  // .zip{
  //   text-transform: uppercase;
  // }
  .emailField {
    width: 100%;
    height: 36px;
    border: 1px solid #e1e1e1 !important;
    background-color: #f7f7f7 !important;
     font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 4px;
    margin-bottom: 0px;
    &:hover{
      border:'none';
    }
  }
  .inputField {
    width: 100%;
    height: 36px;
    border: 1px solid #e1e1e1 !important;
    background-color: #f7f7f7 !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 8px;
    margin-bottom: 15px;
    &:hover{
      border:'none';
    }
  }
  .passwordField {
    width: 100%;
    height: 36px;
    border: 1px solid #e1e1e1 !important;
    background-color: #f7f7f7 !important;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 8px;
    margin-bottom: 15px;
  }

  .phoneNumberGrid{
    padding-right: 5px;
  }
  .extensionGrid{
    padding-left: 5px;
    .emailLabel{
      padding-left: 10px;
    }
  }
  .personalInfoPhone{
    margin-top: 12px;
  }
  .passwordField::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color:'#9aa7af !important';
  }

  .passwordField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color:'#9aa7af !important';
    opacity: 1;
  }

  .passwordField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color:'#9aa7af !important';
    opacity: 1;
  }

  .passwordField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color:'#9aa7af !important';
  }

  .emailField::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color:'#9aa7af !important';
  }

  .emailField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color:'$light-grey !important';
    opacity: 1;
  }

  .emailField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color:'$light-grey !important';
    
  }

  .emailField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color:'$light-grey !important';
  }

  .passwordLabel {
    color: $grey;
    color:'#9aa7af !important';
  }
  .passwordField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color:'#9aa7af !important';
    opacity: 1;
  }
  .passwordField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color:'#9aa7af !important';
    opacity: 1;
  }
  .passwordField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color:'#9aa7af !important';
  }
  .emailField::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color:'#9aa7af !important';
  }
  .emailField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color:'#9aa7af !important';
    opacity: 1;
  }
  .emailField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color:'#9aa7af !important';
    
  }
  .emailField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color:'#9aa7af !important';
  }
  .passwordLabel {
    color: #62717a;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    margin-bottom: 0px;
    margin-top: 34px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    display: block;
    letter-spacing: normal;
   }
  .button {
    background-image: linear-gradient(#f1435e,$primary);
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    width:232px;
  }  
  .error{
    color:$red;
    font-size: 11px;
    text-align: left;
    width: 100%;
  }
  .image_error{
    color:$red;
    font-size: 11px;
    text-align: center;
  }


  input:focus,
  input.form-control:focus {

    outline: none !important;
    color:#202020;
    font-size: 14px;
    text-align: left;
  }
  input:focus, input.form-control:focus {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  /* Select User Type Page */

  .welcome__text {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
    margin-top: 30px;
  }

  .info__text {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
    margin-top: 10px;
  }

  .rectangle {
    width: 160px;
    height: 120px;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    display: flex;
    margin-right: 9px !important;
    margin-bottom: 10px !important;
    border: none !important;
  }

  .rectangle__container {
    margin-bottom: 127px;
    margin-top: 83px;
    @media screen and (max-width:767px) {
      margin:50px auto;
    }
  }

  .text__center {
    text-align: center;
  }

  .user__type__text {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
    margin-top: 10px;
  }

  .icon1 {
    font-size: 20px;
    margin-bottom: 18px;
    color: $primary;
    position: absolute;
    top: 10px;
  }

  .icon2 {
    font-size: 20px;
    margin-bottom: 8px;
    color: $primary;
    position: absolute;
    top: 10px;
  }

  .rectangle__button_hover {

    &:hover {
      background-color: #00a0fc !important;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to top, var(--water-blue), #1cb3ff), linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));

      .user__type__text {
        color: $white !important;
      }

      .icon1,
      .icon2 {
        color: #ffffff !important;
      .icon {
        color: $white !important;
      }
    }
  }
  }

  form {
    width: inherit;
  }

  .img-fluid{
    max-width: 100%;
    height: auto;
  }

  .uploadPhoto {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: $off-white;
    // margin-top: 25px;
    cursor: pointer;
    @media screen and (max-width: 767px)
    { 
      width: 110px;
      height: 110px;

    }

    // .css-1dkwqii{
    //   .css-bdjl4r{
    //     width: 92px !important;
    //     height: 92px !important;
    //   }
    // }

    .icon {
      font-size: 90px;
      color: #e1e1e1;
      width: 100%;
      // height: 100%;
      text-align: center;
    }
    .user-icon{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }

    &:hover {
      font-family: 'Material Icons';
      content: "Upload Photo \e2c3";
      background-color: rgba(98, 113, 122, 0.6);
      color: $white;
      cursor: pointer;
      .user-icon{
        display:none;
      }
      .upload-image{
        display:flex;
      }

    }
    .upload-image{
      width: 100%;
      height: 100%;
      background: #ccc;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      display: none;
      span.material-icons{
        width: 28px;
        height: 28px;
        // padding: 6px;
      }
      p{
        color: inherit;
        text-align: center;
        line-height: normal;
        font-size: 13px;
        font-family: 'Raleway';
      }
    }
  }

  .hintText {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #1397e1;
    margin-top: 19px;
    margin-bottom: 27px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen 
    and (max-width: 991px)
    { 
      .hintText {
        width: auto;
      }
      .personal-info-form,
      .company-info,
      .type-info-form {
        width: auto !important;
      }
  }


  .profile_confirmation {
  width: 100%;
  min-height: 640px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  background-color: $blue;

  &:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 50%;
    position: absolute;
    background: #f0f0f0;
    border-radius: 0 0 100% 100% / 40%;
  }
  }

  .profile_confirmation__inner {
  margin-top: 10px;
  height: 540px;
  position: relative;
  bottom: 0;
  }
  .profile_confirmation_form {
  margin: 50px 0px 25px 0px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  @media only screen and (min-width: 568px) {
    padding: 20px 50px 30px 50px;
  }
  padding: 20px 15px 30px 15px;
  }

  .edit_link {
  text-align: center;
  }

  .profile_confirmation_heading {
  color: #36434b;
  font-size: 16px;
  padding: 0px 0px 5px 0px;
  }

  .profile_confirmation_content {
  font-size: 14px;
  text-align: center;
  color: #62717a;
  font-weight: 500;
  line-height: 1.43;
  }
  .email_phone {
  color: $blue;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  }
  .edit_link {
  text-decoration: none;
  color: #36434b;
  font-size: 14px;
  }
  .contact_container {
  margin-top: 30px;
  }
  .phone_container {
  @media only screen and (min-width: 568px) {
    padding-left: 78px;
  }
  }
  .email_container {
  @media only screen and (min-width: 568px) {
    padding-right: 79px;
  }
  }
  .email_edit {
  background-color: #f7f7f7 !important;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  &:hover{
    border:'none';
  }
  &:focus {
    text-align: center;
    font-size: 12px;
  }
  height: 22px;
  font-size: 12px;
  width: 177px;
  text-align: center;

  }
  .phone_edit {
  background-color: #f7f7f7 !important;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  &:hover{
    border:'none';
  }
  &:focus {
    text-align: center;
    font-size: 12px;
  }
  height: 22px;
  font-size: 12px;
  width: 177px;
  text-align: center;
  }
  .phone_edit_button,.phone_edit_back_button{
  @media only screen and (max-width: 411px) {
    padding-bottom: 15px;
  }
  }
  .email_phone_edit_error {
  margin-bottom: -20px;
  color:#FF0000;
  font-size: 11px;
  text-align: center;
  width:173px;
  }

  .icon {
  font-size: 90px;
  color: #e1e1e1;
  position: relative;
  // top: 0px;
  // left: 6px;
  width: 100%;
  height: 100%;
  text-align: center;
  }
  .user-icon{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  :nth-child(1){
    width: 100%;
    height: 100%;
  }
  }

  .confirm {
    width: 100%;
    min-height: 660px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    position: relative;
    background-color: $blue;
    height: 100vh;
    &:before {
      content: '';
      top: 0;
      left: 0;
      margin-left: -10%;
      width: calc(100% + 20%);
      height: 50%;
      position: absolute;
      background: $off-white;
      border-radius: 0 0 100% 100% / 40%;
    }
  }
  .confirmationBox {
      border-radius: 6px;
      background-color: $white;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
    }
  .confirmationPadding {
    color: #36434b;
    font-size: 16px;
    font-weight: bold;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 45px;
  }
  .confirmDiv { top: 20px;
    display: table;
    margin: 0 auto;
  }

  .emailSentTo{
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    padding: 0px 0px;

  }
  .phoneNumber{
  font-family: Raleway;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1397e1;     
    
  }
  .emailText{
    font-family: Raleway;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: center;
    color: #1397e1;     
    padding: 0px 0px;

  }
  .phoneCode{

    width: 276px;
    height: 16px;
    font-family: Raleway;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;

  }
  .phoneInput{
    width: 363px;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
  }

  .emailLastText{
      font-family: Raleway;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #62717a;
      padding: -1px 0px 0px 0px
  }

  .emailLabel {
    color: $grey;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    margin-bottom: 0px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    margin-top: 0px;
  }

  .emailField {
    width: 100%;
    height: 36px;
    border: none;
    background-color: #f7f7f7 !important;
     font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      border: 'none';
    }
  }

  .PhoneField {
    width: 330px;
    height: 36px;
    border: none;
    background-color: #f7f7f7 !important;
    font-size: 15px !important;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020;
    border-radius: 6px;
    padding-left: 18px;
    padding-right: 15px;

    &:hover {
      border: 'none';
    }
  }

  .phoneLabel { 
    width: 357px;
    font-family: Raleway;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: $grey;
    margin-top: 10px;
    margin-bottom: 7px;
  }

  .conFIrmformFields { margin-top:-18px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 89%;
  }

  .state_div{
    width: 100%;
    margin-bottom: 15px;
 
    .custom_select_with_scroll{
      margin-bottom:0px;
      margin-top: 4px;
      >div{
        @media screen and (max-width:767px) {
          padding: 0 5px 0px 15px;
      }
      }
    }
  }
  .stateLabel {
    color: $grey;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    margin-top: 15px;
   }
  .emailDiv {
    display: table;
    margin: -45px 0px 0px 142px;
  }

  .phoneDivBtn {
    display: table;
    margin: -3px 0px 0px 142px;
  }
  .emailSentTo{
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);

  }
  .texSentTo {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
  .forgotPassword{
    padding-top: 20px;
    width: 359px;
     a{
      text-decoration: none;
      font-size: 14px;
      color: $grey;
      font-weight: 500;
    }
  }
}

.checkbox{
    
  /* Hide the browser's default checkbox */
  .container input, .blue_text_container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #ffffff;
      border: 1px solid $blue;
      border-radius: 2px;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
      background-color: #ffffff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark{
      background-color: $blue;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
      content: "";
      position: absolute;
      display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after{
      display: block;
  }
  .container input:checked ~ .checkmark{
      border: 1px solid $blue;
  }
  /* Style the checkmark/indicator */
  .container .checkmark:after{
      left: 3px;
      top: -1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
  }
  /* Style the checked checkbox label */
  .container {
      display: block;
      position: relative;
      padding-left: 22px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: $blue ;
  }
}
.button_container{
  padding: 25px 0px 22px 0px;
}
.signup-personal .personal-info-form{
  text-align: center;
}
 .signup .emailLabel, .signup-personal .login .emailLabel ,  .signup .personalInfoPhone, .signup-personal .login .personalInfoPhone{
  text-align: left;
}
.signup-personal{
  .personal-info-form{
    .upload-block{
      .crop-block{
          display: block;
      .wid-50{
        width:50%;
        display: inline-block;
        padding: 10px 0px;
        @media screen and (max-width:767px) {
          padding: 20px 0px;
        }
        input {
          position: relative !important;
          @media screen and (max-width:767px){
            width: 108px;
          }
        }
      }
      button{
        @media screen and (max-width:767px) {
            width:45% !important;
        }
       
        input{
          position:  relative !important;
        }
      }
    }
      .error{
        width:auto;
        text-align: center;
      }
  }
}

.company-info .emailLabel{
  padding-top: 10px;
}
.personal-info-form .emailField {
  margin-top: 5px;
}
 .personal-info-form .emailLabel {
  margin-top: 27px;
}
.personal-info-form .inputField{
  margin-top:5px;
  margin-bottom: 0px;
}
.personal-info-form .hintText , .personal-info-form .checkbox .container{
  margin-bottom: 0px;
}
.personal-info-form .signupDiv {
      padding-top: 38px;
}
.personal-info-form .personalInfoPhone {
      margin-top: 20px;
}
@media screen and (max-width:767px){
  // .personal-info-form .emailField , .personal-info-form .emailLabel , .personal-info-form .personalInfoPhone{
  //   margin-top: 15px !important;
  // }
  
  .personal-info-form .signup .personalInfoPhone {
    margin-top: 0px !important;
  }
}
 }
 .company-info{
  .sm-pd-top{
    padding-top: 15px;
  }
 }

// login google outlook icon btn css start
.login,
.signup{
    .login-btn-ui{
        button > span{
            border: 1px solid #0071c5 !important;
            background: #0071c5 !important;
            color: #ffffff;
            padding: 4px;
            img {
                background: #ffffff;
                padding: 4px;
            }
        }
        button > span:hover{
            border: 1px solid #0071c5 !important;
            background: transparent !important;
            color: #0071c5;
            transition: background-color 0.3s ease-in-out;
        }
        
        span.login-btn-text {
            padding: 0 5px;
        } 
    }
    
}