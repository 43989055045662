.matter{ font-family: Raleway !important; }
.venue-space-edit {
  overflow-x: hidden;
  // padding-left: 40px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;

  .black-heading {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0;
    float: left;
    // width: 50%;
  }

  .add-spaces-button {
    width: 50%;
    text-align: right;
    float: right;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-align: right;
    color: #ee2738;
    font-family: raleway;
  }

  .venue-space-empty {
    width: 47%;
    height: 84.4px;
    background-color: #f0f0f0;
    margin: 5px;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .rectangle {
    // width: 352px;
    width: 100%;
    height: auto;
    border-radius: 6px;
    border: solid 1px #f0f0f0 !important;
    background-color: #ffffff;
    padding: 12px;
    box-shadow: none;
    margin: 10px 0 !important;

    .amenities {
      width: 508px;
      background-color: var(--white);
    }
  }

  .venue-space-edit-inner-container {
    width: 100%;
    position: relative;
        .block {
          display: block;
          width: 50%;

          .material-icons {
            color: #1397e1;
            margin-left: -5px;
          }
        }

        .add-space {
          position: absolute;
          right: 0px;
          @media screen and (max-width:767px) {
              top: 12px;
          }
        }
  }
  .add-space-button {
    width: 100px;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738;
    border: 0 !important;
    font-family: raleway;
    cursor: pointer;
    text-decoration: none;
  }
}
.venue_space_venue_card_single{
  width: 100%;
  padding: 8px 8px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .venue-space-edit {
    padding-left: 10px;
  }

  .venue-space-edit-inner-container {
    width: 100% !important;
  }
}
