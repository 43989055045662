@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap');
$primary: #ee2738;
$red: #FF0000;
$white: #ffffff;
$dark-grey:#36434b;
$off-white: #f0f0f0;
$grey: #62717a;
$light-grey: #9aa7af;
$secondary: #f1435e;
.contact_button button{
  color: #ee2738 !important;
  padding: 10px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Raleway !important;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  margin-top: 10px;
 }
 .contactBtn:hover{
  color: #ee2738;
  background-color: #ffffff;
 }
.contackInfoDiv{
  padding-top: 15px;
  width: 100%;
  .managerInfo .MuiCardHeader-content-273 span {
    font-family: Raleway !important;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
   }
 

  .MuiPaper-elevation1-123 {
    box-shadow:none !important;
}
  .MuiCardHeader-action-246 {
    margin-right: 4px !important;

    

    button {
      border-radius: 6px;
      border: none;
      background-color: #fff !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      text-align: center;
      color: #ee2738;
      top:9px;
      padding: 11px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }
  }
  
  .MuiButton-contained-264 {
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    color: #ee2738;
}

  .managerDiv{
    width: 100%; 
    
  }
  .managerInfoDiv{
    width: 100%; 
    padding: 0px 16px 0px 4px;
    
   
  }

  .managerInfo{
    width: 100%;
    padding:11px;
    background-color: #f0f0f0 !important; 
    
    
    .contactDiv {
      width: 100%; 
      min-width: 350px;
     }
   
  }
  .listDiv{ padding-top: 20px;
    
    .ulDiv{   
        border-radius: 6px;
        border: solid 1px #f0f0f0;
        background-color: #f7f7f7;
        width: 100%; padding: 6px;
      
        
          .ulDiv li .MuiListItem-root-154 {
            padding-top: -2px !important;
            padding-bottom: 8px !important;
          }

      }
      .iconClass { 
        background-color: #1397e1 !important;
        font-family: material;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color:#fff;
      }
      .MuiListItem-root-154{
        padding-top: 0px !important;
        padding-bottom:3px !important;
      }
      .MuiAvatar-root-250{
        width: 21px;
        height: 28px;
      }
      .MuiSvgIcon-root-279{
        width: 20px;
        height: 20px;
      }
  }
  .MuiCardHeader-root-244 {
    width: 100% !important;
  }

  .MuiCardHeader-root-244 {
    display: flex;
    padding: 0px;
    align-items: center;
  }

  .MuiTypography-body2-207 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
  }
  .MuiCardHeader-subheader-249{
    font-family: Raleway !important;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;}
    .infoMenu {
      div{
        font-family: Raleway !important;
        font-size:14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
      }

  }
  
}

@media only screen 
and (min-device-width: 320px)
and (max-device-width: 991px)
{
  .contackInfoDiv{
    padding-top: 15px;
    width: 100%;
    .contactBtn{padding: 10px 7px;}
    .MuiCardHeader-action-246 button {
      border-radius: 6px;
      border: none;
      background-color: #fff !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      text-align: center;
      color: #ee2738;
      top:7px;
      padding: 11px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }
    .MuiButton-contained-264 {
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      color: #ee2738;
  }
 .managerDiv{
      width: 100%; 
      
    }
    .managerInfoDiv{
      width: 100%; 
      padding: 0px 0px 0px 4px;
      
     
    }
  
    .managerInfo{
      width: 100%;
      padding:11px;
      background-color: #f0f0f0 !important; 
      
      
      .contactDiv {
        width: 100%; 
        min-width: 350px;
       }
     
    }
    .listDiv{ padding-top: 20px;
      
      .ulDiv{   
          border-radius: 6px;
          border: solid 1px #f0f0f0;
          background-color: #f7f7f7;
          width: 100%; padding: 6px;
        
          
            .ulDiv li .MuiListItem-root-154 {
              padding-top: -2px !important;
              padding-bottom: 8px !important;
            }
  
        }
        .iconClass { background-color: #1397e1 !important;
          font-family: material;
          font-size: 20px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color:#fff;
        }
        .MuiListItem-root-154{
          padding-top:0px !important;
          padding-bottom:3px !important;
        }
        .MuiAvatar-root-250{
          width: 21px;
          height: 28px;
        }
        .MuiSvgIcon-root-279{
          width: 20px;
          height: 20px;
        }
    }
    .MuiCardHeader-root-244 {
      width: 100% !important;
    }
  
    .MuiCardHeader-root-244 {
      display: flex;
      padding: 0px;
      align-items: center;
    }
  
    .MuiTypography-body2-207 {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
    }
    .MuiCardHeader-subheader-249{
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;}
      .infoMenu .MuiTypography-subheading-206{
        font-family: Raleway;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
        // padding: 0px 20px;
    &:before {
      content: 'local_airport';
      font-family: 'Material Icons';
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      top: 5px;
      left: 12px;
      color: #1397e1;
      z-index: 1;
    }
  
    }
    
  }
  
}