/***
* == Event Header Style
***/

.topEventNav
{
  header {
    border-bottom-style: solid !important;
    border-bottom-width: 1px!important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
}
a:hover{
  color: #ee2738 !important;
  background: transparent;
}
.home_page {
  header {
    border-bottom-style: none !important;
    border-bottom-width: none !important;
    border-image-source: none !important;
    border-image-slice: none;
  }
}
 
  width:100% !important; 
    color: #36434b;
    background-color: #ffffff;
    align-items: center;
    display: flex;
    
    .EventPlannerHeader-appbar-106 {
      color: #36434b;
      box-shadow: inset 0 -1px 0 0 #fff !important;
      background-color: #ffffff !important;
     }
    
    .MuiListItem-root-188.MuiListItem-selected-199, .MuiListItem-root-188.MuiListItem-selected-199:hover, .MuiListItem-root-188.MuiListItem-selected-199:focus {
      background-color: transparent !important;
 }
    .EventPlannerHeader-image-529{border: none !important;}
  .toplogoDiv{
    float: left;
    // padding: 17px 0px 0px 0px;
    img{
      // width: 32.5px;
      height: 56px;
      border: none !important;
      border-radius: 50px;
      margin-bottom: -4px;
    }
    &:hover{
      background: transparent;
    }
    &:focus{
      background: transparent;
    }
  }

  .topSearchDiv{ 
    width:100%; 
    display: inline-block;
    .headesearch_container  {
      >div:first-child{
          &:before{
           content: 'search';
           font-family: 'Material Icons';
           font-weight: normal;
           font-style: normal;
           font-size: 24px;
           line-height: 1;
           letter-spacing: normal;
           text-transform: none;
           display: inline-block;
           white-space: nowrap;
           word-wrap: normal;
           position: absolute;
           top: 1px;
           left: 11px;
           color: #ccc;
           z-index: 1;
          }
      }
      
      width: 100% !important;
      height: 26px;
      border-radius: 13px; 
      position: relative;
      padding: 0px 0px 0px 0px;
      
      input {
  
          position: relative;
          float: left;
          width: 100%;
          font-size: 12px;
          border: 0;
          border-radius: 13px; 
          height: 26px; 
          padding: 5px 0px 5px 35px;
          background-color: #f7f7f7;
          border: 1px #f0f0f0 solid; 
          color: #9aa7af;
          &::placeholder{
            color: #b0b0b0;
        }
         }
      
        div {
          width: 100% !important;
          position: relative; 
          border-radius: 13px; 
          
        }
      .suggestionDiv{
      width: 80% !important;
      position: relative;
      font-size: 12px;
      line-height: 2.3;
      word-break: break-word;
      
      }
      input[role="combobox"]+div {
  
          z-index: 9;
          top: 35px !important;
          left: 0px !important;
          position: absolute !important;
          width: 100% !important;
          min-width: 66% !important;
          min-height: 150px !important;
          background: #fff;
          overflow-y: scroll;
          height: 130px;
          box-shadow: 0px 15px 10px -15px #ccc;
          
        }
        input[role="combobox"]+div .item{
          padding: 0px 29px;
          font-size: 12px!important;
          
      }
        input[role="combobox"]+div .item-highlighted{
          background: #eee;
          width: 100% !important; 
          cursor: pointer; 
          font-size: 12px;
          border-radius: 0px;
      }

  }
  }
  
  .topEventMenu {
    // width: 32%;
    margin-left: 20px;
    margin-right: 20px;
    // float: left;
    // display: block;
    text-transform: uppercase;
    text-decoration: none;
    
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #36434b;
    border-bottom:1px solid transparent;
    // padding: 28px 0px 12px 4px;
    // @media screen and (min-width:1024px) and (max-width: 1200px) {
    //   width: 37%;
    // }
    
  }
  
  .MuiMenuItem-root-151 { 
    background-color: transparent !important;
    border-bottom: none !important;
  }
  .MuiMenuItem-root-151:hover { 
      background-color: transparent !important;
  }
  .MuiListItem-button-163:hover {
    text-decoration: none;
    background-color: transparent !important;
}
  
  

  .MuiListItem-root-154.MuiListItem-selected-165, 
  .MuiListItem-root-154.MuiListItem-selected-165:hover, 
  .MuiListItem-root-154.MuiListItem-selected-165:focus {
    width: 25%!important;
    background-color: transparent !important;
    border-bottom: 2px solid #ee2738 !important;
}
.EventPlannerHeader-sectionDesktop-104 { 
  width: 100% !important; 
  }
  .rightImg {
    width: 32px; 
    height: 32px;
    border-radius: 50px;
}
.rightImg:hover {
  background: transparent !important;
}
.MuiIconButton-root-169:hover {
  background-color: transparent !important;
}
 

  .EventPlannerHeader-sectionDesktop-376 { width: 100% !important; }
  .EventPlannerHeader-image-380 {
   
    border: none !important;
}
.EventPlannerHeader-image-108 {
  // width: 32.5px;
  height: 56px;
  border: none !important;
  border-radius: 50px;
}

  
}

@media only screen and (min-device-width: 320px) and (max-device-width: 991px)  {
  

  .topEventNav
    {
      width:100% !important; 
      color: #36434b;
      button > span{
        &:hover{
          background: transparent;
        }
        &:focus{
          background: transparent;
        }
      }
      .EventPlannerHeader-sectionMobile-105 {
        display: flex;
        float: right;
      }

     .EventPlannerHeader-appbar-106 {
        color: #36434b;
        box-shadow: inset 0 -1px 0 0 #fff !important;
        background-color: #ffffff !important;
       }
      .MuiListItem-root-188.MuiListItem-selected-199, .MuiListItem-root-188.MuiListItem-selected-199:hover, .MuiListItem-root-188.MuiListItem-selected-199:focus {
        background-color: transparent !important;
   }
      .EventPlannerHeader-image-529{border: none !important;}
      .toplogoDiv{
        float: left;
        // padding: 6px 0px 0px 0px;
        img{
          // width: 32.5px;
          height: 56px;
          border: none !important;
          border-radius: 50px;
          margin-bottom: -4px;
        }
      }

      .topSearchDiv{ 
        width:76%; 
        display: inline-block;
        .headesearch_container  {
          >div:first-child{
              &:before{
              content: 'search';
              font-family: 'Material Icons';
              font-weight: normal;
              font-style: normal;
              font-size: 24px;
              line-height: 1;
              letter-spacing: normal;
              text-transform: none;
              display: inline-block;
              white-space: nowrap;
              word-wrap: normal;
              position: absolute;
              top: 13px;
              left: 11px;
              color: #ccc;
              z-index: 1;
              }
          }
          
          width: 100% !important;
          height: 26px;
          border-radius: 13px; 
          // padding-left: 20px;
          position: relative;
          // padding: 9px 0px 0px 20px;
          
          input {
      
              position: relative;
              float: left;
              width: 100%;
              font-size: 12px;
              border: 0;
              border-radius: 13px; 
              height: 30px; 
              margin-top: 10px;
              padding: 0px 0px 3px 20px;
              background-color: #f7f7f7;
              border: 1px #f0f0f0 solid; 
              
            }
          
            div {
              width: 100% !important;
              position: relative; 
              border-radius: 13px; 
              
            }
          .suggestionDiv{
          width: 80% !important;
          position: relative;
          font-size: 12px;
          line-height: 2.3;
          word-break: break-word;
          
          }
          input[role="combobox"]+div {
      
              z-index: 9;
              top: 38px !important;
              left: 11px !important;
              position: absolute !important;
              width: 97% !important;
              min-width: 66% !important;
              min-height: 150px !important;
              background: #fff;
              overflow-y: scroll;
              height: 130px;
              box-shadow: 0px 15px 10px -15px #ccc;
              
            }
            input[role="combobox"]+div .item{
              padding: 0px 29px;
              font-size: 12px!important;
              
          }
            input[role="combobox"]+div .item-highlighted{
              background: #eee;
              width: 100% !important; 
              cursor: pointer; 
              font-size: 12px;
              border-radius: 0px;
          }

      }
      }
      
      .topEventMenu {
        width: auto !important;
        float: left;
        display: block;
        text-transform: uppercase;
        text-decoration: none;
        height: auto !important;
        font-family: Raleway;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: #757575;
        padding: 12px 0px 12px 4px
      }
      
      .MuiMenuItem-root-151 { 
        background-color: transparent !important;
        border-bottom: none !important;
      }
      .MuiMenuItem-root-151:hover { 
          background-color: transparent !important;
      }
      .MuiListItem-button-163:hover {
        text-decoration: none;
        background-color: transparent !important;
    }
      

      .MuiListItem-root-154.MuiListItem-selected-165, 
      .MuiListItem-root-154.MuiListItem-selected-165:hover, 
      .MuiListItem-root-154.MuiListItem-selected-165:focus {
        width: 25%;
        background-color: transparent !important;
        border-bottom: 2px solid #ee2738 !important;
    }
    .EventPlannerHeader-sectionDesktop-104 { 
      width: 100% !important; 
      }
      .rightImg {
        width: 32px; 
        height: 32px;
        border-radius: 50px;
    }
    .rightImg:hover {
      background: transparent !important;
    }
    }

    .MuiIconButton-root-169:hover {
      background-color: transparent !important;
    }
    .EventPlannerHeader-sectionDesktop-376 { width: 100% !important; }
  .EventPlannerHeader-image-380,.EventPlannerHeader-image-108, .EventPlannerHeader-image-506 {
    width: 32.5px;
    height: 32.5px;
    border: none !important;
    border-radius: 50px;
  }
  .event-header{
    header {
      border-bottom-style: solid !important;
      border-bottom-width: 1px!important;
      border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
      border-image-slice: 1;
      background-color: #ffffff !important;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
  
}
@media (min-width: 600px)
{
  .event-header{
    header {
      border-bottom-style: solid !important;
      border-bottom-width: 1px!important;
      border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
      border-image-slice: 1;
      background-color: #ffffff !important;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}
.shadow-none{
  box-shadow: none;
}
.logo_and_search{
  display: flex;
  align-items: center;
}
.first_list_row{
  p{
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase
  }
  .gray{
    color:  #62717a;
  }
  .red{
    color: #ee2738;
  }
  .blue{
    color: #1397e1;
  }
}
.second_list_row{
  h4{
    font-size: 13px;
    font-weight: 500;
    line-height: 1.23;
    color: #202020;
    margin:0;
  }
  h5{
    font-size: 13px;
    font-weight: bold;
    color: #62717a;
    margin: 15px 0;

  }
  h6{
    font-size: 10px;
    font-weight: bold;
    color: #62717a;
    margin: 15px 0;

  }
   
}
.request_button{
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  color: #ee2738;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
}
.book_button{
  border: solid 1px #ee2738;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  margin-left: 15px;
}
.buttons{
  justify-content: end;
}
.MuiIconButton-root-176:hover {
  background-color: transparent !important;
  color: #ee2738;
}
input{
  font-family: 'Raleway', sans-serif !important;
}
.event-header{
  button:hover{
    background-color:transparent;
    color: #ee2738;
  }
}
.venue-header{
  .save_button button{
    height: 30px !important;
    width: 90px !important;
  }
  .save_button button:hover{
    background: #d80e2a !important;
    color: #ffffff !important;
  }
}
.widget{
  .topEventMenu{
    margin-left: 0px !important;
    background-color: transparent !important;
    :hover{
      background:none;

    }
  }
  .topEventNav{
    margin-right: 34px;
    .toplogoDiv{
        padding-left: 25px;
    }
    li{
      button{
        border: 1px solid #e1e1e1 !important;
        padding: 6px 16px;
        font-size: 13px;
        min-width: 64px;
        box-sizing: border-box;
        transition: none;
        line-height: 1.75;
        font-weight: 500;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        border-radius: 6px;
        text-transform: none;
        box-shadow: none;
        :hover{
          border-radius: 6px;
        }
      }
    }
  }
  .header-div{
        padding-left: 0px;
        padding-right: 0px;
  }
}
.widget-place-hold{
  :hover{
    background: #d80e2a !important;
    color: #fff !important;
opacity: 0.9;
  }
  button{
    :hover{
      color: #ffffff !important;
    }
  }
}
.header-mobile{
  button{
@media screen and (max-width:767px) {
  padding: 12px 5px;
}
  }
}