.my_hold_page{
    display: block;
    margin-top: 70px;
    .myhold-header {
        display: block;
        width: 95%;
        text-align: center;
        margin: auto;
        .filter-tabs{
            width: 52% !important;
            float: left;
            @media screen and (max-width:767px) {
                width: 100% !important;
            }
                button{
                    @media screen and (max-width:767px) {
                        width: 350px;
                    }
                }
        }
        .search-hold{
            display: inline-block;
            width:47%;
            float: right;
            @media screen and (max-width:767px) {
                width: 100%;
            }
        }
        .search-image{
            display: inline-block;
            width: 10%;
            padding: 10px 0px 10px 15px;
            text-align: center;
            @media screen and (max-width:767px) {
                width: 20%;
                padding: 10px 0px 10px 0px;
            }
        }
        .download{
            display: inline-block;
            width: 10%;
            text-align: right;
            @media screen and (max-width:767px) {
                width: 20%;
            }
            a{
                color: #36434b;
            }
        }
        .searchBox{
            display: inline-block;
            vertical-align: top;
            padding-top: 10px;
            // width: 100%;
            // @media screen and (max-width:767px) {
            //     width: 60%;
            // }
            input{
                border: none;
                width: 100%;
                padding: 5px 15px;
                font-size: 12px;
                padding-left: 5px !important;
            }
        }
    }
    .myhold-content{
        display: block;
        margin:auto;
        text-align: center;
        background-color: #f0f0f0;
        padding-top: 10px;
        min-height: 82vh;
        @media screen and (max-width:767px) {
            height: 100%;
        }
                .my-hold{
                    width: 95%;
                    background-color: #ffffff;
                    align-items: center;
                    margin: auto;
                    border: solid 1px #e1e1e1;
                    display: inline-block;
                    padding: 0px 20px;
                    border-radius: 6px;
                }
                .first-div{
                    display: block;
                    width: 20%;
                    float: left;
                    padding: 8px 15px 0px;
                    @media screen and (max-width:767px) {
                        width: 100%;
                    }
                    .hold-image{
                        display: inline-block;
                        width: 35%;
                        text-align: center;
                        @media screen and (max-width:767px) {
                            width: 100%;
                        }
                        .image{
                            width: 60px;
                            height: 60px;
                            object-fit: cover;
                            border-radius: 50%;
                            @media screen and (max-width:767px) {
                                border-radius: 0px;
                                max-height: 100%;
                                height: auto;
                                width: 100%;
                            }
                        }
                    }
                    .hold-details{
                        width: 100%;
                        display: inline-block;
                        text-align: left;
                        vertical-align: top;
                        padding-top: 18px;
                        @media screen and (max-width:767px) {
                            width: 100%;
                        }
                    // float: left;
                    .hold-name {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        word-break: break-word;
                        font-style: normal;
                        line-height: 1.23;
                        letter-spacing: normal;
                        text-align:left;
                        color: #1397e1;
                        @media screen and (max-width:991px) and (min-width:768px) {
                           font-size: 13px;
                        }
                    }
                    }
                }
            
    
                .middle-div{
                    display: block;
                    float: left;
                    width: 40%;
                    padding: 8px 0px;
                    @media screen and (max-width:767px) {
                        width: 100%;
                    }
    
                    .mid-three-sec{
                        display: inline-block;
                        width: 40%;
                        text-align: left;
                        @media screen and (max-width:767px) {
                           padding-left: 15px;
                        }
                    }
                    .mid-two-sec{
                        display: inline-block;
                        width: 25%;
                        text-align: left;
                        @media screen and (max-width:767px) {
                           padding-left: 15px;
                        }
                    }
                    .mid-one-sec{
                        display: inline-block;
                        width: 35%;
                        text-align: left;
                    }
                            .d-ago {
                                font-family: Raleway;
                                font-size: 13px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                text-align: left;
                                color: #9aa7af;
                            }
                            .date-hold{
                                font-family: Raleway;
                                font-size: 14px;
                                font-weight: 500;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.67;
                                letter-spacing: normal;
                                text-align: left;
                                color: #202020;
                            }
                            .all-day {
                                font-family: Raleway;
                                font-size: 13px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                text-align: left;
                                color: #9aa7af;
                            }
                            .date-expired {
                                font-family: Raleway;
                                font-size: 13px;
                                font-weight: 500;
                                font-stretch: normal;
                                word-break: break-word;
                                font-style: normal;
                                line-height: 1.23;
                                letter-spacing: normal;                           
                                color: #1397e1;
                                @media screen and (max-width:991px) and (min-width:768px) {
                                   font-size: 13px;
                                }
                            }
                }
                .last-div{
                    display: block;
                    float: left;
                    width: 40%;
                    text-align: left;
                    padding: 15px 0px;
                    @media screen and (max-width:767px) {
                        width: 100%;
                    }
                    .last-one-sec{
                        display: inline-flex;
                        width: 35%;
                        justify-content: center;
                        padding-right: 8%;
                    }
                    .last-two-sec{
                        display: inline-block;
                        width: 35%;
                        @media screen and (max-width:767px) {
                            // text-align: end;
                            padding-left: 60px;
                         }
                    }
                    .last-three-sec{
                        display: inline-block;
                        width: 15%;
                        @media screen and (max-width:767px) {
                           text-align: end;
                           padding-left: 20px;
                        }
                        .checkbox .container .checkmark:after {
                            left: 3px;
                            top: -1px;
                            width: 5px;
                            height: 10px;
                            border: solid white;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                        .checkbox .container input:checked ~ .checkmark:after {
                            display: block;
                        }
                        .checkbox .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                    }
                    .last-four-sec{
                        display: inline-block;
                        width: 15%;
                    }
                    .last-text {
                        font-family: Raleway;
                        font-size: 13px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 2;
                        letter-spacing: normal;
                        text-align: center;
                        color: #36434b;
                        @media screen and (max-width:767px) {
                            font-size:13px;
                        }
                    }
                }
    }
    
    .my-hold{
        &.entry-box{
            margin-bottom: 12px;
            .page-select{
                padding: 6px 8px 8px 8px;
                width: 48px;
                background: #f7f7f7;
            }
        }
        &.no-data{
            padding: 16px;
        }
    }
    
    .event-header{
        .first-div,.middle-div,.last-div{
            padding:8px 15px 8px;
            text-align: center;
        }
        p{       
            font-family: Raleway;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #36434b;
            cursor: pointer;
            &.disable{
              cursor: default;
            }
            @media screen and (max-width:767px) {
                font-size:13px;
            }
            .material-icons{
                &.disable{
                    color:#929292;
                }
                font-size: 18px;
                padding: 3px 0px 0px 0px;
            }
            
        }
        .mid-three-sec {
            padding-left: 10px;
        }
    }
          
          
    }
    
    .tabs{
        border: none;
        box-shadow: none;
        span{
            background-color: #ee2738 !important;
            // width: 100px !important;
            left: 20px;
            height: 3px !important;
            // @media screen and (max-width:767px) {
            //    width: 89px !important;
            //  }
        }
        button{
            // min-width: 100px !important;
            :active{
                color: #ee2738 !important;
                font-weight: 800;
            }
            span{
                color: #36434b;
                font-weight: bold;
                width: 100% !important;
                background-color: transparent !important;
                height: auto !important;
                @media screen and (max-width:991px) {
                    font-size: 13px !important;
                 }
                @media screen and (max-width:320px) {
                    font-size: 11px !important;
                 }
            }
        }
    }
    #simple-menu {
            :nth-child(2){
                box-shadow: none;
                border: 1px solid #f1f1f1;
            }
    }
    .tabs{
        :nth-child(2){
            div{
                @media screen and (max-width:767px) {
                    width: 250px;
                 }
        }
    }
    }
    .box-tab {
        div
      {  :nth-child(2)
    {
        @media screen and (max-width:767px) {
            width:100%;
         }
    }
    }
    }
    .myholdpage
    {
    .tabs{
        Button{
          
            @media screen and (min-width:768px)  {
                width: 145px;
                min-width: auto;
                span{
                    font-size: 12px !important;
                }
            }
            @media screen and (min-width:1024px) {
                width: 93px;
                min-width: auto;
            }
            @media screen and (min-width:1220px) {
                width: 115px;
                min-width: auto;
            }
           
        }
    }
    }
    // my_hold_page fixed event-header scss start
    .my_hold_page{
        .event-header {
            .first-div{
                p {
                    text-align: left;
                    margin-left: 24%;
                }
            } 
            .mid-one-sec{
                p {
                    padding-left: 11%;
                }
            } 
            .last-div{
                .last-two-sec{
                    p {
                        text-align: left;
                        padding-left: 17%;
                    }
                }
                .last-one-sec{
                    p {
                        padding-right: 35%;
                    } 
                } 
            } 
        }
        .no-data {
            background-color: #ffffff;
            margin-top:50px;
            padding: 150px 0;
        }
    }
    
    @media screen and (min-width:1600px) {
        .my_hold_page{
            .event-header {
               .last-div{
                    .last-two-sec{
                        p {
                            text-align: left;
                            padding-left: 25%;
                        }
                    }
                }
                
            }
        }
    }
    .my_hold_page{
        .my-hold.entry-box {
            margin-bottom: 20px !important;
        } 
    }
    
    .reason-text {
        min-height:56px;
    }
    
    .place-hold-popup{
        .add-new-form-block {
            height: auto !important;
        }
    } 
    .success_modal_container.hold_settings div:nth-child(2) div:first-child {
        .dialog-content{
          min-height: auto !important;
        }
    }
    .planner-profile-popup{
        p{
            word-break: break-all;
        }
    }
  .calendar-block{
    .searchBox.new {
        position: relative;
        padding-left: 10px;
    }
    .search-image {
        position: absolute;
        top: 13px;
        padding: 0 !important;
        // border: 1px solid #ccc;
        width: auto !important;
        height: 28px;
        border-left: 0;
        cursor: pointer;
    }
    button {
        background: transparent !important;
        color: #000000  !important;
        box-shadow: none !important;
        width: auto;
        // margin-top: 8px;
        // margin-left: 20px;
    }

    
  }  
  @media screen and (max-width:1030px) {
    .search-hold.calendar-block,
    .my_hold_page .myhold-header .filter-tabs{
        width: 100% !important;
    }
}
.searchBox.blocks {
    position: relative;
    // width: 60% !important;
    label {
        position: absolute;
        top: 15px;
        right: 2px;
        cursor: pointer;
    }
    .calendar-icon {
        color: rgba(0, 0, 0, 0.54);
        font-size: 18px;
    }
    .search-image button {
        margin: 0;
        padding: 0;
        min-width: 30px !important;
    }
}
.searchBox.blocks {
    width: 18% !important;
}
.searchBox.new {
    width: 44% !important;
}
.searchBox.btn {
    width: 6% !important;
    min-width:auto !important;
}
.searchBox.btn button {
    min-width: auto;
}
@media screen and (max-width:1380px) {
    .searchBox.blocks {
        width: 18% !important;
    }
}
@media screen and (max-width:1030px) {
    .searchBox.blocks {
        width: 15% !important;
    }
}
@media screen and (max-width:768px) {
    .searchBox.blocks {
        width: 20% !important;
    }
    .searchBox.new {
        width: 34% !important;
    }
}

.react-datepicker__input-container {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
}
input#openCalndar,
.searchBox input {
    text-transform: uppercase;
    color: #36434b;
    font-weight: bold;
}
.searchBox.new {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin-top: 10px;
    padding: 0 !important;
}
.searchBox {
    margin-right: 15px;
}
.searchBox.btn {
    margin: 0 !important;
}