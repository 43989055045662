$white:#ffffff;
$red: #ee2738;
.matter{ font-family: Raleway !important; }
.space-info {
  // overflow-x: hidden;
  .contents {
    width: 80%;
    height: auto;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
  }
  .vilableDescriptionDiv{
    @media screen and (max-width:767px)
    {
      padding-top: 20px;
    }
  }
  .alice-carousel{
    margin-top: 0px;
  }

  .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 5px;
    font-weight: bold;
  }

  .black-heading {
    color: #36434b !important;
    margin: 10px 0 5px;
    font-weight: bold;
  }

  .dayTimeDiv {
    width: 100%;
    color: #202020;

    .mondFriDiv {
      width: 100%;
      float: left;
    }

    .monTextDiv .MuiListItem-gutters-162 {
      padding: 3px 0px 20px 0px !important;
    }

    .avgPriceDiv {
      width: 100%;
      float: left;
      padding-top: 5px;
      position: relative;
      .additional-list{
        list-style:none;
        li{
          padding-left: 18px;
        }
        li:before {
          content: "";
          line-height: 7px;
          width: 7px;
          height: 7px;
          margin: 7px 5px 0 4px;
          background-color: #1397e1;
          float: left;
          border-radius: 55%;          
          position: absolute;
          left: 0px;
        }  
      }
    }

    .satSunfDiv {
      width: 26%;
      float: left;
    }
  }

  .avilableDescriptionDiv {
    width: 80%;

    .description {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;

      p {
        margin: 20px 0 !important;
      }
    }
  }

  @media screen and (max-width:767px)
  {
    .mondFriDiv {
      width: 100% !important;
    }
  }
}
