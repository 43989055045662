.membership-wrapper{
    h1.main-heading {
        color: #747474;
        font-size: 28px;
        font-weight: 600;
        padding-bottom: 20px;
    }
    strong{
        color: #747474;
    }

    p.content{
        padding: 15px 0;
        color: #747474;
        font-size: 16px;
        font-weight: 600;
        a{
            color: #747474;
            text-decoration: underline; 
        }
    } 

    ul.list-block{
        list-style: disc;
        padding-left: 20px;
        li {
            color: #767171;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 0;
            a{
                color: #747474;
                text-decoration: underline; 
            }
        }
    } 

    .sub-heading {
        color: #747474;
        font-size: 25px;
        font-weight: 600;
        padding: 20px 0;
    }
    .billing-list {
        padding-bottom: 20px;
    }

    .pd-bottom{
        padding-bottom: 30px;
    }

    .search-btn.started a,
    .next-btn,
    .back-btn {
        background-color: #d80e2a !important;
        color: #ffffff !important; 
        padding: 10px 20px !important;
        border: 1px solid #d80e2a !important; 
        margin-top: 20px;
        /* width: 100%; */ 
        text-decoration: none !important; 
        font-size: 15px; 
        border-radius: 4px; 
        font-weight: 600; 
        display: inline-block; 
        text-align: center; 
    } 

    select.select-subscription {
        border: 1px solid #cccccc;
        width: 400px;
        border-radius: 5px;
        display: block;
        -webkit-appearance: auto;
        float: none;
    }
    .pt-20{
        padding-top: 20px;
    }

    .cancel-subscription p {
        padding: 5px 0;
    }
    .textarea {
        min-width: 500px !important;
        max-width: 500px !important;
    }

    .text-center{
        text-align: center;
    }
    .custom_select_with_scroll>div {
        max-width: 250px;
    }

    .page-height {
        height: 75vh;
    }

    .page-height2{
        height: 85vh;
    }

    .next-btn {
        float: right;
        color: #ffffff;
        span {
            color: #ffffff !important;
        }
    }
    .back-btn {
        span {
            color: #ffffff !important;
        }
    }

    .pb-10{
        padding-bottom: 10px;
    }
    .subscription-list{
        .custom_select_with_scroll>div {
            max-width: 350px;
        }
    }

    @media screen and (max-width:1023px){
        .textarea {
            min-width: auto !important;
            max-width: 500px !important; 
        }
    }

    .switch-btn{
        margin-top: -12px !important;
        margin-left: -15px !important;

    }
    .text-underline{
        text-decoration: underline;
    }
}

@media screen and (max-width:1023px){
    .membership-wrapper{
        padding-left: 15px;
        padding-right: 15px;
    }
}