$black: #202020;
$water_blue: #1397e1;
$white: #ffffff;
.venueCardWithFav{
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
    .venue{
        position: relative;
        @media screen and (max-width:767px) {
           width: 100%;
           padding: 5px 0px;
        }
        .venue_content{
            padding-top: 0px;
            padding-bottom: 10px;
        }
        .venue_name{
            font-size: 14px;
            line-height: 1.71;
            color: $black;
           
        }
        .venue_address{
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
        }
        
        .input_address{
            padding-top: 4px;
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            color: $water-blue;
            text-transform: uppercase;
        }
        .place_icon{
            font-size: 12px !important;
            padding-top: 1px;
        }
        .overlay_icon{
            position: absolute;
            width: 100% !important;
            @media screen and (max-width:767px) {
                // right: 30px;
                width: 90% !important;
                // height: 100% !important;
            }
        }
        .venue_image{
            @media screen and (max-width:500px) {
                width: 100% !important;
                height: auto !important;
            }
            &:hover {
                box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7);
                transition: 300ms ease-in-out;
            }
        }
    }
    .venue_address{
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        color: #36434b;
    }
    .venue_city{
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        color: $water_blue;
    }
}
        .venue_space_venue_card .venue_image{
            width: 100% !important;
            object-fit: cover;
            height: auto !important;
        }
