@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", 'Raleway', sans-serif,
    monospace;
}

.Content {
  width: 100%;
}

p,
li,
textarea,
button,
input {
  font-family: 'Raleway', sans-serif !important;
}

:focus {
  outline: 0 !important;
}

.fourohfour {
  text-align: center;
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
}

.fourohfour h1 {
  color: #5e5c5c;
  text-align: center;
  font-size: 200px;
  font-weight: 900;
  margin: 0;

}
.fourohfour h3 , .fourohfour h2{
  font-weight: 700;
}

.datepiker::placeholder {
  opacity: 1;
  color: #62717a;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {

    .logo_image {
      width: 194px;

    }

    .verified_image {
      width: 16px;
    }
  }
}

.gallery-modal .gallery {
  padding: 20px;
}

@media not all and (min-resolution:767px) {
  @supports (-webkit-appearance:none) {

    .logo_image {
      width: 100% !important;
    }

  }
}

.gallery-modal .gallery {
  padding: 20px;
}

@media screen and (max-device-width:768px) {
  .gallery-modal--close {

    padding: 0px !important;
  }

  .gallery-modal--close button.gallery-close {
    font-size: 8px;
  }

  .success_modal_container .form .smaller input {
    cursor: default;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  .ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

}

.place-hold {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .logo_image {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 604px) {
  div.example {
    font-size: 50px;
    padding: 50px;
    border: 8px solid black;
    background: yellow;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: #b0b0b0 !important;
}


:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b0b0b0 !important;
}



input,
textarea {
  color: #202020;
}

.custom_select_with_scroll div {
  padding: 0;
}

.custom_select_with_scroll>div {
  padding: 0 15px;
}

.modalBox_content .custom_select_with_scroll>div {
  padding: 0 5px;
}
@media screen and (max-width:767px) {
  .modalBox_content .custom_select_with_scroll>div {
  padding: 0px 15px;
}
}

.datepiker::-webkit-input-placeholder {
  color: #62717a;
}

.search_container2 input::-moz-input-placeholder {
  color: #b0b0b0 !important;
}

.search_container2 input:-ms-input-placeholder {
  color: #b0b0b0;
}

.search_container2 input::placeholder {
  color: #b0b0b0;
}


input::-webkit-input-placeholder {
  color: #b0b0b0;

}

input:-ms-input-placeholder {
  color: #b0b0b0;

}

input::-moz-input-placeholder {
  color: #b0b0b0;

}

input::placeholder , select::placeholder{
  color: #b0b0b0;

}

.search_container2 input {
  color: rgb(98, 113, 122) !important;
}

.home-search .search_container2 ::-webkit-input-placeholder {
  color: rgb(98, 113, 122) !important;

}

.home-search .search_container2::placeholder {
  color: rgb(98, 113, 122) !important;

}

.home-search .search_container2 ::-moz-input-placeholder {
  color: rgb(98, 113, 122) !important;

}

.space-profile-edit input::-webkit-input-placeholder {
  color: #b0b0b0 !important;

}

.space-profile-edit input::placeholder {
  color: #b0b0b0 !important;

}

.space-profile-edit input::-moz-input-placeholder {
  color: #b0b0b0 !important;

}

.searchBgBox select {
  width: 100%;
  cursor: pointer;
}

.header-search-icon{    
  position: absolute;
  z-index: 9;
  color: #e0e0e0;
  cursor: pointer;
}

.success_modal_container div:nth-child(2) div:first-child{
  overflow-y: visible !important;
}
.hold_settings div:nth-child(2) div:first-child{
    max-width:550px;
}

  /* #menu-state div:nth-child(2){
  top: 400.062px !important;
  } */
  /* #menu-state{
    top: 117px !important;
  } */
  .edit_profile_component .edit_profile_component_form .custom_input .eye_icon{
    top: 50px;
  }

@media only screen and (min-width : 1920px) {
  #root {
    position: relative;
    min-height: 100vh;
  }

  .how_it_work_screen {
    width: 80% !important;
    margin: auto;
  }

  .similar_venue_card {
    position: relative;
    min-height: 65vh;
  }
}

.homeheader-icon:hover {
  background-color: transparent !important;
}

@media(max-width:767px) {
  #customized-menu div:nth-child(2) {
    right: 20px !important
  }
  .my_event_container .eventDiv{
    width: 100% !important;
  }
 
  .tall {
    height: auto !important;
  }
  #menu-endTime div:nth-child(2) , #menu-startTime div:nth-child(2){
    border-radius: 0px;
  }
}

.Notify #menu-list-grow {
  left: -318px;
}

@media(max-width:959px) {
  .header-search-icon{    
    font-size: 18px !important;
    top: 16px;
    left: 3px;
  }
}

@media(min-width:960px) {
  .logo_and_search button {
    display: none
  }

  .topEventNav .topSearchDiv .headesearch_container>div:first-child:before {
    top: 1px;
  }

  .topEventNav .topSearchDiv .headesearch_container input {
    height: 26px;
  }
  .header-search-icon{    
    position: absolute;
    z-index: 9;
    color: #e0e0e0;
    font-size: 18px !important;
    cursor: pointer;
    top: 5px;
    left: 15px;
  }
}

.add_space_close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 15px;
  cursor: pointer;
  display: block;
}

.position-relative {
  position: relative;
}

.dialogActions {
  color: #202020;
  top: 0px;
}

.venue_page .space-info{
  padding-bottom: 10px;
}

@media(max-width:1199px) {
  .space-info .seatindAndCapacityGrid .carousel div {
    width: 75px !important;
    max-width: 100% !important;
  }}
  @media(max-width:767px){
    .space-info .seatindAndCapacityGrid .carousel div {
      margin: 7px 0px !important;
    }
  }
.success_modal_container .closeButton:hover{
  color: #ee2738 !important;
}
/* .success_modal_container .events-content{
  overflow-y: scroll
} */
.success_modal_container dv:nth-child(2) div:first-child{
  overflow-y: visible;
}
.custom_select_with_scroll.time_select div{
  overflow-y: hidden !important;
}
.carousel .slide img {
  vertical-align: top;
  border: 0;
  object-fit: cover;
  object-position: bottom;
}
.listyourspace .banner_image{
  background: linear-gradient( rgb(0, 0, 0), rgb(0, 0, 0) );
  opacity: 0.5;
}
.listyourspace .banner-wrap {
  background: linear-gradient( rgb(0, 0, 0), rgb(0, 0, 0) );
}
.banner_image {
  height: 600px;
}
.signup-personal , .company-info-form{
background-color: #3C97E1 !important;
}
.contact_modal_container div:nth-child(2) div{
  overflow: visible !important;
}
.closeButton:hover{
  color: #ee2738 !important;
}
.banner .carousel img
{
  max-width: 1600px;
  max-height: 90vh;
}
.nextButton img{
  margin-right: 15px;
}
.carousel .slide{
  background: #ffffff !important;
}
/* .success_modal_container div div {
  overflow-y: visible !important;
  max-height: 450px;
} */
.browse_venue_search .custom-select:before{
  z-index: 1;
  right: 15px;
  cursor: pointer;
}
.banner_button .previousButton:hover{
  background-color: transparent;
}
.searchBgBox .search_container2 .date_pick{
  background-color: #f7f7f7 !important;
  cursor: pointer;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}
.select_field{
  z-index: 2 !important;
  background-color: transparent !important;
  cursor: pointer;
}
.header-button-div  .red-button-small{
  padding: 0px;
  background: linear-gradient(to bottom, #f1435e, #ee2738);
  font-family: Raleway;
  border-radius: 6px;
  height: 36px;
  width: 165px;
  border: 0px;
  font-weight: 500;
  color: #fff;
}
.searchBgBox .search_container2 .custom-select{
  border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.searchBgBox .custom-select:before{
  z-index: 1;
}
@media(max-width:991px){
  .view_photos_container .venue-gallery {
    min-width: 120px;
    /* padding-top: 10px; */
  }
}
@media(max-width:767px){

  .listyourspace .banner_image{
    opacity: 1;
  }
 
   .sm-100{
    width: 100% !important;
  }
  /* .carousel .slide img{
    height:300px !important;
  } */
  .buttons-group .btn-right , .buttons-group .btn-left{
      width: 48.7%;
      margin-top: 10px;
      float: left;
  }
  .btn-publish{
    margin-left: 7px !important;
  }
  .buttons-header .buttons-group .grey-button{
    width: 144px !important;
  }
  .venue_space_venue_card{
      width: 100% !important;
      display: block;
      text-align: center;
    }
    .space-planner-edit{
      padding-left: 0px !important;
      padding-top: 0px !important;
    }
    .venue-address-edit .textarea-small , .space-planner-edit .bordered{
      width: 100% !important;
    }   
  .success_modal_container .main_place_hold_section{
    max-width: 380px;
  }
  /* .checkBoxDiv div:first-child span{
      margin: 0px 0px 40px 0px;
  } */
  .form-content{
      padding: 20px 10px;
  }
  .place-hold button{
      border:none !important;
  }
  .banner_image{
    height: auto !important;
  }
  .contact_modal_container div:nth-child(2) div{
    max-width: 100%;
    /* width: 290px; */
  }
  #eventsContent{
    margin: 30px auto 0px !important;
  }
  .my_events .my_event_container .main_place_hold_section #eventsContent{
    margin: 5px auto !important;
}
  .success_modal_container .events-content{
    height: 240px !important;
    width:100%;
  }
  .success_modal_container .closeButton , .contact_modal_container .closeButton{
    right: -10px !important;
  }
  .success_modal_container .closeButton{
    color: #202020;
    top: -40px !important;
  }
  .end .react-datepicker-popper {
    right: 0px !important;
    left: auto !important;
    }
  .end{
      position: relative;
  }
  .end .react-datepicker__triangle{
    right: 20px !important;
    left: auto !important;
  }
  .add_venue .step_progress_bar{
    padding: 10px 20px; 
    }
    .main-form{
      padding: 15px !important;
  }
  .syncing_calendar .main-form{
    display: block !important;
    width: 100% !important;
    padding: 15px 4px !important;
}
.my_event_container .loading{
    left: 35% !important;
}
.my_event_container .form .white-button-small{
  width: auto !important;
}
}
.featured_venue_carousel .alice-carousel{
  margin-top: 0px;
}
.venue_page .space-planner-edit{
  padding-left: 0px;
}
.venue_page .contackInfoDiv .managerInfoDiv{
  padding: 0px 0px 0px 4px;
}
/* @media (min-width:1600px){
  .venue-profile-edit {
    height: 1350px;
  }
} */
.venue_page .venueProfile-section .contact_button{
  display: none;
}
@media (min-width:1920px){
  .venue_page .venue-available-right .venueRightContainer{
    overflow-y: scroll;
    /* height: 550px; */
    height: auto;
  }
}
.custom_input input:focus , .my_event_container .form .small select:focus , .my_event_container .form .small select:active, .signup input:focus , .success_modal_container .signup .emailField:focus, .login .emailField:focus , .form .input-field:focus , .success_modal_container .form .select_field:focus , .success_modal_container .form .select_field:active , .success_modal_container .form .searchSelect:active , .success_modal_container .form .searchSelect:focus{
  border: 1px solid #bbbbbb !important;
}
.state_div .custom_select_with_scroll:focus , .custom_select_with_scroll div:nth-child(2):focus , .custom_select_with_scroll div:nth-child(2):focus-within , .custom_select_with_scroll div:nth-child(2):active{
  border: 1px solid #bbbbbb;
}
.my_events select , .main_place_hold_section select{
  padding: 8px 15px !important;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: transparent;
  font-family: Raleway;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #202020 !important;
  text-transform: none !important;
  cursor: pointer;
}
.no-data-loader{
  display: block;
  text-align: center;
  padding: 20px;
  font-weight: 500;
  color: black;
}
.index_container .custom_select_with_scroll>div{
     padding: 10px;
}
.box-spacing-sm div:nth-child(2) , .box-spacing-sm div:nth-child(3){
      padding: 15px 10px;
}
li {
  white-space: normal !important;
}

.need_to_login_btn{
  display: block;
  width:100%;
  text-align: center;
  padding-top: 15px;
}
.need_to_login_btn button{
  margin: 5px;
  width: auto !important;
  text-transform: capitalize !important;
  padding: 6px 15px !important;
  font-size: 14px !important;
}
@media screen and (max-width:767px){
  .success_modal_container .main_place_hold_section{
    max-width: 310px;
  }
  .box-spacing-sm .start-sm , .box-spacing-sm .end-sm{
        height: 45px;
  }
  .time_select{
    position: relative;
  }
  .box-spacing-sm .end-sm{
    padding-bottom: 80px !important;
  }
  .end-sm
    .error{
      padding: 0px !important;
    }
  .need_to_login_btn button{
    width:70px !important;
    padding: 5px 10px !important;
    text-transform: capitalize !important;
    font-size:12px !important;
   }
}
#triangle-container{
  height: 100px;
  width: 100px;
  overflow: hidden;
  background-image: url(http://www.webdesign.org/img_articles/14881/site-background-pattern-07.jpg);
} */
.triangle-topleft {
  width: 0;
  height: 0;
  border-top: 100px solid red;
  border-right: 100px solid transparent;
}
.triangle-0 {

  /* border-bottom: solid 30px rgb(200,30,50);
  border-right: solid 30px rgb(200,30,50); */
  width: 0;
  height: 0;
  border-bottom: 100px solid red;
  border-left: 100px solid transparent;
}
.company-info-form .pd-top-title{
  padding-top: 15px;
}
.company-info-form .state_div div em{
  color: #202020a6;
}
.dont-miss-a{
  margin-top: 0px !important;
}
/*  BROWSERS THAT FIRST-HOLD WILL NOT BE ON  */
.message-block{
  display: block;
  align-items: center;
  margin: auto;
  padding: 80px 15px;
  padding-top: 120px;
  width: 100%;
  text-align: center;
  height: 100%;
  min-height: 100vh;
  color: #ffffff;
  background-color: #1397e1;
}
.message-block .logo_image{
  width: 180px;
}
.message-block .big-text{
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 25px;
}
.message-block .small-text{
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 60px;
}
.message-block .smallest-text{
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
}
.message-block .block-btn a , .message-block .block-btn button{
  width: auto;
  padding: 10px 30px;
  font-size: 13px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background: linear-gradient(to bottom, #f1435e, #ee2738);
  font-weight: 600;
  border-radius: 6px;
  color: #ffffff;
  text-transform: unset;
  font-size: 16px;
}
@media screen and (max-width:991px){
  .message-block .logo_image{
    max-width: 120px;
    min-width: 120px;
  }
  .message-block .big-text{
    font-size: 22px;
  }
  .message-block .small-text{
    font-size: 18px;
  }
}
.notif-div{
  display: inline-block;
  min-width: 200px;
}
.notif-div.mar-left {
  padding-left: 102px;
}
.custom_menu_item li:focus{
background-color: #ffffff;
}
.covid-19-text{
  text-align: center;
  color: #ee2738;
  margin: 0;
  margin-top: -25px;
  font-size: 14px;
  font-weight: 500;
}
.covid-text-right{
  width: 200px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px 15px;
  position: absolute;
  right: 10px;
  line-height: 1.4;
}
.covid-text-right h4  {
  margin: 0;
  color: #ee2738;
  font-weight: 900;
  font-size: 14px;
}
.covid-text-right p{
  font-weight: 600;
  margin: 0;
  color: #ee2738;
  font-size: 11px;
}
.left_panel-pos{
  position: relative;
}
@media screen and (max-width:1024px) and (min-width:992px){
  .covid-text-right{
    width: 170px;
    padding: 15px 10px;
  }
}
@media screen and (max-width:991px){
  .covid-text-right{
   position: relative;
   padding: 10px 10px;
   margin: 5px auto;
  }
}
@media screen and (max-width:767px){
  .action-center.disp-none-sm{
    display: none;
  }
  .covid-19-text{
    margin-top: -6px;
  }
  .notif-div{
    min-width: 100%;
  }
  .browse_venue_page .sign-up1{
    width: 100% !important;
  }
  .message-block{
    height: 100%;
    min-height: 100vh;
    padding: 60px 15px;
    padding-top: 90px;
  }
  .message-block .logo_image{
    max-width: 100px;
    min-width: 100px;
  }
  .message-block .big-text{
    font-size: 20px;
  }
  .message-block .small-text{
    font-size: 16px;
  }
  .error-messages-snackbar div div{
      display: flex;
      justify-content: space-between;
  }
  .error-messages-snackbar div div div{
    display: inline-flex;
    padding-left: 0px;
  }
  .notif-div.mar-left {
    padding-left: 0px;
  }
}
.jss290-btn{
  transform: none !important;
}

.dont_p_text {
  padding-left: 12px;
}

@media screen and ( min-width:1600px) {
  .signup.company-info-form, .signup.signup-personal, .signup ,.login,.profile_confirmation{
    height: 100vh !important;
  }
}
