$black: #202020;
$water_blue: #1397e1;
$dark_grey: #36434b;
$off_white: #f7f7f7;
$white: #ffffff;
$primary: #ee2738;
$light_grey: #747474;
$off_black: #1a181e;
body{
    overflow-x: hidden;
}
.home_page {
    overflow-x: hidden;
}
.text_over_image { 
    position: absolute; 
    left: 0px; 
    width: 100%; 
    top: 149px;
    @media screen and (max-width:800px){
        display: none !important;
    }
}
.mobile_view{
    @media screen and (min-width:800px){
        display: none !important;
    }
    @media screen and (min-width:768px) and (max-width:1024px){
        padding-left: 50px;
        padding-right: 50px;
    }
    @media screen and (max-width: 500px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.banner .carousel{
    border-radius: 0 0 100% 100% / 112px;
    overflow: hidden;
    margin-left: -10%;
    width: calc(100% + 20%) !important;
}
.banner_heading{
    @media screen and (min-width: 800px) {
        font-size: 48px;
        font-weight: 200;
    }
    @media screen and (min-width:800px){
        text-align: left;
        color: $white;
    }
    @media screen and (max-width:800px){
        text-align: center;
        color: #000000;
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
        font-weight: 200;
        padding-top: 20px;
    }
}
    
.banner_sub_heading{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    padding-top: 16px;
    @media screen and (min-width:800px){
        text-align: left;
        color: $white;
        padding-bottom: 8px;
    }
    @media screen and (max-width:800px){
        padding-top: 3px;
        text-align: center;
        color: #000000
    }
}
.banner_description{
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    @media screen and (min-width:800px){
        text-align: left;
        width: 334px;
        color: $white;
    }
    @media screen and (max-width:800px){
        text-align: center;
        color: #000000;
    }
}
.download_link{
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    padding-top: 7px;
    padding-bottom: 10px;
    @media screen and (min-width:800px){
        text-align: left;
    }
    @media screen and (max-width:800px){
        text-align: center;
    }
    .link{
        &:hover{
            text-decoration: none;
        }
        @media screen and (min-width:800px){
            color: $white;
        }
    }
}
.banner_content{
    @media screen and (min-width:800px){
        padding-left: 67px;
    }
}
.banner_button{
    @media screen and (min-width:800px){
        text-align: left;
    }
    @media screen and (max-width:800px){
        text-align: center;
    }
}

.previousButton{
    position: absolute !important; 
    top: 250px; 
    left: 26px;
    margin-left: -21px !important;
    @media screen and (max-width:800px){
        display: none !important;
    }
    :hover{
        background-color: transparent !important;
     }
}

.nextButton{
    position: absolute !important; 
    top: 250px;
    right: 30px;
    @media screen and (max-width:800px){
        display: none !important;
    }
    :hover{
        background-color: transparent !important;} 
}
.featured_venue_heading{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    padding: 0px;
    @media screen and (max-width:767px){
        padding-bottom: 15px;
        padding-top: 12px;  
    }
}
.featured_venue_carousel{
    .venue
    { .venue_image
        {
            height: 200px !important;
            object-fit: cover;
            object-position: top center;
        }
    }

    .alice-carousel__stage-item .venue_content{
        padding-left: 5px;
    }

    .alice-carousel__stage-item{
        // width: 320px !important;
        @media screen and (max-width:767px){
            margin-left: auto;
            margin-right: auto;            
        }
    }
}
.featured_venue_carousel .prev-arrow, .alice-carousel__prev-btn-wrapper p{
    // background-color: #f7f7f7;
    // margin-top:-7px !important;
    // margin-bottom: -10px !important;
    // padding-top: 16px;
    // padding-bottom: 25px;
}
// .icon_over_image{
//     position: absolute;
//     // left: -30px;
//     width: 100%;
//     top: 2px;
//     color: $white;
//     @media screen and (max-width:500px) {
//         left: -35px;
//     }
//     @media screen and (min-width:501px) and (max-width:768px){
//         left: -5px;  
//     }
//     @media screen and (min-width: 768px) {
//         left: -34px;
//     }
// }
// .active_icon_over_image{
//     color: red;
//     position: absolute;
//     width: 100%;
//     top: 2px;
//     @media screen and (max-width:500px) {
//         left: -35px;
//     }
//     @media screen and (min-width:501px) and (max-width:768px){
//         left: -5px;  
//     }
//     @media screen and (min-width: 768px) {
//         left: -34px;
//     }
// }

// find feature venue css
.find_venue_heading{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    padding: 20px 0px;
}
.find_venues{
    padding-bottom: 80px;
    position: relative;
    background: $off_white;
    &:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 100%;
    position: absolute;
    background: $white;
    border-radius: 0 0 100% 100% / 125px;
    }
    > div{
        z-index:1
    }
}
.event_planner{
    padding-top: 32px;
    background-color: $off_white;
    padding: 0px 15px;
}
.get-qualified{
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $black;
    padding-top: 32px;
}
.event-planner{
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $black;
    padding-top: 2px;
    padding-bottom: 17px;
}
.theresa_image{
    margin-top: 52px !important;
    @media screen and (min-width:1024px){
        margin-right: -73px !important;
    }
    @media screen and (min-width:500px) and (max-width:1024px){
        margin-right: -52px !important;
    }
    
}
.jennie_image{
    margin-top: 35px !important;
    @media screen and (min-width:1024px){
        margin-left: -102px !important;
    }
    @media screen and (min-width:700px) and (max-width:1024px){
        margin-left: -82px !important;
    }
}

.event_planner_image{
    @media screen and (max-width:360px){
        width: 270px; 
    }
    @media screen and (min-width:375) and (max-width:450px){
        width: 350px; 
    }
    @media screen and (min-width:700px) and (max-width:1024px){
        width: 271px; 
    }
}
.event_planner_heading{
    @media screen and (max-width:800px){
       text-align: center; 
    }
    @media screen and (min-width:800px){
        padding-left: 75px;
    }
}
.event_planner_name{
    font-size: 16px;
    line-height: 1.5;
    color: $black;
}
.verified_image{
    padding-left: 5px;
}
.be_ready{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
}
.device_image{
    padding-top: 22px;
}
.device_image_dimension{
    @media screen and (max-width:500px){
        width: 310px;
        height: 208px;
    }    
}
.how-it-works-list{
    .event_planners_section{
        // background-color: #f7f7f7;
    }
}

.event_planners{
    font-size: 16px;
    font-weight: 600;
    color: #36434b;
    @media screen and (min-width:1024px){
        padding: 0px 63px;
    }
    @media screen and (max-width:700px){
        text-align: center;
    }
    @media screen and (min-width:768px) and (max-width:1024px) {
        padding: 0px 12px;
    }
}
.size_style{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: $dark_grey;
    text-transform: uppercase;
    @media screen and (min-width:1024px){
        padding: 5px 50px 5px 63px;
    }
    @media screen and (max-width:700px){
        text-align: center;
        padding-top: 10px;
        font-size: 20px;

    }
    @media screen and (min-width:768px) and (max-width:1024px) {
        padding: 0px 12px;
    }
}
.event_planner_content{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #36434b;
    @media screen and (min-width:1024px){
        padding: 0px 63px;
    }
    @media screen and (min-width:768px) and (max-width:1024px) {
        padding: 0px 28px 0px 12px;
    }
}
.venue_managers{
    font-size: 16px;
    font-weight: bold;
    color: $primary;
    @media screen and (max-width:700px){
        text-align: center;
    }
    @media screen and (min-width:1024px){
        padding: 0px 76px;    
    }
    @media screen and (min-width:768px) and (max-width:1024px) {
        padding: 0px 12px;
    }
}
.sync_your{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: $dark_grey;
    text-transform: uppercase;
    @media screen and (min-width:1024px){
        padding: 5px 0px 5px 76px;  
    }
    @media screen and (max-width:700px){
        text-align: center;  
        padding-top: 10px;
        font-size: 20px;

    }
    @media screen and (min-width:768px) and (max-width:1024px) {
        padding: 0px 0px 0px 12px;
    }
}
.venue_manager_content{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #36434b;
     @media screen and (min-width:1024px){
        padding: 0px 76px;
    }
    @media screen and (min-width:768px) and (max-width:1024px) {
        padding: 0px 41px 0px 12px;
    }
}
.how_it_work_screen{
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    @media screen and (max-width: 768px){
           padding-bottom: 0px;    
        }
    }
.how_it_works{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: $black;
    @media screen and (max-width: 767px){
        padding-top: 50px;
    }
    @media screen and (max-width: 500px){
        padding: 50px 0px 10px 0px
    }
    @media screen and (min-width: 768px){
        padding: 28px 0px 22px 0px;
    }
}
.event_planners_section{
    @media screen and (max-width:768px){
        padding: 10px 0px 20px 0px;
        text-align: center;

    }
}
.venue_managers_section{
    @media screen and (max-width:768px){
        padding: 0px 0px 0px 0px;
    }
}
.image_link{
    &:hover{
        text-decoration: none !important;
        cursor: pointer;
    }
}
.marginTopD{
    margin-top: 20px;
    @media screen and (max-width:768px){
        margin-top: 10px;
    }
}
// footer
.footer{
    background-color: $off_black;
    @media screen and (min-width: 1024px) {
        padding: 52px 200px 20px 200px;
    }
    @media screen and (min-width:320px) and (max-width: 1024px) {
        padding: 52px 30px 20px 30px;
    }
    @media screen and (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center; 
    }
    .copyright{
        font-size: 12px;
        padding-top: 83px;
        color: $light_grey;
    }
    .logo{
        // height: 74px;
        // width: auto;
        padding-bottom: 38px;
        @media screen and (max-width: 768px) {
            padding-bottom: 20px;
        }
    }
    .footer_links{
        font-size: 13px;
        line-height: 1.54;
        color: $white;
        &:hover{
            text-decoration: none;
            cursor: pointer;
        }
    }
    .button_section{
        @media screen and (min-width: 768px) {
            justify-content: flex-end; 
        }
        @media screen and (max-width: 500px) {
            padding-top: 10px;
            justify-content: center;
        }
    }
    .links_section{
        @media screen and (min-width: 768px) {
            justify-content: left;
        }
        @media screen and (max-width: 500px) {
            justify-content: center;
            text-align: center;
        }
    }
    .footer{
        @media screen and (min-width:1025px) and (max-width: 1280px) {
            padding: 52px 50px 20px 50px !important;
        }
    }
}
a{
    text-decoration: none;
}
button[disabled]{
    opacity: 0.5;
}
.banner{
    position: relative;
    .nextButton {
        position: absolute !important;
        top: 50%;
        right: 40px;
        &:hover{
            background-color: transparent;
          }
    }
    .nextButton {
        position: absolute !important;
        top: 50%;
        right: auto;
    }
    .previousButton{
        position: absolute !important;
        top: 50%;
        left: auto;
    }
}
/* container */
.fh-container {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }
  .color-trans{
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .pos-0{
      left: 0 !important;
      position: absolute !important;
  }
  @media(max-width:1199px){
    .fh-container {
      max-width: 1170px;
    }
  }
