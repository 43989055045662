/***
* == Gallery Style
***/
$next : '../images/arrow-right.svg';
$prev : '../images/arrow-left.svg';
$next-black : '../images/arrow-right-black.svg';
$prev-black : '../images/arrow-left-black.svg';

.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after{
  content: none;
}
.venue_page .seatindAndCapacityGrid .alice-carousel__wrapper li{
  margin: 0px 0px 0px 0px;
  padding: 20px;
}
.seatindAndCapacityGrid{
.alice-carousel__wrapper{
    margin-left: 24px;
    margin-right: 24px;
    width: auto !important;
}

.alice-carousel__stage-item div h6{
    min-height: 30px;
}

.alice-carousel__stage-item div:nth-child(2){
    // text-align: center;
    margin: auto !important;
}

.alice-carousel{
  margin-top: 45px;
}

.alice-carousel ul li img {
// width:380px !important;
height: 280px !important;
}

  .single-images .alice-carousel__stage-item.__active{
      width: 100% !important;
  }
  .alice-carousel__wrapper{
    background-color: #f7f7ff;
    padding: 5px 0px 5px;
    margin: 0px;
    @media screen and (max-width:767px) {
      padding: 0px;
    }
  }
}

.seatindAndCapacityGrid .alice-carousel__stage-item{
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  .venue_image{
    width:100% !important;
  }
  .venue_content{
    text-align: left;
  }
}

.seatindAndCapacityGrid .prev-arrow , .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p{
  width: 4%;
  height: 215px;
  border-radius: 6px 0 0 6px;
  border: solid 1px #f0f0f0;
  background-color: #62717a;
  float: left;
  background-position: center center !important;
  left: 0 !important;
  background-image: url($prev) !important;
  background-repeat: no-repeat !important;
  background-size: 30% !important;
  position: absolute;
  z-index: 2;
  top: 0px;
  margin-top: -2px;
  left: 0 !important;
  @media screen and (max-width:767px) {
    height:210px;
  }

  svg {
    opacity:0;
  }
}



.seatindAndCapacityGrid .prev-arrow:hover , .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p:hover {
  background-image: url($prev-black) !important;
}

.seatindAndCapacityGrid .next-arrow , .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p {
  width: 4%;
  height: 215px;
  border-radius: 0 6px 6px 0;
  border: solid 1px #f0f0f0;
  background-color: #62717a;
  float: right;
  background-position: center center !important;
  background-image: url($next) !important;
  background-repeat: no-repeat !important;
  background-size: 30% !important;
  position: absolute;
  z-index: 2;
  top: 0px;
  margin-top: -2px;
  right: 0 !important;
  @media screen and (max-width:767px) {
    height:210px;
  }

  svg {
    opacity:0;
  }
}

.seatindAndCapacityGrid .next-arrow:hover , .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p:hover{
  background-image: url($next-black) !important;
}
.venue_page .seatindAndCapacityGrid  .alice-carousel__wrapper li div:first-child:hover{
      background-color: #fff !important;
      transition: 200ms ease-in-out;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
}

 .alice-red .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p ,  .alice-red  .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p{
      background-color: #ee2738 !important;
}

@media(min-width:1200px){
   .venue_page .seatindAndCapacityGrid .alice-carousel__wrapper li{
    padding: 20px 0px 20px 0px;
    margin: 0px 6px;
  }
}
@media(max-width:1199px){
  .prev-arrow, .alice-carousel__prev-btn-wrapper p , .next-arrow , .alice-carousel__next-btn-wrapper p{
    width: 8%;
  }
  .venue_page .seatindAndCapacityGrid .alice-carousel__wrapper li{
    padding: 8px;
  }
}
@media(max-width:767px){
  .prev-arrow, .alice-carousel__prev-btn-wrapper p , .next-arrow , .alice-carousel__next-btn-wrapper p{
    width: 12% !important;
  }
  .alice-carousel__prev-btn, .alice-carousel__next-btn{
    padding: 0px !important;
  }
  .venue_page .alice-carousel__wrapper li{
    // padding: 24px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}
.single-img-max{
  max-width: 200px !important;
  margin: 0px auto !important;
}
.single-images{
  .alice-carousel{
    .__active{
      width: 100% !important;
    }
  }
} 