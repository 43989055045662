$black: #202020;
$water_blue: #1397e1;
$dark_grey: #36434b;
$off_white: #f7f7f7;
$white: #ffffff;
$primary: #ee2738;
$light_grey: #747474;
$off_black: #1a181e;

.mobile-view {
    @media screen and (min-width:800px) {
        display: none !important;
    }
}
.desktop-view {
    display: block;
    @media screen and (max-width:767px) {
        display: none;
    }
}
.landing-banner {
    .text_over_image {
        position: absolute;
        left: 0;
        width: 100%;
        top: 149px;
        justify-content: center;
        @media screen and (max-width:800px) {
            display: none !important;
        }
    }
    .banner_heading {
        @media screen and (min-width: 800px) {
            font-size: 48px;
            font-weight: 600;
        }
        @media screen and (min-width:800px) {
            text-align: center;
            color: $white;
        }
        @media screen and (max-width:800px) {
            text-align: center;
            color: #000000;
        }
        @media screen and (max-width: 768px) {
            font-size: 24px;
            font-weight: 200;
            padding-top: 20px;
        }
    }

    .banner_sub_heading {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        padding-top: 24px;
        @media screen and (min-width:800px) {
            text-align: center;
            color: $white;
            padding-bottom: 8px;
        }
        @media screen and (max-width:800px) {
            padding-top: 12px;
            text-align: center;
            color: #000000;
        }
    }
    .banner_description {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.71;
        margin-top: 48px;
        @media screen and (min-width:800px) {
            text-align: center;
            color: $white;
            width: 100%;
        }
        @media screen and (max-width:800px) {
            text-align: center;
            color: #000000;
            width: 100%;
        }
    }
    .banner_content {
        @media screen and (min-width:800px) {
            padding-left: 67px;
        }
    }
    .banner_button {
        @media screen and (min-width:800px) {
            text-align: center;
        }
        @media screen and (max-width:800px) {
            text-align: center;
        }
    }
}

.sectionOne-box {
    .left-content {
        padding: 90px 52px 90px 190px;
        h4 {
            font-size: 52px;
            color: $water_blue;
            font-weight: 600;
            line-height: 66px;
        }

    }
    .right-content {
        padding: 75px 36px;
        h3 {
            color: $light_grey;
            font-size: 28px;
            font-weight: 600;
        }
        p {
            color: $light_grey;
            &.tp-16 {
                margin-top: 16px;
            }
            font-size: 16px;
            font-weight: 600;
        }
        .sectionOne-box-content {
            p {
                position: relative;
                padding-left: 30px;
            }
            p:before {
                font-size: 12px;
                content: "";
                position: absolute;
                top: 6px;
                left: 1px;
                text-transform: capitalize;
                background: #747474;
                color: #ffffff;
                width: 15px;
                border-radius: 50%;
                height: 15px;
                padding: 0 5px 1px 5px;
            }
        }
    }
    .full-content {
        background-color: $water_blue;
        color: $white;
        text-align: center;
        padding: 120px 0;
        width: 100%;
        h4 {
            font-size: 50px;
            font-weight: 600;
            &.sheading {
                font-size: 18px;
            }
        }
        p {
            font-size: 18px;
            font-weight: 600;
        }
        .text-white {
            color: $white;
        }

    }
    
}
@media screen and (max-width:767px) {
    .left-content h4,
    .sectionOne-box .full-content h4,
    .sectionTwo-box .full-content h4,
    .sectionOne-box .full-content h4,
    .sectionTwo-box .full-content h4,
    .featured_venue_slider .top-section .left-container h4,
    .sectionFive-box .heading h4,
    .sectionOne-box .right-content h3,
    .sectionTwo-box .right-content h3,
    .sectionThree-box .heading h4
     {
        font-size: 25px !important;
        line-height: 30px !important;
    }
    .new-home-banner .banner_heading,
    .sectionFour-box .full-content .button,
    .featured_venue_heading,
    .sectionFive-box .quotes .right-container h4,
    .sectionFour-box .full-content .large-text,
    .ready-checkout .left-container h4 ,
    .sectionFive-box .quotes .right-container p{
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .new-home-banner .banner_sub_heading,
    .sectionOne-box .right-content p,
    .sectionOne-box .full-content p,
    .sectionTwo-box .right-content p,
    .sectionTwo-box .full-content p,
    .sectionThree-box .mobile-view .right-container p,
    .sectionFour-box .full-content .small-text,
    .sectionFour-box .images-wrapper p,
    .featured_venue_slider .top-section .right-container p,
    .sectionFive-box .quotes .left-container .quotes-box,
    .event-planner,
    .ready-checkout .left-container p {
        font-size: 14px !important;
    }
    .BannerList-button-319 {
        font-size: 12px !important;
    }
}

.sectionTwo-box {
    .left-content {
        background: #f7f7f7;
        padding: 130px 52px 130px 170px;
        h4 {
            font-size: 52px;
            color: $water_blue;
            font-weight: 600;
            line-height: 66px;
        }

    }
    .right-content {
        background: #f7f7f7;
        padding: 100px 36px;
        h3 {
            color: $light_grey;
            font-size: 28px;
            font-weight: 600;
        }
        p {
            color: $light_grey;
            &.tp-16 {
                margin-top: 16px;
            }
            font-size: 16px;
            font-weight: 600;
            position: relative;
            padding-left: 30px;
        }
        span.material-icons {
            color: #69C02E;
            font-size: 18px;
            position: absolute;
            left: 0;
            top: 10%;
        }
    }
    .full-content {
        color: $light_grey;
        text-align: center;
        padding: 60px 0;
        width: 100%;
        h4 {
            font-size: 50px;
            font-weight: 600;
            &.blue-text {
                color: $water_blue;
            }
        }
        p {
            padding-top: 4px;
            font-size: 16px;
            font-weight: 600;
        }
    }

}
.blue-text {
    color: $water_blue;
}
.sectionThree-box {
    background: #f7f7f7;
    padding: 20px;
    .heading {
        text-align: center;
        color: #747474;
        padding: 16px 0;
        h4 {
            font-size: 32px;
            font-weight: 600;
            text-transform: initial;
        }
    }
    .mobile-view{
        .left-container {  
            .img-container{  
                padding: 10px 20px;
                box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
                background-color: #64646f33;
                border-radius: 20px;     
                margin-right: 8px;  
                @media screen and (max-width:767px) {
                    margin-right: 0px; 
                }
                img{
                    height: 420px;
                    @media screen and (max-width:767px) {
                        height: auto;
                    }
                }
            }
        
        }
        .right-container {   
            .box-shadow-white{               
                box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
                background-color: #ffffff;
                border-radius: 20px;
                padding: 10px 20px;            
                @media screen and (max-width:767px) {
                    margin-left: 0px; 
                }
            }
            h6 {
                padding-top: 12px;
                color: $water_blue;
                font-weight: 600;
                font-size: 20px;
            }
            p {
                padding-top: 8px;
                color: $light_grey;
                font-weight: 600;
            }
        }
    }
    
    .full-container{        
        position: relative;
        margin:16px 0 80px 0;        
        .img-container{
            position: relative;
            box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
            background-color: #64646f33;
            border-radius: 20px;   
            padding: 10px 20px;
            width: 75%;
            height: 430px;
            img{
                height: 400px;
            }
        }
        .box-shadow-white{
            position: absolute;
            box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
            background-color: #ffffff;
            border-radius: 20px;
            padding: 10px 20px;
            top:10%;
            left: 44%;
            width: 56%;
            height: 440px;
            h6 {
                padding-top: 12px;
                color: $water_blue;
                font-weight: 600;
                font-size: 20px;
            }
            p {
                padding-top: 8px;
                color: $light_grey;
                font-weight: 600;
            }
        }
    }
   
    @media screen and (max-width:991px) {
        // .heading {
        //    // padding: 10px 20px;
        // }
    }
}

.sectionFour-box {
    .full-content {
        background-image: linear-gradient(315deg, #37d5d6 0%, #1481d7 100%);
        color: $white;
        text-align: center;
        padding: 32px 0 52px;
        width: 100%;

        .large-text {
            font-size: 36px;
            font-weight: 500;
        }
        .small-text {
            padding-top: 24px;
            font-size: 20px;
            font-weight: 500;

        }
        .bold-text {
            font-weight: 700;
        }
        .button {
            background-color: #1497e1;
            color: #ffffff;
            font-size: 32px;
            font-weight: 600;
            border: 1px solid;
            margin-top: 24px;
            a{
                color: #ffffff;
            }
        }
    }
    .left-container {
        padding: 100px 50px;
    }
    .images-wrapper {
        justify-content: center;
        padding: 50px 0 25px;
        p {
            text-align: center;
            margin-top: 20px;
            font-weight: 600;
        }
        .image-block {
            text-align: center;
            margin-bottom: 40px;
            li.images {
                display: inline-block;
                border: 1px solid #1497e1;
                width: 130px;
                height: 125px;
                margin: 15px;
                border-radius: 15px;
                padding: 15px;
                p{
                    width: 130px;
                    height: 125px;                   
                    border-radius: 15px;  
                    display: flex;
                    position: absolute;
                    margin: -121px 0 0 -16px;
                    background-color: #4d4d4d;
                    opacity: 0.9;
                    color: #fff;
                    justify-content:center;
                    align-items: center;
                    cursor: default;
                }
               
            }
        }
    }
}
.bold {
    font-weight: 700 !important;
}
.find_venues {
    padding-bottom: 50px;
}
.sectionFive-box {
    .heading {
        text-align: center;
        padding: 85px 0;
        width: 100%;
        h4 {
            font-size: 50px;
            font-weight: 400;
            text-transform: none;
            color: #747474;
        }
        p {
            padding-top: 4px;
            color: $light_grey;
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
    .quotes {
        background: #f7f7f7;
        padding: 60px 45px 60px 0;
        .left-container {
            align-items: center;
            justify-content: center;
            padding: 16px 0 16px 52px;
            .quotes-box {
                padding: 50px 30px;
                background: #36434b;
                color: #ffffff;
                font-size: 22px;
                font-weight: 500;
                box-shadow: 36px 30px 0 #ccc;
            }

        }
        .right-container {
            text-align: right;
            padding-right: 24px;          
            padding-top: 24px; 
            h4 {
                padding-top: 12px;
                color: $light_grey;
                font-weight: 600;
                font-size: 36px;
                line-height: 40px;
            }
            p {
                padding-top: 8px;
                color: $light_grey;
                font-weight: 500;
                font-size: 22px;
            }

        }
    }
    .column-box {
        background: $white;
        padding: 60px 0 0;
        .column-section {
            padding: 0 32px;
            h6 {
                font-size: 16px;
                color: #1a181e;
                text-transform: uppercase;
                font-weight: 700;
            }
            p {
                font-size: 14px;
                color: $light_grey;
                font-weight: 600;
            }
            .block-height {
                min-height: 150px;
            }
        }
    }
    @media screen and (max-width:1024px) {
        .column-box {
            padding: 0 20px;
            .column-section {
                padding: 0;
            }
        }
        .quotes {
            .left-container {
                padding: 0;
            }
            .right-container {
                padding-right: 15px;
            }
        }
    }
}

.featured_venue_slider {
    .top-section {
        background: #f7f7f7;
        .left-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 36px;
            h4 {
                font-size: 48px;
                font-weight: 600;
                color: #747474;
                line-height: 55px;
            }
        }
        .right-container {
            padding: 48px;
            text-align: left;
            p {
                color: #747474;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
    .featured_venue_heading {
        padding: 20px 0 12px;
        background: #f7f7f7;
        color: #747474;
        font-weight: 600;
    }
    .featured_venue_carousel {
        background-color: #f7f7f7;
        padding: 16px 0 60px;
        .venueCardWithFav {
            @media screen and (max-width:767px) {
                margin: 0 15px;
            }

        }
    }
    .overlay_icon {
        right: 15px !important;
        @media screen and (max-width:767px) {
            right: 5px !important;
        }
    }
    .venue_content {
        padding-top: 5px !important;
        padding-bottom: 0 !important;
    }
    @media screen and (max-width:1024px) {
        .top-section {
            justify-content: center;
            .left-container,
            .right-container {
                padding: 20px !important;
            }
        }
    }
}

.ready-checkout {
    padding: 40px 0;
    background: #fff;
    .left-container {
        padding-left: 20%;
        h4 {

            padding-top: 4px;
            font-size: 24px;
            color: $water_blue;
            font-weight: 600;
        }
        p {
            color: $off_black;
            font-weight: 500;
        }
    }
    .right-container {
        padding: 24px 0;
        text-align: center;
        button {
            padding: 8px 40px;
            background: #ee2738;
            border: none;
            border-radius: 6px;
            box-sizing: border-box;
            color: #ffffff;
            height: 36px;
            font-size: 13px;
            text-align: center;
            font-weight: 800;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            cursor: pointer;
        }
        p {
            padding: 8px 0 0 4px;
            color: $light_grey;
            font-size: 11px;
            font-weight: 600;
        }

    }
    @media screen and (max-width: 768px) {
        justify-content: center;
        .right-container {
            p {
                padding: 10px 0;
            }
        }
        .left-container {
            padding-left: 10%;
        }
    }
}
@media screen and (max-width:1024px) {
    .full-content,
    .images-wrapper,
    .left-content,
    .right-content,
    .sectionFive-box .heading {
        padding: 20px !important;
    }
    .sectionFive-box {
        .column-box {
            .column-section {
                .block-height {
                    min-height: auto;
                    margin-top: 20px;
                }
            }
        }
    }
    .sectionFive-box {
        .quotes {
            .right-container {
                padding-top: 30px;
            }
        }
    }
}
@media screen and (max-width:767px) {
    .sectionOne-box-content {
        margin-bottom: 30px;
    }
    .sectionTwo-box {
        .right-content {
            padding: 20px 20px 60px !important;
        }
        .left-content h4 {
            font-size: 40px;
            line-height: 46px;
        }
    }
    .sectionFour-box {
        .full-content {
            .button {
                font-size: 25px;
                margin-bottom: 20px;
            }
        }
    }
    .sectionThree-box {
        .right-container {
            margin: 20px 0 28px;
        }
    }
}
@media screen and (max-width:680px) {
    .event_planner {
        .danny_image {
            margin-top: 35px;
        }
        .theresa_image {
            margin-top: 0 !important;
        }
    }
}

.banner-image-wrapper{
    background-image: url("../images/Banner_imagesList/Header_deYoung.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 793px;
    border-radius: 0 0 55% 46%/30px;
    position: relative;
    margin-top: -196px
}
.banner-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 0 0 55% 46%/30px;
}
.padding-top{
    padding-top: 350px;
    text-align: center;
}
.banner-image-wrapper.landing-banner .banner_heading {
    text-align: center;
    color: #ffffff;
    display: inline-block;
    max-width: 75%;
}
.new-home-banner{
    .mobile-view{
        display: none;
    }
}
.text-space{
    display: inline-block;
    padding: 0 5px;
}
@media screen and (max-width:768px) {
    .new-home-banner{
        .desktop-view{
            display: none;
        }
        .mobile-view{
            display: block;
            padding-top: 10px;
        }
        .banner_heading {
            font-size: 24px;
            font-weight: 200;
            padding-top: 20px;
        }
        .banner_sub_heading {
            padding-top: 12px;
            text-align: center;
            color: #000;
            font-weight: 500;
        }
        .banner_description {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.71;
            margin-top: 48px;
        }
        .banner-image-wrapper.landing-banner {
            max-height: 380px;
            margin-top: 0;
        }
        .banner-overlay {
            background-color: rgba(0, 0, 0, 0);
        }
        .text-space{
            display: inline-block;
            padding: 0 3px;
        }
        // .banner-image-wrapper.landing-banner {
        //     background-image: none;
        //     height: 0 !important;
        // }
        .mobile-view-img {
            border-radius: 0 0 55% 46%/30px;
        }
    }
}
@media screen and (max-width:767px) {
    .banner-image-wrapper {
            background-image: none;
            height: 60px !important;
        }    

}