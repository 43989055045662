$dark_grey: #36434b;
.venueTypeCard{
    @media screen and (max-width:767px){
       margin: auto 15px;
       width: 100%;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
    .find_feature_venue_image{
        &:hover {
            box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7);
        }
        @media screen and (min-width:1150px) {
            width: 321.3px;
            max-height: 200px;
            object-fit: cover;
            object-position: bottom;
        }
        @media screen and (max-width:1151px){
            width: 265.3px;
            max-height: 160.8px;
            object-fit: cover;
            object-position: bottom;
        }
        @media screen and (max-width:991px){
            width: 100%;
            max-height: 200px;
        }
    }
    .find_venue_event{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        color: $dark_grey;
        padding-top: 3px;
    }
    .venues{
        padding: 10px 20px !important;
    }
}
.venues{
    .venue_card {
        width: 306px;
    }
}
