input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: #cccccc;
    border-radius: 25px;
  }
  input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #cccccc ;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #cccccc;
  }
  // #myRange{ 
  //     background: linear-gradient(to right, #f50057 0%, #f50057 100%, #fff 0%, #fff 0%);
  //     border: solid 1px #f50057;
  //     border-radius: 8px;
  //     width: 100%;
  //     outline: none;
  //     transition: background 450ms ease-in;
  //     -webkit-appearance: none;
  //   }
  // .rangeWrap {
  //   position: relative;
  //   width: 60%;
  // }
  // .rangeValue {
  //   position: absolute;
  //   top: -50%;
  // }
  // .rangeValue span {
  //   width: 30px;
  //   height: 24px;
  //   line-height: 24px;
  //   text-align: center;
  //   background: #cccccc;
  //   color: #fff;
  //   font-size: 12px;
  //   display: block;
  //   position: absolute;
  //   left: 50%;
  //   transform: translate(-50%, 0);
  //   border-radius: 6px;
  // }
  // .rangeValue span:before {
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   border-top: 10px solid #cccccc;
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent;
  //   top: 100%;
  //   left: 50%;
  //   margin-left: -5px;
  //   margin-top: -1px;
  // }
  
.float-left{
  float: left;
}

.float-right{
  float: right;
}
.float-clear{
  clear: both;
}

.slider-value{
  span {
    display: inline-block;
    width: 9.9%;
  }
  span.ten-block.w-0 {
    width: 0;
  }
  .active{
    color: #1E88E5;
    font-weight: 600;
  }
}

.slider-value > *:last-child {
  width: 0 !important;
}
@media screen and (max-width:675px){
  .slider-value{
    span {
      display: inline-block;
      width: 9.7%;
    }
  }
}