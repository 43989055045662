.blog-wrpper {
    width: 265px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
    p {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
    }
    a {
        color: #000000;
        font-size: 20px;
        font-weight: 600;
    }
    img{
      height: 400px;
    }
}
.image-block{
    margin: 50px 0;
}

.top{
    margin-top: 50px;
}
.blog-detail-page{
    max-width: 700px;
    margin: 0 auto;
    padding-top: 200px;
    .main-heading{
        h1 {
            font-size:40px;
            text-align: center;
            font-weight: 600;
            color: #000000;
        }
    }
    p {
        font-size: 12pt;
        padding: 10px 0;
    }
    
    a:hover {
        color: #1497e1;
    }
    .section-heading{
        h2 {
            font-size: calc((2.8 - 1) * 1.2vw + 1rem);
            color: #000000;
            font-weight: 500;
            span {
                color: #1497e1;
                font-weight: 500;
            }
        }
    }
    .block-details{
        h4 {
            font-size: calc((1.2 - 1) * 1.2vw + 1rem);
            color: #000000;
            font-weight: 700;
            padding: 20px 0;
        }
        ul {
            padding-left: 50px;
            font-size: calc((1.2 - 1) * 1.2vw + 1rem);
            li {
                list-style: initial;
                padding: 10px 0;
                margin-bottom: 0 !important;
            }
        }
    }
    .image-space {
        margin: 20px 0;
    }
    .block-details.light-blue p {
        margin-top: 50px;
        color: #1497e1;
        font-size: calc((1.6 - 1) * 1.2vw + 1rem);
        line-height: calc(1.4 * (1 + (1 - 1.6)/25));
        font-weight: 600;
        text-align: center;
    }
    
    .btn{
        background-color: transparent;
        color: #d80e2a;
        padding: 22px 20px;
        border: 2px solid #d80e2a;
        margin-top: 20px;
        font-size: 18px;
        border-radius: 6px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        text-align: center;
    }
    .btn:hover{
        background-color: #d80e2a;
        color:#ffffff;
    }
    .text-center{
        text-align: center;
    }
    
}

.header-title-logo{
    a {
        span {
            display: inline-block;
            vertical-align: middle;
            height: 135px;
            margin-left: 20px;
            color: #000000;
            font-weight: 700;
            font-size: 45px;
        }
    }
    img {
        width: 80px;
    }
}
.header-title-logo {
    display: inline-block;
    float: left;
}

.header-nav-wrapper {
    float: right;
    margin-top: 30px;
}

.header-title-nav-wrapper {
    clear: both;
}
.category-btn {
    background: transparent;
    box-shadow: none;
    color: #000000;
    font-size: 20px;
    border: 0;
}
.VenueHeader-signupDiv-98 {
    width: 100%;
    display: none;
}

.mobile-view-nav {
    height: 160px;
    text-align: right;
    .category-btn-hover {
        display: none;
        a {
            display: block;
            text-align: right;
            color: #000000;
            font-size: 18px;
            font-weight: 300 !important;
            padding-top: 10px;
        }
    }
}

.mobile-view-nav:hover .category-btn-hover,
.mobile-view-nav:active .category-btn-hover,
.mobile-view-nav:focus .category-btn-hover {
    display: block;
}

.next-heading button {
    box-shadow: none;
    background: transparent;
    font-size: calc((2.2 - 1) * 1.2vw + 1rem);
    font-weight: 600;
    color: #000000;
    line-height: 40px;
    text-align: start;
}
.next-heading{
    .btn-block1{
        button:before{
            content: "<";
            font-size: 40px;
            font-weight: 200;
            margin-right: 30px;
        }
    }
    .btn-block2{
        button::after{
            content: ">";
            font-size: 60px;
            font-weight: 200;
            margin-left: 30px;
        }
    }
} 
.next-heading {
    padding: 100px 0 50px 0;
}
.next-heading button:hover {
    background: transparent;
    color: #272525c7;
}
.btn-block2 button{
    text-align: right !important;
}
.btn-block1 button{
    text-align: left;
}

.main-wrapper.blog-detail-page.meeting-venue {
    padding: 0;
}

.block-details h4 {
    padding: 5px !important;
}

.capacity-block {
    right: 0 !important;
    width: 300px !important;
}
.contact.search-btn{
    width: 200px;
    margin: 0 auto;
    padding-bottom: 120px;
}
.search-btn button {
    flex-direction: row;
    background-color: #d80e2a;
    color: #ffffff;
    padding: 10px 20px;
    border: 1px solid #d80e2a;
    margin-top: 20px;
    width: 100%;
    font-size: 15px;
    border-radius: 4px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
}
.block-details.last-block h1 {
    font-weight:bold;
    font-size: 2em;
}
.block-details.last-block h2 {
    font-weight:bold;
    font-size: 1.5em;
}
.block-details.last-block h3 {
    font-weight:bold;
    font-size: 1.17em;
}
.block-details.last-block h4 {
    font-weight:bold;
    font-size: 1.33em;
}
.block-details.last-block h5 {
    font-weight:bold;
    font-size: .83em;
}
.block-details.last-block h6 {
    font-weight:bold;
    font-size: .67em;
}
.block-details.last-block strong {
    font-weight:bold;
    color:unset;
    
}


.btn-inline-block {
    display: inline-block;
    padding: 0 15px;
    vertical-align: middle;
}

.block-details.last-block p {
    font-family: 'Raleway', sans-serif !important;
}
.loading_blog{
    padding-bottom:100px;
    text-align: center !important;
}
@media only screen and (max-width: 767px) {
    .container.top{
        margin:50px 25px 0;
    }
}
.strong-text {
    color: #363636 !important;
}
.strong-text:hover {
    color: #1397e1 !important;
}
.dark-blue{
    color: #34495e;
}
.light-blue{
    font-size: 18px !important;
    color: #1397e1 !important;
    font-weight: 700;
}
.light-blue-normal{
    font-size: 18px !important;
    color: #1397e1;
}
.red-color,
.red-color:hover{
    color: #ee2738 !important;
}

.padding-bottom{
    padding-bottom: 150px;
}
.next-heading.bottom-btn-link {
    align-items: flex-start;
}
.next-heading button {
    text-align: left;
    border: 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.f-18{
    font-size: 18pt;
}
.last-blue-heading {
    font-size: calc(.72vw + 1rem) !important;
    font-weight: 600;
}
.f-16{
    font-size: 16px;
}
.ft-18{
    font-size: 18pt;
}
.light-black{
    color: #36434b;
}