.matter{ font-family: Raleway !important; }
.venue-attraction-edit {
//   overflow-x: hidden;
  // padding-left: 90px;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .custom_input{
    display: block;
    width: 100%;
    margin-bottom: 5px;
    input {
      min-width: 100%;
      margin-bottom: 5px !important;
    }
    ::placeholder{
      opacity: 0.5;
        }
  }

  .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 0;
  }

  .black-heading {
    color: #36434b !important;
    margin: 0px 0px 13px;
  }
  .bordered{
    width: 100% !important;
  }
}
