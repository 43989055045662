.calendar{
    // background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
      .button-calendar{
            display: block;
            text-align: center;
            border-bottom: 1px solid #f0f0f0;
            button{
              background-color: transparent;
              color: #ee2738;
              box-shadow: none;
              font-weight: 700;
              font-size: 18px;        
            }
            .today{
              color: #1397e1;
              min-width: 140px;
              font-family: Raleway;
              font-size: 16px;
              font-weight: bold;

            }
                      }
    .flexContainer{
        background-color: #f7f7f7;
        .weekname{
            padding: 8px 0px;
            text-transform: uppercase;
        }
        .flexColumn{
            font-family: Raleway;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #202020;
        }
        
        .day{
            background-color: #f7f7f7;
            color: #36434b;
            height: 50px;
            font-weight: normal;
            font-size: 14px;
           
            .inner-grid{
                padding-left: 0px;
                position: relative;
                padding-right: 0px;
                .date{
                    text-align: left;
                    left: 5px;
                    display: block;
                    top: 0;
                    width: 100%;
                    position: absolute;
                    z-index: 2;
                }
           
                .event{
                    position: absolute;
                    width: 100%;
                    height: 10px;
                    top: 0px;
                }
                .single-event{
                    height: 100%;
                }
                .blockOut{
                    background-color: grey !important;
                    height: 100%;
                
                }
                .makeAvailableClass{
                    background-color: #ffffff !important;
                    height: 100% !important;
              
                }
                .holdClassSecond{
                    top: 0px;
                }
                .holdClassSingle{
                    height: 100%;
                    background-color: #69c7ee !important
                }
                .blockClassSingle{
                    height: 100%;
                    background-color: grey !important
                }
        
    
            }
        }
        .inactive{
            background-color: #f7f7f7;
            color: #e1e1e1;
        }
        .inner-grid{
            padding: 17px 8px;
            box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
            border-radius: 0!important;
            .event-slot{
                min-height: auto;
            }
        }
    }
                    
  }
  .widget{
    .bookClasssingle{
        background-color: red !important;
        height:100% !important;
    }
    
      .h3{
        margin-top: 105px;
        display: block;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        @media screen and (max-width:992px){
            margin-top: 130px;
        }
      }
        .calendar
        { margin: 30px 180px 10px !important;
            @media screen and (min-width:1360px) {
                margin: 30px 400px 10px !important;  
            }
            @media screen and (min-width:1024px) {
                margin: 30px 250px 10px !important;  
            }
            @media screen and (max-width:767px){
                margin: 30px 75px 10px !important;  
            }

            .day{
                height: 80px !important;
                @media screen and (max-width:992px){
                    height: 48px !important
                }
              }
              .weekname{
                  padding: 20px 0px !important;
              }
              .headesearch_container{
                p,b{
                    word-wrap: break-word;
                }
              }
              .holdClassRight{
                @media screen and (min-width:1400px){
                    border-right: 150px solid transparent;
                }
                @media screen and (max-width:1399px){
                    border-right: 116px solid transparent;
                }
                @media screen and (max-width:1200px){
                    border-right: 80px solid transparent;
                }
                @media screen and (max-width:1024px){
                    border-right: 90px solid transparent;
                }
                @media screen and (max-width:991px){
                    border-right: 90px solid transparent;
                }
                @media screen and (max-width:860px){
                    border-right: 68px solid transparent;
                }
                @media screen and (max-width:610px){
                    border-right: 65px solid transparent;
                }
                @media screen and (max-width:575px){
                    border-right: 50px solid transparent;
                }
                @media screen and (max-width:460px){
                    border-right: 33px solid transparent;
                }
              }
              .topEventNav{
                  Button{
                    @media screen and (max-width:767px){
                       width: auto !important;
                    }
                  }
              }
        }
        .calendar_instruction{
            padding-left: 115px;
        }
      }
      .widget-place-hold{
        Button{
            @media screen and (max-width:767px){
               width: auto !important;
               min-width: 85px !important;
            }
        }
      }
      .widget-header{
        .topEventMenu , .topEventNav{
            margin-right: 10px !important;
            @media screen and (max-width:767px){
                margin-right: 4px !important;
             }
        }
      }
