/***
* == Header Style
***/
@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap');
$primary: #ee2738;
$red: #FF0000;
$white: #ffffff;
$dark-grey:#36434b;
$off-white: #f0f0f0;
$grey: #62717a;
$light-grey: #9aa7af;
$secondary: #f1435e;

// body {
//   font-family: 'Raleway', sans-serif;
//   overflow-x: hidden;
// }

// span,
// div,
// p,
// li,
// a,
// input {
//   font-family: 'Raleway', sans-serif;
// }

.image {
    width: 26px;
    height: 24px;
}

.uploadPhoto {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0px;
  background-color: $off-white;
  margin-top: 25px;
  position: relative;
  cursor: pointer;
  text-align: center;
  display: inline-block;

  .image_upload_file {
    display: none;
  }

  .icon {
    font-size: 90px;
    color: #e1e1e1;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .user-icon{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  &:hover {
    font-family: 'Material Icons';
    content: "Upload Photo \e2c3";
    background-color: rgba(98, 113, 122, 0.6);
    color: $white;
    cursor: pointer;
    .upload-image{
      display:flex;
    }

  }
  .upload-image{
    width: 100%;
    height: 100%;  
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(98, 113, 122, 0.6);
    display: none;
    span.material-icons{
      width: 28px;
      height: 28px;
      padding: 2px 0px;
    }
    p{
      color: inherit;
      text-align: center;
      line-height: normal;
      font-size: 13px;
      font-family: 'Raleway';
    }
  }
}

.hintText {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;
  margin-top: 19px;
  margin-bottom: 27px;
  width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.image_upload_form{
  margin-top: -100px;
}
.save_button{
  display: block;
  text-align: center;
}
@media only screen 
  and (min-device-width: 320px)
  and (max-device-width: 991px)
   { 
     .hintText {
       width: auto;
     }
     .personal-info-form,
     .company-info,
     .type-info-form {
       width: auto !important;
     }
}
a.fill-div {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}
