$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;
.MuiDialog-paperWidthSm {
    max-width: 480px;
}
.dialog-container {
    padding: 24px;
    .logo {
        text-align: center;
        img{
            height: 92px;
        }
    }
    p {
        width: 100%;
    }
    .heading-1 {
        font-size: 14px;
        text-align: center;
        font-weight: 500;
    }
    .heading-2 {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    .bottom-heading {
        margin: 16px 0;
        font-size: 14px;
        text-align: center;
    }
    .bottom-link {
        margin: 4px 0 4px;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        a {
            color: $water-blue;
        }
    }

    .login-container {

        .select-box {
            padding-right: 4px;
            .phone-ext {
                height: 35px;
            }

        }
        .button-box {
            margin-top: 18px;
            .sign-btn {
                background-color: $water-blue;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .bottom-link {
            margin: 18px 0 8px; 
        }
        .bottom-border{
            padding: 0 18px 0 18px;
        }
        .input-box{
            margin-top:12px;
            label{
                font-size: 14px;
                font-weight: 600;
                padding-left: 4px;
            }
            .inputField{
                width: 100%;
            }
        }
        .password_field_div {
           padding-right: 4px;
        }
    }
    .otp-container{
        .otp-input-box {
            display: flex;
            align-items: center;
            justify-content: center;

            .otp-input {
                width: 40px !important;
                text-align: center;
                height: 40px;
                margin: 12px;
                border-radius: 4px;
                border: 1px solid $water-blue;
            }
        }
        .bottom-link {
            margin: 36px 0 8px; 
        }
    }
    .help-container{
        .heading-2 {
            margin-top: 12px;
        }
    }
}
