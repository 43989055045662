.matter{ font-family: Raleway !important; }
.venue-address-edit {
  overflow-x: hidden;
  // padding-left: 90px;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;

  .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 0;
  }

  .black-heading {
    color: #36434b !important;
    margin: 20px 0;
    font-weight: bold;
  }

  .textarea-small {
    // width: 288px;
    width: 100%;
    height: 90px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    padding: 10px;
    font-size: 15px;
    line-height: 1.07;
  }
  .bordered{
    width: 100% !important;
  }
}
