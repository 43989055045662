$water_blue:#1397e1;
$white: #ffffff;
$primary: #ee2738;


.DayPicker-Day--disabled{
    color:#DCE0E0 !important;
    background-image:none !important;
    background-color: white !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
    background-color: red !important;
    color:white !important;
}
.DayPicker {
    font-size: 14px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #36434b;
}
.DayPicker-Day {
    box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
    border-radius: 0 !important;
    padding: 17px 8px !important;
}
.DayPicker-Day--outside {
    box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
    background-color: #f7f7f7;
    border-radius: 0 !important;
}
.DayPicker-Weekday {
    font-size: 12px !important;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #202020 !important;
    text-transform: uppercase;
    background-color: #f7f7f7;
    padding: 3px !important;
}
.DayPicker-Caption {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1397e1;
    text-align: center !important;
    margin-bottom: 0px !important;
    padding: 0 0.5em 0.9em 0.5em !important;
    border-bottom: #f0f0f0 1px solid;
}
.DayPicker {
    background-color: #ffffff;
    border: 1px #f0f0f0 solid;
    border-radius: 6px;
}
.DayPicker-Month {
    margin: 1em 0em !important;
    margin-top: 1em;
    width: 100%;
}
.DayPicker {
    width: 100%;
}
.DayPicker-NavButton--prev {
     background-image: url(../images/prev.png) !important;
     left: 20% !important;
    @media screen and (min-width:500px) and (max-width: 1000px){
        margin-right: 16.5em !important;
    }
    @media screen and (max-width:500px){
        margin-right: 20px !important;
    }
}
.DayPicker-NavButton {
    width: 3.25em !important;
}
.DayPicker-NavButton--next {
     background-image: url(../images/next.png) !important;
     right: 20% !important;
    @media screen and (max-width:500px){
        margin-right: 0 !important;
    }
   
}
.event_calendar{
    width: 100%;
    // position: relative;
    .event_info_popup{
        width: 260px;
        border-radius: 6px;
        box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        border-width: 1px;
        border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
        border-image-slice: 1;
        border-radius: 6px;
        position: absolute;
        z-index: 2;
        @media screen and (max-width:767px) {
            margin-top: -150px;
        }
        .event_date{
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            background-color: $primary;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            padding: 9px 13px 7px 13px;
        }
        .event_name1{
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #36434b;
            background: $white;
            // padding: 0px 13px;
            padding: 23px 13px 12px 13px;
        }
        .event_name2{
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $water_blue;
            background: $white;
            // padding: 0px 13px;
        }
        .event_type_heading{
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #62717a;
            background: $white;
            padding: 0px 13px;
        }
        .event_type_name{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #36434b;
            word-break: break-word;
            background: $white;
            padding: 0px 13px 12px 13px;          
        }
        .calendar-close{
            margin:0px;
            padding-right: 5px;
            color: #ffffff;
            span{
                font-size: 18px;
                color: #ffffff !important;
            }
        }
        .time_heading{
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #62717a;
            background: $white;
            padding: 0px 13px;
        }
        .event_time{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            display: flex;
            color: #36434b;
            background: $white;
            padding: 4px 13px 11px 13px;
            :nth-child(1)
            div{
                    display: flex;
                    justify-content: space-between;
            }
            p{
                margin-left: 45px !important;
            }
            .custom_checkbox{
                .checkmark{
                    top: 3px;
                }
            }
        }
        .event_price_heading{
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #62717a;
            background: $white;
            padding: 0px 13px;
        
        }
        .event_price{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #36434b;
            background: $white;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            padding: 0px 13px 14px 13px;
        }
        .event_price_venue{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #36434b;
            background: $white;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            padding: 0px 13px 14px 13px;
        }
    }
}
