.divider{
  opacity: 0.1;
}
.avilableDescription{
h3{
  font-weight: bold;
  margin-bottom: 1rem;
}
}
.abt-us{
  margin-top: -20px;
}