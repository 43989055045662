.custom_menu_item{
    .event_name{
        margin-bottom: -19px;
    }
    .checkbox_grid{
        margin-top: -12px;
    }
    .material-icons{
        font-size: 50px;
        color:#EBEBEB;
    }
    .space_image{
        width:50px;
        height: 50px;
        object-fit: cover;
        border-radius:50%;
    }
    .hr_tag{
        margin: 5px;
    }
}

.notification-box{
    .mr-bottom {
        margin-top: 30px;
    }
    h5 {
        font-size: 12px !important;
    }
}