$white: #ffffff;
$off-white:#f0f0f0;

.select_field{
    padding:8px 12px !important;
    border-radius: 6px;
    border: solid 1px $off-white;
    background-color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-align: left;
    text-transform: uppercase;
    // background-image: linear-gradient(45deg, transparent 50%, #757575 50%), linear-gradient(135deg, #757575 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
    // background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    // background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    // background-repeat: no-repeat;
    .category_option{
      text-transform: none;
    }
    option {
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #62717a;
        border: 0;
        border: 0;
        padding: 8px !important;
        min-height: 50px !important;
        height: 30px !important;
        padding: 0px 12px;
      }

}
.customized-select {
    position: relative;
    background: #f7f7f7;
  
    &:before {
      content: 'arrow_drop_down';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      top: 3px;
      right: 5px;
      color: #62717a;
      z-index: 999;
    }
}
