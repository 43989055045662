.success_modal_container div:nth-child(2) div:first-child{
  max-width: 450px;
}
.success_modal_container .modalBox_content,
.success_modal_container .dialog-content{
    background-color: transparent !important;
    // padding: 0;
}
@media screen and (max-width:767px){
    .success_modal_container .modalBox_content,
    .success_modal_container .dialog-content{
        padding: 10px 0;
        
    }
}
@media screen and (max-width:540px){
    .success_modal_container div:nth-child(2) div:first-child .dialog-content{
        min-height: auto !important;
    }
    .success_modal_container div:nth-child(2) div:first-child,
    .success_modal_container .dialog-content{
        overflow: auto !important;
    }
}
.venue-publish-popup-box{
      width: 100%;
    //   overflow: scroll;
      height: auto;
      padding: 10px 15px;
      text-align: center;
      padding-top: 20px;
      display: block;
      .approval-btn{
          text-align: center;
          display: flex;
          justify-content: center;
          padding: 0px 0px 16px;
          
          button{
              width: 130px;
              height: 30px;
              border-radius: 6px;
              box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
              background-image: linear-gradient(to bottom, #f1435e, #ee2738);
              font-family: Raleway;
              font-size: 13px;
              font-weight: bold;
              text-align: center;
              color: #ffffff;
              margin: 0 5px;
              border: 0 !important;
              cursor: pointer;
              
          }
      }
      a,
      a:hover{
        //   color: black;
            color:#1397e1;
            font-size: 16px;
            text-decoration: underline;
      }
     
    //   @media screen and (max-width:767px){
    //       height: auto;
    //       padding-top: 35px;
    //   }
    //   @media screen and (max-width:500px){
    //       height: 375px;
    //   }  
    //   @media screen and (max-width:320px){
    //       height: 338px;
    //   } 

      .group-buttons{
          display: inline-block;
          padding-top: 15px;
          .banner_button{
              display: inline-block;
              margin: 10px 19px;
              width: auto;
              @media screen and (max-width:767px){
                  margin: 10px 10px;
              } 
              @media screen and (max-width:500px){
                  width: 100% !important;
                  margin: 0px;
              } 
          }
          .white-button-div{
              button{
                  background-color: transparent;
                  color: #ee2738;
                  background: transparent;
                  box-shadow: none;
                  :hover{
                      opacity: 0.8;
                      transition: 0.6ms;
                  }
              }
          }
          button{
              margin: 5px auto;
              width: auto !important;
              @media screen and (max-width:767px){
                  width: 166px !important;
                  padding: 6px 15px;
              }               
          }
      }
}
.view-listing-popup{
  // padding: 15px 0px 0px;
  .container-box{
      display: block;
      // width: 100%;
      width: 98%;
      height: auto;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      margin: 10px auto 15px !important;
      padding: 25px 10px 25px;
  }
  .header {
      width: 100%;
      height: auto;
      background-color: #ffffff;
      margin-top: 5px;
      font-weight: 500;
      padding: 15px;
      border-bottom-style: solid !important;
      border-bottom-width: 1px !important;
      border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
      border-image-slice: 1;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      @media screen and (max-width:767px){
          margin-top: -5px;        }
    }
    

    }
    .ready-to-publish{
        @media screen and (max-width:767px){
            height:205px;
        }
        @media screen and (max-width:500px){
          height:275px;
        }
        .dont_single{
                font-size: 18px;
                @media screen and (max-width:767px){
                    font-size: 16px;
                }
        }
        p{
            padding: 20px 15px;
            display: block;
            font-size: 16px;
        }
        .ready-button{
            text-align: center;
            display: flex;
            justify-content: center;
            button{
                width: 130px;
                height: 30px;
                border-radius: 6px;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
                background-image: linear-gradient(to bottom, #f1435e, #ee2738);
                font-family: Raleway;
                font-size: 13px;
                font-weight: bold;
                text-align: center;
                color: #ffffff;
                margin: 0 5px;
                border: 0 !important;
                cursor: pointer;
                :hover{
                    opacity: 0.8;
                }
            }
        }
        .click-here{
            padding-top: 0;
            a{
                display: inline-block;
                font-size: 18px;
            color: #1397e1;
            }
        }
    }
    .forgot-something-popup{
      .venue-publish-popup-box{
          display: block;
          width: 100%;
          padding:25px 20px 5px;
          @media screen and (max-width: 500px){
              height: auto;
          }
      }
      .what-we-love-block{
          margin-top: 0px;
           .what-we-love-listing{
               display: flex;
               justify-content: space-between;
               margin-left: 25px;
               .right-list{
                  text-align: right;
                   @media screen and (max-width: 500px){
                      padding-left: 30px;
                  }
                  li:before{
                          padding-right: 15px;
                      
                  }
               }
           }
      }
    }
    .submit-for-approval{
      @media screen and (max-width:767px){
          height:270px;
      }
      @media screen and (max-width:500px){
          height:400px;
      }
      .dont_single{
              padding: 10px 0px 15px;
              font-size: 18px;
              @media screen and (max-width:767px){
                  font-size: 16px;
              }
      }
      p{
          padding: 0px 0px;
          display: block;
          text-align: center;
          font-size: 16px;
      }
      .approval-btn{
          text-align: center;
          display: flex;
          justify-content: center;
          padding: 20px 0px 0px;
          
          button{
              width: 130px;
              height: 30px;
              border-radius: 6px;
              box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
              background-image: linear-gradient(to bottom, #f1435e, #ee2738);
              font-family: Raleway;
              font-size: 13px;
              font-weight: bold;
              text-align: center;
              color: #ffffff;
              margin: 0 5px;
              border: 0 !important;
              cursor: pointer;
              :hover{
                  opacity: 0.8;
              }
          }
      }
    }

    .MuiPaper-rounded-125 {
        border-radius: 2px;
    }