//start editVenueImage
$white: #f7f7f7;
$grey: #e1e1e1;
$red: #FF0000;
$black: #202020;
.edit_venue_image{
    padding: 0px 10px;
    li{
      list-style-type: none;
    }
    .image_name{
      text-align: center;
    }
    .error{
      text-align: center;
      color: #FF0000;
      font-size: 11px;
    }
    .name_and_progess_bar_container{
      background-color: #d8d8d8;
      padding: 55px 15px;
      width:100%;
      @media screen and (max-width:767px){
          text-align: center;
          display: block;
        }
    }
    .drag_icon_container{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 56px;
      cursor: pointer;
        @media screen and (max-width:767px){
          padding-left: 12px;
        }
    }
    .delete_icon_container{
      // padding-top: 50px;
          @media screen and (max-width:767px){
            // padding-top: 18px;
            text-align: center;
            width: 100%;
            min-width: 100%;
            justify-content: center;
            display: flex;
            max-width: 100%;
            align-items: center;
            position: relative;
        }
        button{
          background-color: transparent;
          border: none;
          color: #36434b;
          display: inline-block;
          @media screen and (max-width:767px){
            padding-left: 0px;
            min-width:42px;
            padding-top: 11px;
        }
        }

        .venue-gallery {
          width: auto;
          display: inline-block;
          margin-right: 10px;
          @media screen and (max-width:991px){
            margin-right: 15px;
            padding-top: 2px;
          }
      }

      a{

        @media screen and (max-width:767px){
        display: block;
      }
    }

        span{
          cursor: pointer;
          margin-left: 10px;
          @media screen and (max-width:991px){
              margin-left: 0px;
              padding-top: 2px;
          }
          
          @media screen and (max-width:767px) {
                display:block;
          }
        }
    }
    .image_details{
      border: solid 1px #f0f0f0;
      padding-bottom: 16px;
      padding-top: 11px;
      margin: 5px;
      width: 98%;
      align-items: center;
      .custom_input{
        padding:4px;
        input{
            text-align: left;
            border-radius: 6px;
            border: solid 1px $grey;
            background-color: $white;
            height: 34px;
            width: 192px;
            padding: 0px 15px;
            margin-top: 0px;
            font-size: 12px;
            line-height: 1.07;
            text-align: left;
            color: $black;
            &::placeholder{
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.07;
                letter-spacing: normal;
                text-align: left;
                color: #b0b0b0;
            }        
        }
      }

    }
    .v_image_container
    {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .venue_image{
      width: 140px;
      height: auto;   
      cursor: pointer;
         @media screen and (max-width:767px){
              width:100%;
         }
    }
  }
    .file_type_field{
      display: none;
    }
    .sortable-list{
      padding-left: 0px !important;
    }
    .draggable {     
      margin: 10px;
    }
   
    .dragged {
      opacity: 0.7;
    }

    .item {
      user-select: none;
      background-color: #ffffff;
    }


  
  //end editVenueImage
  