/***
* == ModalBox Style
***/
@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap');

.dialouge_heading{
  color: red;
  font-weight: 700;
}
.dialouge_text{
  margin: 1rem 0;
  text-align: center;
}
.success_modal_container {
  .dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
  }
  .action-center{
    justify-content: center;
  }
  .padding-left{
    padding-left: 70px;
    @media screen and (max-width:767px){
    padding-left: 0px;
    }
  }

  .padding-right{
    padding-right: 70px;
    @media screen and (max-width:767px){
      padding-right: 0px;
      }
  }
  // img {
  //   width: 100% !important;
  // }

  .boxMatter{
    padding:15px 12%;
    text-align:left;
  }
  button{
    padding: 6px 30px;
  }
}



.dont_single {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.95px;
  text-align: center !important;
  color: #1397e1;
}

.dont_p {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.75px;
  text-align: left !important;
  color: #62717a; 
  padding-bottom: 16px;
}

.dont_p-d-none-sm{
  @media screen and (max-width:767px){
    display: none !important;
  }
}
.dont_p-d-none-lg{
  @media screen and (min-width:768px){
    display: none;
  }
}

.noThank_btn {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  text-align: center;
  color: #ee2738;
  padding: 12px 40%; 
  cursor: pointer;
}



 @media only screen 
  and (min-device-width: 320px)
   and (max-device-width: 991px)
  {
    .noThank_btn {
      padding: 12px 27%;
    }

  }
  .grey-delete{
    display: initial;
    p{
      @media screen and (max-width:767px){
        position: absolute;
      width: 100%;
      left: 0;
      bottom: -13px;
      }
    }
  }

  .centerClass{
    text-align: center !important;
  }
  .sync-popup{
    .boxMatter{
    
    @media screen and (max-width:1599px) and (min-width:1025px){
    padding: 0px 17px; 
    }
    }
    }
    .centerWord{
    text-align: center;
    span{
    padding: 0px;
    }
    }
    
// .success_modal_container > div > div {
//     overflow-y: scroll !important;
//     overflow-x: hidden;
// }