.success_modal_container div:nth-child(2) div:first-child{
    max-width: 450px;
}
.view-listing-popup-box{
        width: 100%;
        overflow: scroll !important;
        height: 335px;
        @media screen and (max-width:767px){
          height: auto;     
    }
}
.view-listing-popup{
    // padding: 15px 0px 0px;
    .container-box{
        display: block;
        // width: 100%;
        width: 98%;
        height: auto;
        border: solid 1px #e1e1e1;
        background-color: #ffffff;
        margin: 10px auto 15px !important;
        padding: 25px 10px 25px;
    }
    .header {
        width: 100%;
        height: auto;
        background-color: #ffffff;
        margin-top: 5px;
        padding: 15px;
        border-bottom-style: solid !important;
        border-bottom-width: 1px !important;
        border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
        border-image-slice: 1;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        @media screen and (max-width:767px){
            margin-top: -5px;  
          height: 72px !important;      }
      }
      
      .checkBoxDiv {
          display: inline-block;
          width: 5%;
          float: none;
          margin-top: -11px;
          span{
              margin: 0px !important;
              padding: inherit;
          }
      }
      .annual{
        font-size: 14px;
        font-weight: 600;
        padding-top: 2px;
        padding-left: 6px;
      }
      .end-date{
        padding-left: 46px;
      }
      .blue-text {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #1397e1;
        margin: 0;
      }
      .detailsbox{
          display: inline-block;
          width: 95%;
          vertical-align: top;
          @media screen and (max-width:767px){
              width: 90%;
          }
          .large-text{
              display: inline-block;
              width: 70%;
              padding-left: 10px;
              @media screen and (max-width:767px){
                width: 70% !important;
                padding-left: 0px;
            }
              p{
                  float: left;
                  margin: 0px;
                  word-break: break-word;
                  @media screen and (max-width:767px){
                   padding-left: 10px;
                }
              }
          }
          .actionDiv{
              display: inline-block;
              width: 30%;
              text-align: right;
              vertical-align: top;
              @media screen and (max-width:767px){
                width: 30% !important;
            }
              p{
                display: inline-block;
                min-width: 60px;
                text-align: center;
                margin: 0;
                vertical-align: super;
                @media screen and (max-width:767px){
                    min-width: auto;
                }
              }
              span{
                display: inline-block;
              }
          }
      }
      .not_available{
        text-align: center;
        padding: 150px 0px 120px;
        @media screen and (max-width:767px){
          padding: 30px 0px;
      }
      }
}
 .view-listing-popup-box .actionDiv .delete::before , .view-listing-popup-box .actionDiv .delete::after{
    display: none;
  }
  .view-listing-popup-box  .actionDiv .delete{
    background-color: transparent;
  }
  .modalBox_content{
      overflow: visible;
  }
  .success_modal_container .dialog-content{
      overflow-y: visible !important;
  }
  .restriction-popup{
    .dialog-content
   {
      width: 100% !important;}
  }