.divider{
  opacity: 0.1;
}
.contact_us{
  input
  {
  background-color: #f7f7f7;
  border: 1px #f0f0f0 solid;
  border-radius: 5px;
  padding: 5px;
  width: 50%;
  }
  textarea
  {
  background-color: #f7f7f7;
  border: 1px #f0f0f0 solid;
  border-radius: 5px;
  padding: 5px;
  width: 50%;
  }
  .bottomSpacing{
      margin-bottom: 20px;
  }
  .button{
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.5px;
    background-color: #ee2738;
    color: #fff;
    border: 1px solid #ee2738;
    padding: 6px 20px;
    border-radius: 6px;
    cursor: pointer;
  }
  @media screen and (max-device-width:500px){
    input,textarea
    {
    width: 90%;
    }
  }
}