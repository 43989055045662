$black: #202020;
$water_blue: #1397e1;
$dark_grey: #36434b;
$off_white: #f7f7f7;
$white: #ffffff;
$primary: #ee2738;
$light_grey: #747474;
$off_black: #1a181e;
.syncing_calendar{
    .CustomSelectWithScroll-selectContainer-99 {
         border: solid 1px #e1e1e1;
        height: 36px;
        padding: 0px 4px;
        background: #f7f7f7 !important;
        margin-top: 4px;
        border-radius: 6px;
    }
    .custom_checkbox{
        float: right;
    }
}
.main-form{
    .main-form .custom_checkbox {
        float: right;
    }
    .custom_select_with_scroll {
        width: 53%;
        float: right;
    }
    .custom_checkbox {
        padding-top: 10px;
    }
    .custom_select{
        width: 50%;
    }
    .search_page{
        background: $primary;
        text-align: center;
        color: $white;
        padding: 4% 42%;
        border-radius: 0 0 100% 100% / 112px;
        overflow: hidden;
        margin-left: -10%;
        width: calc(100% + 20%) !important;
      
        h1{
            text-align: left;
            font-size: 48px;
            font-weight: 200;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $white;
        }
        p{
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: $white;
        }
        .material-icons{
            font-size: 48px;
        }
    }
    padding: 2% 10%;
    p{
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: $dark_grey;
    }
    h3{
        text-align: right;
        color: $dark_grey;
        font-size: 16px;
        margin: 0;
        @media screen and (max-width:767px)
        {
            display: none;
        }
    }
    h5{
        font-size: 16px;
        font-weight: 500;
         line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $dark_grey;
        margin: 0;

    }
    .small{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
        margin: 0;
    }
    h6{
        font-size: 16px;
        font-weight: bold;
         line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $dark_grey;
        margin: 0;
    }
    .MuiSelect-root-123 {

        border-radius: 0px;
     }
    .MuiSelect-select-124 {
        width: calc(100% - 100px);
        height: 1.1875em;
        cursor: pointer;
        min-width: 100px;
        user-select: none;
        padding-right: 32px;
        border-radius: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    
    .select_field {
        background-color: $off_white;
    
    }
    .data-table{
        border: 1px solid #c4d0d9;
        padding: 20px 20px 10px;
        margin-top: 10px;
    }
    .custom_checkbox{
        font-size: 13px;
    }
    button{
        border-radius: 6px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f1435e), to(#ee2738));
        background-image: linear-gradient(to bottom, #f1435e, #ee2738);
        border: 0;
        padding: 1% 6%;
        color: $white;
        margin-top: 20px;
    }
    @media screen and (max-width:768px){

        .webCalendar .syncing_calendar form{
            width: 100%;
        }
        .webCalendar .main-form{
            padding: 15px !important;
        }
        .webCalendar form {
            width: 100%;
        }
        .webCalendar .blue-text{
            width: 100%;
            display: block;
         }
         .webCalendar .custom_select_with_scroll{
             width: 100% !important;
         }
         .webCalendar .left, .webCalendar .right {
            width: 50%;
        }
          .CustomSelectWithScroll-selectContainer-99 {
                width: 100%;
            }
         p {
            font-size: 16px;        
        }
        button{
            padding: 10px 30px;
        }
        .small {
            margin: 8px auto;
        
        }
        .vertical_align_date{
            margin: 0px auto 0px 0px !important;
        }
        .vertical_align_reason{
            margin: 0 auto;
        }
        .custom_select_with_scroll {
            width: 80%;
            float: left;
        }
        .data-table .custom_checkbox {
            width: 80%;
            float: left;
        }
        .webCalendar .main .content .blue-text{
            display: block !important;
            width: 100% !important;
        }
        .webCalendar .main .content .blue-text .right {
            margin: 8px 0px;
        }       
    }
    .vertical_align_date{
        margin: 10px 0;
    }
    .vertical_align_reason{
        margin: 20px 0;
    }
    button{
        cursor: pointer;
    }
}
.google_calendar{
    .main-form {
        padding-top:48px;
        padding-left: 44px;
        .heading{
            text-transform: capitalize;
        }
    }
}
.mob-btn-red , .mob-btn-white , .mob-contact-btn{
    text-align: left !important;
    justify-content: left !important;             
    Button{
        text-align: left;
        justify-content: left !important;
        height: auto !important;              
    @media screen and (max-width:767px){
        background-color: transparent;
        background: none;
        color: #202020;
        box-shadow: none;  
        text-align: left;
    }
    span{
        color:rgba(0, 0, 0, 0.87);
        font-weight: 400;
        font-size: 12px;
    }
}
Button:hover {
    @media screen and (max-width:767px){
        background-color: transparent;
        background: none !important;
        color: rgba(0, 0, 0, 0.87);
        box-shadow: none !important;  
    }
    span:hover{
        background: none !important;
        color: rgba(0, 0, 0, 0.87) !important;
        box-shadow: none !important;  
        text-align: left;
    }
}
}
.mob-contact-btn{
    button{
        padding: 5px 0px;
    }
}
