.help-ui-wrapper{
    .article {
        background-color: #fff;
        padding:16px;
    }
    @media only screen and (min-width:1024px){
        .article {
            padding: 48px 48px;
        }
        .article h1 {
            font-size: 36px !important;
        }
        .article-body p {
            color: #424242;
            font-size: 20px;
        }
    }
    .article h1 {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 25px;
    }
    section.article-overview-list {
        margin-top: 10px;
    }
    .article-subheading {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .article-body p {
        color: #424242;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .authors {
        margin-bottom: 30px;
    }
    .article-body li {
        list-style: disc;
    }
    .article-body ul {
        margin: 16px 0;
        padding: 0 0 0 40px;
        list-style-position: outside;
    }
    .tags-section span {
        color: #dfe5ee;
        padding-right: 8px;
    }
    .tags-section a.tag-item,
    .tags-section a.tag-item:hover {
        color: #ee2738;
    }
    .article-body a {
        color: #ee2738;
    }
    .tags-section a.tag-item {
        text-decoration: none;
        margin-right: 8px;
        display: inline-block;
    }
    section.tags-section {
        background-color: #fff;
        box-shadow: 0 1px 4px 0 #dfe5ee;
        font-size: 13px;
        font-weight: 400;
        padding: 8px 16px;
        border-radius: 4px;
        margin-top: 20px;
    }
    .rating-section {
        border: 1px solid #e4e8ed;
        border-radius: 4px;
        padding: 16px 0 32px 0 !important;
        margin-top: 20px;
    }
    .rating-section .rating {
        padding: 8px 8px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin: 0 auto;
        padding: 0;
        height: 80px;
    }
    .rating-section .rating.emoji .rating-body a {
        font-size: 24px;
        margin: 0 12px;
    }
    .rating-section .rating-body a:hover {
        -webkit-transform: translate3d(0, 0, 0) scale(1.1);
        transform: translate3d(0, 0, 0) scale(1.1);
    }
    .rating-section .rating-body a {
        font-size: 32px;
        line-height: 42px;
        text-decoration: none;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
        -webkit-transition: -webkit-transform 250ms ease;
        transition: -webkit-transform 250ms ease;
        transition: transform 250ms ease;
        transition: transform 250ms ease, -webkit-transform 250ms ease;
        -webkit-transform: translate3d(0, 0, 0) scale(1.01);
        transform: translate3d(0, 0, 0) scale(1.01);
        display: inline-block;
    }
    .rating-section .rating.emoji .rating-body a:nth-child(1)::before {
        content: '\1F603';
    }
    .rating-section .rating.emoji .rating-body a:nth-child(2)::before {
        content: '\1F642';
    }
    .rating-section .rating.emoji .rating-body a:nth-child(3)::before {
        content: '\1F615';
    }
    .rating-section .rating.emoji .rating-body a:nth-child(4)::before {
        content: '\1F61E';
    }

    .widget {
        text-align: center;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .widget a {
        text-decoration: none;
        color: rgba(238, 39, 56, 0.8);
        padding-left: 4px;
    }
    .widget img {
        padding-right: 8px;
    }
}