/***
* == Header Style
***/
@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap');
$primary: #ee2738;
$red: #FF0000;
$white: #ffffff;
$dark-grey:#36434b;
$off-white: #f0f0f0;
$grey: #62717a;
$light-grey: #9aa7af;
$secondary: #f1435e;
$blue: #2497e2;


span,
div,
p,
li,
a,
input {
  font-family: 'Raleway', sans-serif;
}

//start of confirm email and confirm password
.submitDiv{
  padding-bottom: 24px;
}
.confirmPhoneSubmit{
  padding-top: 46px;
}
.confirm {
  width: 100%;
  min-height: 660px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  //background-color: $blue;
  height: 100vh;
  &:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 50%;
    position: absolute;
    //background: $off-white;
    border-radius: 0 0 100% 100% / 40%;
  }
}
.min-wid-email{
  min-width: 400px;
  @media screen and(max-width:767px) {
    min-width:280px;
  }
}
.confirmationBox {
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
    h1{
      padding: 20px 0px;
    }
    .phoneNumber{
      padding-bottom: 25px;
    }
    .confirmPhoneSubmit{
      padding: 20px 0px;
    }
  }
  .confirm-email{
    padding: 0px 15px;
    .phoneLabel{
      @media screen and(max-width:767px) {
        padding-left: 0px;
      }
    }
    .emailSentTo{
      padding: 20px 0px;
    }
    .yourBusiness{
      padding-bottom: 30px;
    }
    .submitDiv{
      padding: 20px 0px;
    }
  }
.confirmationPadding {
  color: #36434b;
  font-size: 16px;
  font-weight: bold;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 45px;
}
.confirmDiv { top: 20px;
  display: table;
  margin: 0 auto;
}

.emailSentTo{
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  padding: 0px 0px;

}
.phoneNumber{
  font-family: Raleway;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;    
  padding-bottom: 10px; 
  
}
.emailText{
  font-family: Raleway;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;     
  padding: 0px 0px;

}
.phoneCode{

  width: 276px;
  height: 16px;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a;

}
.phoneInput{
  width: 363px;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #f7f7f7;
}

.emailLastText{
    font-family: Raleway;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #62717a;
    padding: -1px 0px 0px 0px
}

.emailLabel {
  color: $grey;
  font-weight: 500;
  font-size: 11px;
  width: 359px;
  margin-bottom: 5px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  margin-top: 15px;
}

.emailField {
  width: 330px;
  height: 36px;
  border: none;
  background-color: #f7f7f7 !important;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;

  &:hover {
    border: 'none';
  }
}

.PhoneField, .inputField {
  width: 330px;
  height: 36px;
  border: none;
  background-color: #f7f7f7 !important;
  font-size: 15px !important;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
  border-radius: 6px;
  padding-left: 18px;
  padding-right: 15px;

  &:hover {
    border: 'none';
  }
}

.phoneLabel, .fieldLabel { 
  width: 357px;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: $grey;
  margin-top: 10px;
  margin-bottom: 7px;
  padding-left: 15px;
  @media only screen and (max-width: 767px) {
    padding-left: 0px;
  }
}

.conFIrmformFields { margin-top:-18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 89%;
  span{
    font-family: Raleway;
  }
}


.emailDiv {
  display: table;
  margin: -45px 0px 0px 142px;
}

.phoneDivBtn {
  display: table;
  margin: -3px 0px 0px 142px;
}
.emailSentTo{
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);

}
.texSentTo {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.signup__inner {
    margin-top: 10px;
    height: 540px;
    position: relative;
    bottom: 0;
}
//end of confirm email and confirm password

//start profile confirmation
.profile_confirmation {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    position: relative;
    background-color: $blue;

    .textAndEmailButton {
      padding: 9px 0px 22px 0px;
    }
   
    &:before {
      content: '';
      top: 0;
      left: 0;
      margin-left: -10%;
      width: calc(100% + 20%);
      height: 50%;
      position: absolute;
      background: #f0f0f0;
      border-radius: 0 0 100% 100% / 40%;
    }
   }
   
   .profile_confirmation__inner {
    margin-top: 10px;
    height: 540px;
    position: relative;
    bottom: 0;
   }
   .profile_confirmation_form {
    margin: 50px 0px 25px 0px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
    @media only screen and (min-width: 568px) {
      padding: 20px 50px 30px 50px;
    }
    padding: 20px 15px 30px 15px;
   }
   
   .edit_link {
    text-align: center;
   }
   
   .profile_confirmation_heading {
    color: #36434b;
    font-size: 16px;
    padding: 0px 0px 5px 0px;
   }
   
   .profile_confirmation_content {
    font-size: 14px;
    text-align: center;
    color: #62717a;
    font-weight: 500;
    line-height: 1.43;
   }
   .email_phone {
    color: $blue;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
   }
   .edit_link {
    text-decoration: none;
    color: #36434b;
    font-size: 14px;
   }
   .contact_container {
    margin-top: 30px;
   }
   .phone_container {
    @media only screen and (min-width: 568px) {
      padding-left: 78px;
    }
   }
   .email_container {
    @media only screen and (min-width: 568px) {
      padding-right: 79px;
    }
   }
   .email_edit {
    background-color: #f7f7f7 !important;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    &:hover{
      border:'none';
    }
    &:focus {
     text-align: center;
     font-size: 12px;
    }
    height: 22px;
    font-size: 12px;
    width: 177px;
    text-align: center;
   
   }
   .phone_edit {
    background-color: #f7f7f7 !important;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    &:hover{
      border:'none';
    }
    &:focus {
     text-align: center;
     font-size: 12px;
    }
    height: 22px;
    font-size: 12px;
    width: 177px;
    text-align: center;
   }
   .phone_edit_button,.phone_edit_back_button{
    @media only screen and (max-width: 411px) {
      padding-bottom: 15px;
    }
   }
   .email_phone_edit_error {
    margin-bottom: -20px;
    color:#FF0000;
    font-size: 11px;
    text-align: center;
    width:173px;
    padding-top: 6px;
   }
   
   .icon {
    font-size: 90px;
    color: #e1e1e1;
    position: relative;
    // top: 0px;
    // left: 6px;
    width: 100%;
    height: 100%;
    text-align: center;
   }
   .user-icon{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
   }
  .help{
    color: #757575;
    padding: 10px 0px 0px 25px;
    font-size: 13px;
  }
   
//end of profile confirmation

//start of sent mail and forgot
.instruction{
  padding: 0px 50px 30px 60px;
  text-align: left;
  font-size: 14px;
  color: #9a9a9a;
}
.sendButton{
    padding-bottom: 24px;
    padding-top: 35px;
}
.sentMail{
  margin-top: 106px;
}
.resetPassword{
  padding-bottom: 20px;
}
//end of sent mail and forgot

.error{
  color:$red;
  font-size: 11px;
  text-align: left;
  width: 333px;
}
.user-icon-profile{
  text-align: center;
    background-color: #f7f7f7;
    width: 90px;
    margin-bottom: 15px;
    border-radius: 50%;
    .icon{
      font-size: 80px;
    }
}
// profile confirmation css
.contact-us-link {
  text-align: center;
  p {
    color: #747474;
    font-weight: 600;
    font-size: 12px;
  }
  a,
  a:hover {
    color: #1397e1;
  }
}

.confirm.mobile-otp{
    .logo{
        text-align: center;
        img {
            width: 100px;
        }
    } 
    .phoneNumber,
    h1 {
        color:rgba(0, 0, 0, 0.87);
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        padding: 0;
    }
    .otp-input-box {
        align-items: center;
        justify-content: center;
        padding: 20px;
    }
    .expire{
        padding-top: 10px;
    }
    input.otp-input {
        width: 40px !important;
        text-align: center;
        height: 40px;
        margin: 12px;
        border-radius: 4px;
        border: 1px solid #1397e1;
        padding: 0;
    }
    h2.need-help {
        text-align: center;
        color: #24a0f1;
        font-weight: 600;
    }
    .signup__inner {
        max-height: 400px;
    }
    .confirmationPadding{
        margin-top: 0px;
    }
    .confirm-email .yourBusiness {
        padding-bottom: 0px;
    }
    .blue-text{
        color: #1397e1;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
    }
    h2.texSentTo {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }
    .min-wid-email {
        min-width: 480px !important;
        min-height: 348px !important;
    }
}

.need-help{
    display: block;
    padding: 50px;
    text-align: center;
    height: 320px;
    width: 435px;
}
.help-container{
    .need-help-block{
        border-radius: 6px;
        background-color: $white;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        border-style: solid;
        border-width: 1px;
        border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
        height: 340px;
    }
    .dark-text p{
        font-weight: 600;
        font-size: 17px;
        margin-top: 20px;
    }
    .blue-text{
        color: #1397e1;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
    }
    
}