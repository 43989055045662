$white:#ffffff;
$red: #ee2738;
.matter{ font-family: Raleway !important; }
.venue_page {
  // font-family: Raleway;
  // width: 94%;
  overflow-x: hidden;
  margin-top: 62px;
  // margin-left: 35px;
  // margin-right: 35px;
  @media screen and (max-device-width:500px){
    margin-top: 66.5px;
  }
  @media screen and (min-device-width:501px) and (max-device-width:1023px) {
    margin-top: 74px;
  }
  .aboutHead{ font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;}
    .aboutMatter{  font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      word-break: break-word;
      text-align: left;
      color: #62717a;
    }

  .EventPlannerHeader-appbar-106 {
    color: #36434b;
    box-shadow: inset 0 -1px 0 0 #fff !important;
    background-color: #ffffff !important;
  }

  .MuiTypography-body1-208 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
  }

  .MuiTypography-h4-214 {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
  }

  .leftPanel {
    width: 80%;

    .topImage {
      border: #000 1px solid;
      height: 394px;
    }
  }

  .rightPanel {
    width: 20%;

    .rightMap {
      border: #000 1px solid;
      height: 394px;
    }
  }

  .midContainer {
    // width: 97%;
    margin: 25px;

    .venue-available {
      width: 100%;
      font-family: Raleway !important;


      .avilableDescriptionDiv {
        width: 80%;

        .avilableDescription {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #62717a;
        }

      }

      .dayTimeDiv {
        width: 100%;
        color: #202020;

        .mondFriDiv {
          width: 13%;
          float: left;
        }

        .satSunfDiv {
          width: 26%;
          float: left;
        }

      }

      .availabilitytext {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.12;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
      }
    }

  }

  .spaceVanueDiv {
    width: 100%;
    min-height: 385px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;

    // .venue {
    //   width: 48%;
    //   height: auto;


    //   .venue_image {
    //     width: 100%;
    //     min-width: 150px;
    //   }

    //   .venue_content {
    //     margin-left: 3px;
    //     margin-top: -34px;
    //     margin-bottom: 49px;
    //   }

    //   .venue_name {
    //     font-size: 12px;
    //     font-weight: 500;
    //     font-style: normal;
    //     font-stretch: normal;
    //     line-height: 2;
    //     letter-spacing: normal;
    //     text-align: left;
    //     color: #202020;

    //   }

    //   .VenueCardWithFav-activeFav-363 {
    //     font-size: 24px;
    //     text-shadow: 0px 0px #ff0000;

    //     .active_icon_over_image {
    //       left: -2px !important;
    //     }

    //     .active_icon_over_image {
    //       top: -118px;
    //       position: relative !important;
    //     }
    //   }

    // }
  }

  .similarDiv {
    width: 100%;

    .similarDiv .active_icon_over_image {
      left: -44px;
    }

    .similarDiv .active_icon_over_image {
      color: red;
      position: absolute;
      width: 100%;
      top: 2px;
    }

    .similarContainer {
      width: 100%;
      padding: 10px;

      .similerText {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
        padding-bottom: 10px;
        @media screen and (max-width:767px){
          padding-bottom:0px;
        }
      }
    }

  }

  .venue-available-right {
    width: 98%;

    h3 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
    }

    .help_icon{
      padding: 12px 0px 12px;
      margin-left: -15px;
      button{
        background-color: transparent;
        border: 0px;
        box-shadow: none;
        display: block;
        padding: 0px;
      
      }
    }

    .venueRightContainer {
      width: 97%;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;

      .spaceImage {
        width: 100%;
      }

      // .venue {
      //   width: 50%;
      //   height: auto;
      //   padding-left: 14px;
      //   padding-right: 14px;
      //   padding-top: 14px;
      //   height: auto;

      //   .venue_image {
      //     width: 100%;
      //     min-width: 120px;
      //     height: 76%;

      //     .VenueCardWithFav-activeFav-363 {
      //       font-size: 24px;
      //       text-shadow: 0px 0px #ff0000;

      //       .venueRightContainer .active_icon_over_image {
      //         color: red;
      //         position: relative !important;
      //         width: 100%;
      //         top: -108px;
      //         left: -5px !important;
      //       }
      //     }
      //   }

      //   .venue_name {
      //     font-size: 12px;
      //     font-weight: 500;
      //     font-style: normal;
      //     font-stretch: normal;
      //     line-height: 2;
      //     letter-spacing: normal;
      //     text-align: left;
      //     color: #202020;

      //   }

      //   .venue_content {}



      //   .MuiIcon-root-205 {
      //     width: 1em;
      //     height: 1em;
      //     overflow: hidden;
      //     font-size: 24px;
      //     user-select: none;
      //     flex-shrink: 0;
      //   }
      // }
    }
  }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {

  .venue_page {

    .EventPlannerHeader-appbar-106 {
      color: #36434b;
      box-shadow: inset 0 -1px 0 0 #fff !important;
      background-color: #ffffff !important;

    }

    .MuiTypography-body1-208 {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
    }

    .MuiTypography-h4-214 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
    }

    .leftPanel {
      width: 80%;

      .topImage {
        border: #000 1px solid;
        height: 394px;
      }
    }

    .rightPanel {
      width: 20%;

      .rightMap {
        border: #000 1px solid;
        height: 394px;
      }
    }

    .midContainer {
      // width: 89%;
      margin: 14px;
      padding-left: 10px;
      padding-right: 10px;

      .venue-available {
        width: 100%;
        font-family: Raleway !important;



        .avilableDescriptionDiv {
          width: 80%;

          .avilableDescription {
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #62717a;
          }

        }

        .dayTimeDiv {
          width: 100%;
          color: #202020;
          

          .mondFriDiv {
            width: 40%;
            float: left;
          }

          .satSunfDiv {
            width: 50%;
            float: left;
          }

        }


        .availabilitytext {
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.12;
          letter-spacing: normal;
          word-break: break-word;
          text-align: left;
          color: #36434b;
        }
      }

    }

    .spaceVanueDiv {
      width: 100%;
      min-height: 385px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;

      // .venue {
      //   width: 48%;
      //   height: auto;

      //   .venue_image {
      //     width: 100%;
      //     min-width: 150px;
      //   }

      //   .venue_name {
      //     font-size: 12px;
      //     font-weight: 500;
      //     font-style: normal;
      //     font-stretch: normal;
      //     line-height: 2;
      //     letter-spacing: normal;
      //     text-align: left;
      //     color: #202020;

      //   }

      //   .VenueCardWithFav-activeFav-363 {
      //     font-size: 24px;
      //     text-shadow: 0px 0px #ff0000;


      //   }

      // }
    }

    .similarDiv {
      width: 100%;

      .similarContainer {
        width: 100%;
        padding: 10px;

        .similerText {
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #36434b;
        }
      }

    }

    .venue-available-right {
      width: 100%;

      h3 {
        font-family: Raleway;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        word-break: break-word;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
      }

      .venueRightContainer {
        width: 100%;
        border-radius: 6px;
        border: solid 1px #e1e1e1;
        background-color: #ffffff;

      .spaceImage {
        width: 100%;
      }
      }
    }
  }
}
.venue_space_venue_card{
  padding: 21px 8px;
  // width: 50%;
}
.similar_venue_card{
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width:767px) {
    padding: 10px;
    width: 100%;
  }
  .venueCardWithFav{
    .venue_image{
      width: 100%;
      transition: 200ms ease-in-out;
    }
  }
}

.image-div {
  width: 100%;
  height: 393.8px;
  background-color: #f0f0f0;
  @media screen and (max-width:767px) {
    height: auto;
   }

  .no-image-div {
    margin: auto;
    height: 122px;
    width: 91px;
    background-image: url('../images/file-image-regular.svg');
    background-repeat: no-repeat;
    background-size:cover;
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}

// start of venue details image css
.space_details_image {
  position: relative;

  .image_of_venue {
    width: 100%;
    height: 394px;
    // object-fit: cover;
  }
  .venue-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // @media screen and (max-width:767px) {
    //   height: auto;    
    // }
  }
  .space_details_over_image {
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(6, 6, 6, 0.2));
    position: absolute;
    bottom: 0; /* attaches the element to the bottom */
    left: 0; /* attaches the element to the left-side */
    right: 0; /* attaches the element to the right-side */
    align-items: flex-end;
    @media screen and (max-width:767px) {
      position:relative;
      background-image: none;
    }
    padding: 20px 20px 20px 20px;

    .div1 {
      width: 45%;

      .space_name_in_venue_details_image {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        text-align: left;
        text-align: left;
        word-break: break-word;
        color: $white;
        @media screen and (max-width:767px){
          color: #36434b;
        }
      }

      .venue_name {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        word-break: break-word;
        color: #ffffff;
        @media screen and (max-width:767px){
          color: #36434b;
        }
      }
    }

  }

  .space_address_details_in_venue_details_image {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    width: 40%;
    height: auto;
    display: flex;
    align-items: flex-end;
    // padding-top: 20px;
    @media screen and (max-width:767px){
      color: #36434b;
    }

    .icon_container {
      margin-top: 4px;
      padding-left: 23px;
    }

  }

  .place_icon_in_space_details_image {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ee2738;
    margin-right: 5px;
      @media screen and (max-device-width:767px){
      position: absolute;
      left: -24px;
      top: 8px;
      }
  }

  .view_photos {
    border: $white 1px solid;
  }

  .view_photos_container {
        margin-left: 8px;
    @media screen and (max-width:500px){
      padding: 10px 15px 10px 25px;
    }

    .white-button {
      // width: 100px;
      // height: 30px;
      padding: 5px 12px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      color: #ee2738;
      cursor: pointer;

      span {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  @media screen and (max-width:767px) {
    .space_details_over_image {
      height: auto !important;

      .div1 {
        padding-top: 5px;
        width: 100%;
      }

      .space_address_details_in_venue_details_image {
        width: 100%;
        padding: 5px 0 !important;
        margin-left: 20px;
        position: relative;
      }

      .view_photos_container {
        padding: 5px 0 !important;
        width: 100%;
      }
    }

  }
}
.crop-the-image{
  display: block;
  color: #ee2738;
  font-size: 11px;
  position: absolute;
  @media screen and (max-width:767px){
    top: 23px;
  }
}
.error-copyright{
  position: absolute;
  top: 38px;
  @media screen and (max-width:767px){
    top: 75px;
  }
}

.success_modal_container.venueprofilepopupui{
  button {
    padding: 6px 10px;
  }
} 