.paymentconfirmation{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    @media screen and (max-width:767px) {
        width:95%;
    }
    .paymentimage{
        padding-top: 10px;
        border-bottom: 3px solid #000;
        padding-bottom: 10px;
    }
    .title{
        padding:15px 0;
        font-weight: 600;
        font-size: 16px;
        color: #000;
    }
    .midContainer-payment{
        text-align: right;
        width: 70%;
        margin-left: auto !important;
        display: block;
        padding-right: 0;
        text-align: left;
        padding-top: 15px;
        @media screen and (max-width:767px) {
            width:100%;
        }
        .right{
            display: block;
            h3{
                text-align: left;
                font-size: 25px;
                font-weight: 600;
                color: #000;
            }
            p{
                font-size: 18px;
                color: #4185f4;
                font-weight: 600;
                padding: 20px 0px;

            }
        }
    }
    .annual-left{
        float: left; 
        width: 50%; 
        border-top: 2px solid #dddddd; 
        padding-top:30px; 
        border-bottom: 1px solid #dddddd; 
        padding-bottom:30px;
        p{
            font-size: 16px; 
            font-weight: bold; 
            color: #000;
        }
    }
    .annual-right{
        float: left; 
        width: 50%; 
        border-top: 2px solid #dddddd; 
        padding-top:30px; 
        border-bottom: 1px solid #dddddd; 
        padding-bottom:30px;
        p{
            font-weight: 700;
            color: #000;
        }
        span{
            font-size: 16px; 
            font-weight: bold; 
            padding: 0px 5px;
            font-size: 16px; 
            color: #000;
        }
    }
    .payment-method{
        margin: 0px auto;
        padding-bottom: 20px;
        display: inline-block;
        .one{
            padding-top: 20px;
            font-weight: bold;
            color: #3a3333;
            font-size: 14px;
        }
        .two{
            margin: 0px;
            color: #3a3333;
            font-size: 12px;
        }
        .three{
            margin: 0px;
            color: #3a3333;
            padding-bottom: 20px;
            font-size: 12px;
        }
    }
    
}