/***
* == Monday to Friday Style
***/
.monTextDiv
  {
    ul li div span{ font-family: Raleway !important;color: #202020 !important;font-size: 15px !important}
    ul li div p{
      font-weight: bold;
      color: #62717a;
    }
    .monfriText{ 
      font-family: Raleway !important;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #202020;
    }
    .MuiListItem-gutters-162 {
      padding: 3px 0px 20px 4px !important;
      
      }
    
     
      
  }
.monfriText li{
padding-left: 0 !important;
padding-right: 0 !important;
}
 