$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;

.my_favourites{
    padding-top: 65px;
    height: 100vh;

    .venueCardWithFav{
        .venue_image{
          width: 100%;
          transition: 200ms ease-in-out;
        }
    }
    .save_button{
        padding: 0px 32px;
    }
    .personal_info{
        padding-bottom: 50px;
    }
    .company_info{
        padding-bottom: 50px;
    }
    .change_password{
        padding-bottom: 50px;
    }
    .delete-image{
        text-align: center;
        color: #1397e1;
        text-transform: capitalize;
        // padding-left: 27px;
    }
    .delete-image:hover{
        text-align: center;
        color: #1397e1;
        text-transform: capitalize;
        text-decoration: none;
        cursor: pointer;
    }
        .headings{
            border-bottom: solid 1px #e1e1e1;
            text-align: center;
            .sub-heading{
                font-weight: 500;
                margin: -18px 0 8px 0px;
                font-size: 16px;
            }
        }
        .form_name{
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            text-transform: capitalize;
            margin: 20px auto;
            color: $water-blue;
        }
        .edit_profile_component{
            padding-bottom: 62px;
            background-color: #ffffff;
            padding: 0px 0px 20px 0px;
            margin: 15px 30px 15px 15px;
            box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
            width: auto !important;
            .help_icon{
                padding-top: 44px;
                cursor: pointer;
            }
            .edit_profile_component_form{
                @media screen and (max-width: 500px) {
                    padding-left:20px;
                    padding-right: 20px;
                }
            }
            .headings{
                // border: solid 1px #e1e1e1;
            }
            .form_name{
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: $water-blue;
                text-transform: capitalize;
                margin: 20px auto;
            }
            .left_panel{
                @media screen and (min-width:1025px){
                    padding-right: 55px;
                }
                @media screen and (min-width: 960px) and (max-width: 1025px){
                    padding-right: 20px;
                }
            }
            .right_panel{
                @media screen and (min-width:1025px){
                    padding-left: 57px;
                }
                @media screen and (min-width: 960px) and (max-width: 1025px){
                    padding-left: 20px;
                }
            }
            .password_field_div{
                padding-top: 20px;
                position: relative;
            }
            .field_div{
                padding-top: 20px;
            }
        .edit_profile_component{
            padding-bottom: 62px;
        }
        .similar_venue_card{
            padding: 0 10px 10px 10px;
            .detail-box{
                
                margin: -54px 0 12px 12px;
                color: #ffffff;
                .space-name{         
                    font-weight: 500;
                    font-size: 18px;
                }
                .venue-name{
                    font-size: 11px;
                    padding-left: 4px;
                }
            }
            
        }
    }
    .error{
        color: #FF0000;
        font-size: 11px;
        text-align: left;
        height: 10px;
    }
}
.widgetImg{
    img{

    @media screen and (max-width:767px) {
        max-width: 60px !important;
    }

    }
}
.widgetMobile{
    .calendar{
        @media screen and (max-width:767px) {

        margin:30px 25px 10px !important;
        
        }
    }
}
