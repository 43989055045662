.MuiMenu-paper-149 {
  width: 230px !important;
}

.MuiSelect-selectMenu-107 {
  padding: 0 !important;
}

.CustomTimeSelect-selectContainer-99 {
  padding: 0 !important;
  border: solid 1px #f0f0f0 !important;
}

.MuiGrid-spacing-xs-16-24 {
  margin: 0 !important;
}

.webCalendar {

  .header {
    width: 100%;
    height: auto;
    border-bottom: none !important;
    &:before {
      content: '';
      top: 0;
      left: 0;
      // margin-left: -10%;
      // width: 100%;
      // height: 85px;
      // background: #ee2738;
      // position: absolute;
      // border-radius: 0 0 100% 100% / 100%;
    }
  }

  .main {
    margin: 60px auto 0 45px;

    .large-text {
      font-family: Raleway;
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #36434b;
      margin: 0 !important;
      @media screen and (max-width:767px) {
        font-size: 24px;
      }
    }

    .sm-d-none{
      @media screen and (max-width:767px) {
        display: none !important;
      }
    }

    .content {
      width: 50%;
      display: grid;
      margin: 28px 0px 10px;

        .bold-text {
          height: 20px;
          font-family: Raleway;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #36434b;
          display: flex;
          justify-content: space-between;

          .left {
            width: 225px;
            display: inline-flex;
          }

          .right {
            width: 240px;
            display: inline-flex;
          }
        }

        .blue-text {
          height: auto;
          display: flex;
          align-items: center;
          border: solid 1px #e1e1e1;
          font-family: Raleway;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #1397e1;
          padding: 0 15px;
          justify-content: space-between;
          @media screen and (max-width:767px) {
            display: block;
            padding: 20px 15px;
          }
          .blue-left{
              margin-right: 20px;
              width: 225px;
              position: relative;
              padding-top: 1rem;
              display: inline-block;
                @media screen and (max-width:767px) {
                  width:100%;
                  padding-top: 0px;
                }
          }
          
          select {
            padding-right: 34px !important;
            margin-right: 15px;
            width: 100%;
          }

          .left {
            width: 60%;
            float: left;
          }

         

          .right {
            width: 225px;
            display: inline-block;
            @media screen and (max-width:767px) {
              width:100% !important;
              margin-top: 20px;
            }
          }
        }

        .MuiIconButton-root-110 {
          padding: 12px 8px 12px 0 !important;
          float: left;
        }

        .custom_select_with_scroll {
          width: 100%;
        }
    }

    .content-sm{
      display: none;
      @media screen and (max-width:767px) {
        display:block;
      }
      .blue-left{
        background-color: #f7f7f7 !important;
        select{
            color: #202020 !important;
            font-size: 14px;
            text-transform: capitalize;
          
           
        }
      }
      .left{
        width: 100% !important;
        color: #202020;
      }
      .heading-right{
        margin: 0px auto;
        margin-top: 0px !important;
        color: #202020;
      }
    }

    .pad-top{
      margin: 0px;
      @media screen and (max-width:767px) {
        display:none;
      }
    }

    .buttonContent {
      margin-top: 30px;
      margin-bottom: 40px;
      @media screen and (max-width:767px) {
        margin-top: 30px;
        margin-bottom: 10px;
      }

      .red-button-small {
        width: 130px;
        height: 36px;
        border-radius: 6px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        background-image: linear-gradient(to bottom, #f1435e, #ee2738);
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .MuiInputBase-fullWidth-132 {
      width: 75% !important;
    }

    .main {
      margin: 60px auto !important;
      width: 95% !important;

      .content {
        width: 100% !important;
      }

      .large-text {
        height: auto;
        overflow: auto;
      }
    }

    .left, .right {
      width: 50% ;
    }

    .bold-text, .normal-text {
      height: 40px !important;
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 768px){
  .buttonContent {
    text-align: center
  }
  .webCalendar .error {
    width: auto;
  }
  .webCalendar .left {
    width: 40% !important;
  }
  .webCalendar .right{
    width: 60% !important;
  }
  .webCalendar .custom_select_with_scroll>div {
    padding: 0 5px 0px 15px;
  }
  .webCalendar .custom_select_with_scroll svg {
    right: -7px;
  }

}
@media only screen and (max-width: 767px){
      .webCalendar .header{
        position: relative;
      }
}
.select{
  width: 100%;
  border: solid 1px #e1e1e1;
  height: 36px;
  padding: 0px 10px;
  background: #f7f7f7 !important;
  margin-top: 4px;
  border-radius: 6px;
  color: #202020;
 position: relative;
font-size: 14px;
font-weight: 500;
text-transform: capitalize;
}
.blue-text p::before {
  content: 'arrow_drop_down';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 0.8;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 24px;
  right: 15px;
  color: #62717a;
  @media screen and (max-width:767px) {
    top: 10px;
    right: 5px;
  }
}
.webCalendar{
.custom_select_with_scroll {
  #select-googlecalendar{
    padding-right: 15px;
  }
  div{
  margin-top: 0px;
  }
  svg {
    right: 0px;
  }
}
}


.webCalendar .main .content.triplesheat{
    .blue-text {
        align-items: start;
        .blue-left {
            padding-top: 14px;
        }
        .material-icons.help-button {
            margin-top: 20px;
        }
    }
    
} 