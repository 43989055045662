.matter{ font-family: Raleway !important; }
.buttons-header {
  overflow-x: hidden;
  padding: 20px 30px 10px;
    @media screen and (max-width:767px) {
      padding: 10px 15px;
    }

  .title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    word-wrap: break-word;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    float: left;
    width: 50% !important;
    @media screen and (max-width:767px) {
      width: 100%;
    }
  }

  .buttons-group {
    width: 50%;
    text-align: right;
    display: inline-flex;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-align: right;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width:767px) {
      width:100%;
      text-align: center;
      display: block;
      justify-content: space-between;
      span{
        padding-bottom: 5px;
      }
    }

    .small-grey-text {
      font-size: 10px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      color: #62717a;
      margin: 0 5px;
      text-transform: uppercase;

      @media screen and (max-width:767px) {
       display: block;
       margin-left: 0px;
      }
    }
      .btn-right {
        display: inline-flex;
        margin-left: 7px !important;
        @media screen and (max-width:767px) {
          margin-left: 0px !important;
          margin-top: 0px;
         }
    }

    .btn-publish{
      text-align: center;
      display: inline;
      justify-content: center;
      display: inline;
      @media screen and (max-width:767px) {
        margin-left: 7px !important;
       }
      }

    .red-button {
      width: 130px;
      height: 30px;
      border-radius: 6px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      margin: 0 5px;
      border:0 !important;
      cursor: pointer;
      @media screen and (max-width:767px) {
        width:135px;
        // margin-top: 10px;
        justify-content: center;
        display: inline-flex;
        margin: 0px 0px;
        padding: 0px 15px;
      }
    }

    .grey-button {
      width: 150px;
      height: 30px;
      border-radius: 6px;
      background-color: #9aa7af;
      font-family: Raleway !important;
      font-size: 13px;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      color: #ffffff;
      margin: 0 5px;
      border:0 !important;
      cursor: pointer;
      position: relative;
      @media screen and (max-width:767px) {
        width:135px !important;
        height: 32px;
      }
      .help_icon{
          position: absolute;
          right: 5px;
      }
    }
  }
}
 