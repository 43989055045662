$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;

.my_events{
    padding-top: 65px;
    height: 100vh;
    // height: 100%;
    // @media screen and (max-width:1366px) and (min-width: 1300px) {
    //     // padding-bottom:220px;
    //     height: auto;
    // }
    .save_button{
        padding: 0px 32px;
    }
    .personal_info{
        padding-bottom: 50px;
    }
    .company_info{
        padding-bottom: 50px;
    }
    .change_password{
        padding-bottom: 50px;
    }
    .delete-image{
        text-align: center;
        color: #1397e1;
        text-transform: capitalize;
        // padding-left: 27px;
    }
    .delete-image:hover{
        text-align: center;
        color: #1397e1;
        text-transform: capitalize;
        text-decoration: none;
        cursor: pointer;
    }
    
        .form_name{
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            text-transform: capitalize;
            color: $water-blue;
        }
        .edit_profile_component{
            padding-bottom: 0px;
            background-color: #ffffff;
            padding: 20px 20px 20px 20px;
            margin: 15px 15px;
            min-height: 85vh;
            height: auto;
            display: flex;
            width: auto !important;     
            overflow: visible;       
            // vertical-align: middle;
            border: 1px solid #ebebeb;
            @media screen and (min-width: 1599px) {
                padding-bottom:150px;
            }
            @media screen and (max-width:1366px) and (min-width: 1300px) {
                padding-bottom:240px;
            }
            
            .help_icon{
                padding-top: 44px;
                cursor: pointer;
            }
            .edit_profile_component_form{
                @media screen and (max-width: 500px) {
                    padding-left:20px;
                    padding-right: 20px;
                }
            }
            .form_name{
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: $water-blue;
                text-transform: capitalize;
                @media screen and (max-width: 767px) {
                    margin: 0px;
                    padding-left: 25px;
                }
            }

            .form_desc{
                font-size: 14px;
                text-align: center;   
                font-weight: 500; 
                padding-top: 4px;
                a{
                   color:#4a4a4a;
                   .info-icon{
                    font-size: 14px;
                    width: 20px;
                    height: 20px;
                    margin: -4px;
                   }
                }            
            }

            .left_panel{
                @media screen and (min-width:1025px){
                    padding-right: 55px;
                }
                @media screen and (min-width: 960px) and (max-width: 1025px){
                    padding-right: 20px;
                }
            }
            .right_panel{
                @media screen and (min-width:1025px){
                    padding-left: 57px;
                }
                @media screen and (min-width: 960px) and (max-width: 1025px){
                    padding-left: 20px;
                }
            }
            .password_field_div{
                padding-top: 20px;
                position: relative;
            }
            .field_div{
                padding-top: 20px;
            }
        .edit_profile_component{
            padding-bottom: 62px;
        }
    }
    .error{
        color: #FF0000;
        font-size: 11px;
        text-align: left;
        display: block !important;
        position: absolute;
        bottom: -16px;
        left: 0px;
    }
    input#city{
        border-radius: 6px;
        border: solid 1px #e1e1e1 !important;
        background-color: transparent;
        width: 100%;
        padding: 9px 15px;
        font-size: 15px;
        line-height: 1.07;
        text-align: left;
        color: #202020;
    }
    .suggestionDiv {
        width: 100% !important;
        position: relative;
        font-size: 12px;
        line-height: 2.3;
        word-break: break-word;
    }

    input[role="combobox"]+div {
        z-index: 99;
        top: 35px !important;
        left: 0px !important;
        position: absolute !important;
        min-height: 95px !important;
        background: #fff;
        overflow-y: scroll;
        height: 150px;
        box-shadow: 0px 15px 10px -15px #ccc;
        border: 1px solid #e1e1e1;
        font-weight: 500;
        cursor: pointer;
      }

      input[role="combobox"]+div .item{
        padding: 0px 15px;
        
      }

      input[role="combobox"]+div div:hover{
        background-image: linear-gradient(rgb(238, 238, 238), rgb(238, 238, 238));
      }
}
// .my_event_container{
//     @media screen and (max-width:767px){
//         padding-bottom: 62px !important;
//     }
// }
.my_event_container .loading{
    display: block;
    position: absolute;
    top: 43%;
    font-size: 25px;
    left: 47%;
    font-weight: 700;
    background-color: transparent !important;
}

