/***
* == ModalBox Style
***/
$primary: #ee2738;
$dark-grey:#36434b;

@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap');
.rightImg{ 
  width: 32px; height: 32px; border-radius:50px; 
}
.rightMenuDiv{
  padding: 5px 1px 0px 0px;
}
#customized-menu{
  span{
    font-size: 14px;
    color: $dark-grey;
    font-family: 'Raleway', sans-serif;
  }
  svg{
    font-size: 16px;
    color: $dark-grey;

  }
}


 @media only screen 
  and (min-device-width: 320px)
   and (max-device-width: 991px)
  {
    .noThank_btn {
      padding: 12px 27%;
    }

  }
  @media screen and (max-width: 1024px) {
    .rightImg{ 
      width: 32px; height: 32px; border-radius:50px; 
    }
    .rightMenuDiv{
      padding: 5px 1px 0px 0px;
    }
  }
 