.venue_plan_container {
  margin-top: 60px;
  font-family: Raleway;
  width: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
  height: 100%;

  .plan_outer_container {
    margin: 0 auto;
    text-align: center;
    padding-top: 50px;
    .venue-mouse-over{
      button{
        background-color: transparent;
        color: #4a4a4a;
        box-shadow: none;
        padding: 0px;
        min-width: auto;
        font-size: 20px;
        font-weight: 400;
      }
    }
    .heading-red{
      font-size: 24px;
      font-weight: 700;
      line-height: 1.33;
      //color: #ee2738;
      color:#36434b;
      //padding-bottom: 10px;
    }
    .head-text{
      font-size: 20px;
      font-weight: 600;
      line-height: 1.43;
      color: #36434b;
      margin: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .head-text-blue{
      color: #1397e1;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.43;
    }
    .tier-box {
      display: block;
      justify-content: space-between;
      width: 100%;
    }
    .blue-heading{
      color: #1397e1;
      padding-bottom:12px;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.43;
    }
  }

  .advertisement{
    font-family: Raleway;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ee2738;
    margin: 10px auto 20px;
    text-align: center;
  }
  .plan_inner_container {
    width: 300px;
    min-height: auto;
    border-radius: 6px;
    vertical-align: top;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin: 30px 15px;
    display: inline-block;
  }

  .plan_title {
    font-family: Raleway;
    font-size: 48px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.96;
    letter-spacing: normal;
    color: #36434b;
    margin: 20px auto 10px;
  }

  .blue {
    color: #1397e1 !important;
  }

  .bigsub_title{
    font-size: 20px;
    padding-bottom: 15px;
    a{
      color: #36434b;
    }
  }

  .red-text {
    width: 100%;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ee2738;
    margin: 24px auto 10px;
  }

  .small-font {
    width: 74px;
    height: 32px;
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #36434b;
    margin: 10px auto !important;
  }

  .sub_title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #747474;
  }

  .plan_description {
    width: 80%;
    height: 920px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #62717a;
    list-style: none;
    margin: auto;
    text-align: right;
    padding-inline-start: 0 !important;

    li {
      overflow: auto;
      width: 100% !important;
      padding: 2px 0;
      .red{
        color: #ee2738;
        font-weight: 700;
      }
    }
  }

  .list {
    display: list-item;
  }

  .big {
    width: 240px;
  }

  .text {
    display: inline-block;
    width: 90%;
    padding: 8px 0 0 4px;
    text-align: left;
  }

  .blue_icon_div {
    width: 10%;
    display: inline-block;
    float: left;
    padding-top: 10px;
  }

  .blue_icon {
    width: 16px;
    height: 16px;
    border: 0;
    background-color: none;
  }

  .red-section-1 {
    width: 100%;
    height: 91px;
    background-color: #ee2738;
    overflow: hidden;
    @media only screen and (min-width:992px){
      background: transparent;
      position: relative;
      &:before{
        content: '';
        top: 0;
        left: 0;
        margin-left: -10%;
        width: calc(100% + 20%);
        height: 100%;
        position: absolute;
        background: #ef4a39;
        border-radius: 100% 0 0% 0% / 35%;
      }
    }

  }

  .red-section-2 {
    width: 100%;
    height: 91px;
    overflow: hidden;
    background-color: #ee2738;
    @media only screen and (min-width:992px){
      background: transparent;
      position: relative;
      &:before{
        content: '';
        top: -8px;
        left: 0;
        margin-left: -10%;
        width: calc(100% + 20%);
        height: calc(100% + 14px);
        position: absolute;
        background: #ef4a39;
        border-radius: 100% 100% 0% 0% / 25%;
      }
    }
    
  }

  .red-section-3 {
    width: 100%;
    height: 91px;
    overflow: hidden;
    background-color: #ee2738;
    @media only screen and (min-width:992px){
      background: transparent;
      position: relative;
      &:before{
        content: '';    font-family: Raleway;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #ee2738;
        margin: 0px auto 26px;
        top: 0;
        left: 0;
        margin-left: -10%;
        width: calc(100% + 20%);
        height: 100%;
        position: absolute;
        background: #ef4a39;
        border-radius: 0% 100% 0% 0% / 35%;
      }
    }
  }

  .button-bg {
    width: 130px;
    height: 36px;
    border-radius: 6px;
    background-image: linear-gradient(to bottom, #ffffff, #ececec);
    margin-top: 32px;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738;
  }

  @media only screen and (max-width:991px) and (min-width:768px){
    .plan_inner_container{
      float: left;
      width: 46%;
    }
  }

  @media only screen and (max-width:767px){
    .plan_outer_container {
      width: 80%;
    }

    .plan_inner_container {
      width: 100%;
      min-width: 100%;
      padding-top: 15px;
      margin: 30px 40px 30px 0px;
    }

    .plan_description {
      height: auto;
      margin-bottom: 20px;
    }
  }
}
.checkall-block fieldset {
  display: block;
}