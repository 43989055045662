$borderRadius: '.3rem';
$margin: '.5rem';
$padding: '.25rem .5rem';
$eventColor: 'white';
$eventFontSize: '14px';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.flexContainer {
    max-width: 100%;
    width: 100%;
    // border-top: 1px solid ;
    // border-left: 1px solid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.flexColumn {
    display: flex;
    width: 14.2857142857%;
    // border-bottom: 1px solid;
    // border-right: 1px solid;
    flex: 0 1 auto;
    justify-content: center;
}

.day.inactive {
    background-color: #f8f8f8;
    color: #ccc;
}

.day.today {
    background-color: #fcf8e3;
}

.day .inner-grid {
    width: 100%;
    position:relative;
    
}

.day .event-slot {
    position: relative;
    margin: $margin 0;
    min-height: 28px;
    font-size: $eventFontSize;
}

.day .event-slot.event {
    background:transparent;
    color: black;
    white-space: nowrap;
    text-indent: -10000px;
    cursor: pointer;
} 

.day .event-slot .event-title {
    position: absolute;
    top:($eventFontSize/2)/2;
    left:$margin;
    z-index: 100;
    color:black;
    z-index:1;
    overflow:visible;
    text-indent: 0;
}

.day .event.event-first-day {
    margin-left: $margin;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.day .event.event-last-day {
    margin-right: $margin;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}  

.day .date {
    padding: $padding;
    text-align: right;
}   
.blankClass{
    background-color: transparent !important;  
}



//single event
.single-event{
    height: 100%;
}

//single block
.blockClasssingle{
    background-color: grey !important;
    height:100% !important;
}

.bookClassSingle{
    background-color: red !important;
    height:100% !important;
}

//for 2 time slot hold
.holdClassLeft {
    width: 0;
    height: 0;
    border-bottom: 50px solid #ccebf9;
    border-left: 62px solid transparent;
    background: transparent !important;
    @media screen and (max-width:991px) {
        border-left:44px solid transparent;
    }


  }

  .holdClassRight {
    width: 0;
    height: 0;
    border-top: 50px solid #69c7ee;
    border-right:62px solid transparent;
    background: transparent !important;
    @media screen and (max-width:991px) {
        border-right:44px solid transparent;
    }

  }
  

  //for timeslot 2 and block
  .blockClassRight {
    width: 0;
    height: 0;
    border-top: 52px solid grey;
    border-right:62px solid transparent;
    background: transparent !important;

  }

  .blockClassLeft {
    width: 0;
    height: 0;
    border-bottom: 50px solid grey;
    border-left: 60px solid transparent;
    background: transparent !important;

  }


  //book
  .bookClassRight {
    width: 0;
    height: 0;
    border-top: 50px solid red;
    border-right:60px solid transparent;
    background: transparent !important;

  }

  .bookClassLeft {
    width: 0;
    height: 0;
    border-bottom: 50px solid red;
    border-left: 60px solid transparent;
    background: transparent !important;

  }


//for timeslot 4 and hold
.holdClass{
    background-color: #69c7ee !important;
    height:12px !important;
    top: 0px !important;


}
.holdClassOne4{
    background-color: #ccebf9 !important;
    top: 12px !important;
    height:12px !important;

}
.holdClassTwo4{
    background-color: #69c7ee !important;
    top: 24px !important;
    height:12px !important;

}
.holdClassThree4{
    background-color: #ccebf9 !important;
    top: 36px !important;
    height:12px !important;


}

//for timeslot 4 and book
.bookClass{
    background-color: red !important;
    height:12px !important;
    top: 0px !important;


}
.bookClassOne4{
    background-color: red !important;
    top: 12px !important;
    height:12px !important;

}
.bookClassTwo4{
    background-color: red !important;
    top: 24px !important;
    height:12px !important;

}
.bookClassThree4{
    background-color: red !important;
    top: 36px !important;
    height:12px !important;


}



.blockClass{
    background-color: grey !important;
    height:12px !important;
    top: 0px !important;


}
.blockClassOne4{
    background-color: grey !important;
    top: 12px !important;
    height:12px !important;

}
.blockClassTwo4{
    background-color: grey !important;
    top: 24px !important;
    height:12px !important;

}
.blockClassThree4{
    background-color: grey !important;
    top: 36px !important;
    height:12px !important;


}





//for timeslot 3 and hold
.holdClass3{
    background-color: #69c7ee !important;
    height:15px !important;
    top: 0px !important;


}
.holdClassOne3{
    background-color: #ccebf9 !important;
    top: 16px !important;
    height:15px !important;

}
.holdClassTwo3{
    background-color: #69c7ee !important;
    top: 32px !important;
    height:15px !important;

}

//book 3
.bookClass3{
    background-color: red !important;
    height:15px !important;
    top: 0px !important;


}
.bookClassOne3{
    background-color: red !important;
    top: 16px !important;
    height:15px !important;

}
.bookClassTwo3{
    background-color: red !important;
    top: 32px !important;
    height:15px !important;

}



.blockClass3{
    background-color: grey !important;
    height:15px !important;
    top: 0px !important;


}
.blockClassOne3{
    background-color: grey !important;
    top: 16px !important;
    height:15px !important;

}
.blockClassTwo3{
    background-color: grey !important;
    top: 32px !important;
    height:15px !important;

}

//blockout
.blockOut{
    background-color: grey !important;
}

//make available
.makeAvailableClass{
    background-color: #ffffff !important;
    color: black;
    height: 100% !important;
}





//book
//single block
.bookClasssingle{
    background-color: red !important;
    height:100% !important;
}

//for 2 time slot hold
.bookClassLeft {
    width: 0;
    height: 0;
    border-bottom: 50px solid red;
    border-left: 60px solid transparent;
    background: transparent !important;
    @media screen and (max-width:991px) {
        border-left:44px solid transparent;
    }


  }

  .bookClassRight {
    width: 0;
    height: 0;
    border-top: 50px solid red;
    border-right:60px solid transparent;
    background: transparent !important;
    @media screen and (max-width:991px) {
        border-right:44px solid transparent;
    }

  }
  

  //for timeslot 2 and block
  .bookClassRight {
    width: 0;
    height: 0;
    border-top: 50px solid red;
    border-right:60px solid transparent;
    background: transparent !important;

  }

  .bookClassLeft {
    width: 0;
    height: 0;
    border-bottom: 50px solid red;
    border-left: 60px solid transparent;
    background: transparent !important;

  }



//for timeslot 4 and hold
.bookClass{
    background-color: red !important;
    height:12px !important;
    top: 0px !important;


}
.bookClassOne4{
    background-color: red !important;
    top: 12px !important;
    height:12px !important;

}
.bookClassTwo4{
    background-color: red !important;
    top: 24px !important;
    height:12px !important;

}
.bookClassThree4{
    background-color: red !important;
    top: 36px !important;
    height:12px !important;


}
.bookClass{
    background-color: red !important;
    height:12px !important;
    top: 0px !important;


}
.bookClassOne4{
    background-color: red !important;
    top: 12px !important;
    height:12px !important;

}
.bookClassTwo4{
    background-color: red !important;
    top: 24px !important;
    height:12px !important;

}
.bookClassThree4{
    background-color: red !important;
    top: 36px !important;
    height:12px !important;


}


//for timeslot 3 and hold
.bookClass3{
    background-color: red !important;
    height:15px !important;
    top: 0px !important;


}
.bookClassOne3{
    background-color: red !important;
    top: 16px !important;
    height:15px !important;

}
.bookClassTwo3{
    background-color: red !important;
    top: 32px !important;
    height:15px !important;

}

.bookClass3{
    background-color: red !important;
    height:15px !important;
    top: 0px !important;


}
.bookClassOne3{
    background-color: red !important;
    top: 16px !important;
    height:15px !important;

}
.bookClassTwo3{
    background-color: red !important;
    top: 32px !important;
    height:15px !important;

}
