.success_modal_container div:nth-child(2) div:first-child {
  max-width: 550px;
  .dialog-content{
    min-height: 400px;
  }
}
.success_modal_container_small div:nth-child(2) div:first-child {
  max-width: 550px;
  .dialog-content{
    min-height: 100px !important;
  }
  
}
.help-button{
  cursor: pointer;
}

.view-listing-popup { 
  .header {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    margin-top: 5px;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-weight: 600;

    @media screen and (max-width:767px) {
      margin-top: -5px;
      height: 72px !important;
    }
  }
  .modal_container{
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .list-box {
    padding: 10px;
    border: 1px solid #e1e1e1;
    margin: 8px;
    background-color: #ffffff;
    .blue-text {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      margin: 0;
    }
    .grey-text {
      font-family: Raleway;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color:#62717a;
      margin: 0;
    }
    .actionDiv {
      display: inline-block;
      width: 30%;
      text-align: right;
      vertical-align: top;
      @media screen and (max-width:767px) {
        width: 30% !important;
      }
      span {
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  .not_available {
    text-align: center;
    padding: 150px 0px 120px;

    @media screen and (max-width:767px) {
      padding: 30px 0px;
    }
  }
}

.modalBox_content {
  overflow: visible;
}

.success_modal_container .dialog-content {
  overflow-y: visible !important;
}


