$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;
$dark-grey: #62717a;
.holiday-rate-container {
    border: 1px solid $grey;
    padding: 12px;
    margin-top: 12px;
    border-radius: 4px;
    .input_field {
        border: solid 1px $grey;
        background-color: $white;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: $black;
        height: 36px;
        width: 250px;
        border-radius: 6px;
        padding-left: 15px;
        padding-right: 15px;        
        @media screen and (max-width:1024px) and (min-width:768px) {
            width: 200px;
        }
        @media screen and (max-width:767px) {
            width: 100%;
        }
    }
    .input_label {
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: $dark-grey;
        margin: 8px 0 8px 0;
        @media screen and (max-width:767px) {
            width: 90%;
            float: left;
        }
    }
    .checkbox-container{
        margin-top:8px;
        label{          
            margin-right: 8px;
        }
       
    }
}
