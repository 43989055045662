.space-complete {
    overflow-x: hidden;
    padding-top: 180px;

    @media screen and (max-device-width:500px){
      margin-top: 66.5px;
    }
    @media screen and (min-device-width:501px) and (max-device-width:1023px) {
      margin-top: 74px;
    }
    background-color: #1397e1;

    .mask {
      height: 580px;
      background-image: linear-gradient(to top, var(#1397e1), #1cb3ff);
      justify-content: center;
    }

    .venueCompleteContent {
      text-align: center;
    }

    .check_circle---material {
      width: 50px;
      height: 50px;
      -webkit-text-stroke: 2px #ffffff;
      background-image: linear-gradient(to bottom, #59e8a9, #2dcc70);
      font-family: material;
      font-size: 60px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      border: 2px white solid;
      border-radius: 50%;
    }

    .thankYouText {
      width: 420px;
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #ffffff;
      margin: 10px auto;
      text-align: left;
    }

    .title {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin: 10px 0 0 0;
      text-transform: uppercase;
    }

    .sub-title {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin: 0;
    }

    .button-bg {
      height: 36px;
      border-radius: 6px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
      margin-top: 65px;

      .button-text {
        width: 100%;
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
    .link-conatiner{
      padding-top: 10px;
      a{
        text-decoration: none; 
        color:#ffffff;
        font-size: 14px;
        font-weight: bold;
      }
      a:hover{
        color: #cbd0d4;
      }
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
      padding-top: 20px;

      .thankYouText {
        width: 90%;
      }

      .button-bg {
        margin-top: 45px;
      }
    }
  }
