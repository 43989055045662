$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;
$red: #ee2738;
.video-dialog-container {
    padding: 24px;
    p {
        width: 100%;
    }
    .heading-1 {
        font-size: 18px;
        text-align: center;
        font-weight: 500;
    }
    .heading-2 {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    .bottom-heading {
        margin: 16px 0;
        font-size: 14px;
        text-align: center;
    }
    .bottom-link {
        margin: 4px 0;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        a {
            color: $water-blue;
        }
    }
    .v-container {
        .button-box {
            margin-top: 18px;
            .sign-btn {
                background-color: $red;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .input-label{
            margin-left: 4px;
        }
        .input-box {            
            margin-bottom: 8px;
            label {
                font-size: 14px;
                font-weight: 600;
                padding-left: 4px;
            }
            .inputField {
                width: 100%;
            }
            .hint{
                font-size: 11px;
                padding-left: 8px;
            }
        }
    }
}
.add-videos {
    .grid-section-vertical {
        h3 {
            color:#62717a;
            font-weight: bold;
            padding: 10px 10px 10px 0;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            padding-left: 4px;
            color: $red;
        }
    }

    .coupon-list-container {
        margin-top: 4px;
        min-width: 300px;
        max-width: 300px;
        ul {
            width: 100%;
            li {
                // border: 1px solid #f1efef;
                // margin-top: 4px;
                .avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 6px;
                }
                .c-text {
                    padding: 12px;
                    h3 {
                        text-transform: capitalize;
                        font-weight: 500;
                    }
                }
                .action-icon {
                    button {
                        padding: 2px;
                        .redIcon {
                            color: #ee2738;
                        }
                    }
                }
                .blueIcon {
                    color: #1397e1;
                    font-size: 30px;
                }
            }
        }
    }

}

.grid-section-vertical .select_field{
    -webkit-appearance:auto;
}