$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;

// .add_venue{
//     padding-top: 75px;
//     height: 100%;
//     // start of stepProgreeBar
    
//     //end of stepProgressBar ccs
//     // begin of add venue name css
//     .add_venue_name{
//         padding: 11px 40px 0px 40px;
//         background-color: $white;
//         @media screen and (max-width:500px) {
//             padding: 12px 21px 0px 25px;
//         }
//         .add_venue_container{
//             border: solid 1px #e1e1e1;
//             background-color: #ffffff;
//             margin: 0px 0px 20px 0px;
//             height: 505px;
//             padding-top: 42px;
//             @media screen and (max-width:500px){
//                 padding-left: 20px;
//                 padding-right: 20px;
//             }
//         }
//         .code_visible{
//             font-size: 12px;
//             font-weight: 500;
//             line-height: 1.33;
//             text-align: left;
//             color: #62717a;
//             padding-top: 5px;
//             @media screen and (max-width:'500px'){
//                 padding-left: 5px;
//                 padding-right: 5px;
//             }
//         }
//         .venue_type{
//             padding-top: 26px;
//             @media screen and (max-width:'500px'){
//                 padding-left: 5px;
//                 padding-right: 5px;
//             }
//         }
//         .venue_code{
//             padding-top: 26px;
//             @media screen and (max-width:'500px'){
//                 padding-left: 5px;
//                 padding-right: 5px;
//             }
//         }
//         .venue_name{
//             @media screen and (max-width:'500px'){
//                 padding-left: 5px;
//                 padding-right: 5px;
//             }
//         }
//     }
//     // end of add venue name css
//     // begin of add venue photo css
//     .add_venue_photo{
//         padding: 11px 40px 94px 6px;
//         background-color: $white;
//         @media screen and (max-width:500px) {
//             padding: 12px 21px 0px 25px;
//         }
//         .image_detail_form{
//             @media screen and (max-width: 500px) {
//                 padding:20px;
//             }
//         }
//         // .image_upload{
//         //     background-color: #e1e1e1;
//         //     width: 100%;
//         //     height: 260px;
//         // }
//         .add_venue_photo_container{
//             border: solid 1px #e1e1e1;
//             background-color: #ffffff;
//         }
//         .learning_hall_container{
//             border-bottom: solid 1px #e1e1e1;
//         }
//         .learning_hall{
//             font-size: 16px;
//             font-weight: bold;
//             text-align: center;
//             text-transform: capitalize;
//             color: $water-blue;
//         }
//         .add_venue_profile_photo_heading{
//             font-size: 16px;
//             line-height: 1.5;
//             text-align: left;
//             color: $black;
//             padding: 18px 0px 8px 0px;
//         }
//         .add_venue_description{
//             font-size: 16px;
//             line-height: 1.5;
//             text-align: left;
//             color: $black;
//             padding: 42px 0px 10px 0px;
//         }
//         // .venue_image{
//         //     width: 100%;
//         // }
//         .description{
//             font-size: 11px;
//             font-weight: 500;
//             line-height: 1.45;
//             text-align: left;
//             color: #62717a;
//         }
//         .venue_description{
//             padding-bottom: 94px;
//             textarea{
//                 border-radius: 6px;
//                 border: solid 1px #e1e1e1;
//                 background-color: $white;
//                 height: 70px;
//                 width: 100%;
//                 padding: 7px 15px;
//                 margin-top: 4px;
//                 font-size: 15px;
//                 line-height: 1.07;
//                 text-align: left;
//                 &::placeholder{
//                     font-size: 15px;
//                     line-height: 1.07;
//                     text-align: left;
//                     color: #b0b0b0;
//                 }
//             }
//         }
//     }
//     // end of venue photo css
//     // begin of add venue address css
//     .add_venue_address_container,.add_venue_contact_container{
//         background-color: $white;
//         padding: 11px 20px 20px 20px;
//         padding-bottom: 60px;
//         .add_venue_address, .add_venue_contact{
//             padding-bottom: 62px;
//             background-color: #ffffff;
//             box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
//             .help_icon{
//                 padding-top: 44px;
//                 cursor: pointer;
//             }
//             .add_venue_address_form{
//                 @media screen and (max-width: 500px) {
//                     padding-left:20px;
//                     padding-right: 20px;
//                 }
//             }
//             .add_venue_contact_form{
//                 @media screen and (max-width: 500px) {
//                     padding-left:20px;
//                     padding-right: 20px;
//                 }
//             }
//             .learning_hall_container{
//                 border: solid 1px #e1e1e1;
//             }
//             .learning_hall{
//                 font-size: 16px;
//                 font-weight: bold;
//                 text-align: center;
//                 color: $water-blue;
//                 text-transform: capitalize;
//             }
//             .left_panel{
//                 @media screen and (min-width:1025px){
//                     padding-right: 55px;
//                 }
//                 @media screen and (min-width: 960px) and (max-width: 1025px){
//                     padding-right: 20px;
//                 }
//             }
//             .right_panel{
//                 @media screen and (min-width:1025px){
//                     padding-left: 57px;
//                 }
//                 @media screen and (min-width: 960px) and (max-width: 1025px){
//                     padding-left: 20px;
//                 }
//             }
//             .what_is_address, .what_is_contact{
//                 font-size: 16px;
//                 line-height: 1.5;
//                 text-align: left;
//                 color: $black;
//                 padding-top: 41px;
//             }
//             .address_1, .contact_name{
//                 padding-top: 20px;
//             }
//             .address_2, .contact_phone{
//                 padding-top: 85px;
//                 @media screen and (max-width: 959px) {
//                     padding-top: 20px;
//                 }
//             }
//             .city_name, .contact_email{
//                 padding-top: 20px;
//             }
//             .zipcode{
//                 padding-top: 20px;
//             }
//             .contact_job_title{
//                 padding-top: 43px;
//             }
//             .state{
//                 padding-top: 43px;
//                 @media screen and (max-width:500px){
//                     padding-top: 23px;
//                 }
//             }
//             .contact_job_title{
//                 padding-top: 20px;
//             }
//         }
//         .add_venue_address{
//             padding-bottom: 62px;
//         }
//         .add_venue_contact{
//             padding-bottom: 140px;
//         }
//     }
//     .error{
//         color: #FF0000;
//         font-size: 11px;
//         text-align: left;
//         height: 10px;
//     }
//     // end of add veneu address css
// }

.step_progress_bar{
    padding: 10px 40px;
    box-shadow: inset 0 -1px 1px 0 #e1e1e1;
    @media screen and (max-width:767px){
        padding: 10px 15px;
    }
    .mobile_view_progress_bar {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .indexedStep {
        color: white;
        width: 20px;
        height: 20px;
        font-size: 12px;
        background-color: rgba(211, 211, 211, 0.8);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .indexedStep.accomplished {
        background-image: linear-gradient(to bottom,$sky-blue, $water-blue);
    }
    .save_and_exit{
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.46px;
        color: $black;
        text-decoration: none;
        padding-top: 13px;
    }
    .add_venue_heading{
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        color: $black;
        display: flex;
        align-items: center;
    }
    
    .progress_bar{
        padding-top: 9px;
        text-align: center;
        @media screen and (max-width:767px){
            padding-top: 0px;
        }
    }
}