.calendar{
    // background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
      .button-calendar{
            display: block;
            text-align: center;
            border-bottom: 1px solid #f0f0f0;
            button{
              background-color: transparent;
              color: #ee2738;
              box-shadow: none;
              font-weight: 700;
              font-size: 18px;        
            }
            .today{
              color: #1397e1;
              min-width: 140px;
              font-family: Raleway;
              font-size: 16px;
              font-weight: bold;

            }
                      }
    .flexContainer{
        background-color: #f7f7f7;
        .weekname{
            padding: 8px 0px;
            text-transform: uppercase;
        }
        .flexColumn{
            font-family: Raleway;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #202020;
        }
        
        .day{
            background-color: #f7f7f7;
            color: #36434b;
            height: 50px;
            font-weight: normal;
            font-size: 14px;
            cursor: pointer;
            .inner-grid{
                padding-left: 0px;
                position: relative;
                padding-right: 0px;
                .date{
                    text-align: left;
                    left: 5px;
                    display: block;
                    top: 0;
                    width: 100%;
                    position: absolute;
                    z-index: 2;
                }           
                .event{
                    position: absolute;
                    width: 100%;
                    height: 10px;
                    top: 0px;
                }
                .single-event{
                    height: 100%;
                }
                .blockOut{
                    background-color: #f7f7f7 !important;
                    height: 100%;
                    cursor: default;
                
                }
                .makeAvailableClass{
                    background-color: #ffffff !important;
                    height: 100% !important;
              
                }
                .holdClassSecond{
                    top: 0px;
                }
                .holdClassSingle{
                    height: 100%;
                    background-color: #69c7ee !important
                }
                .blockClassSingle{
                    height: 100%;
                    background-color: grey !important;
                    cursor: default;
                }
                .noTimeSlotAvilable{
                    height: 100%;
                    background-color: #f7f7f7 !important;
                    pointer-events: none
                }
        
    
            }
        }
        .inactive{
            background-color: #f7f7f7;
            color: #e1e1e1;
        }
        .inner-grid{
            padding: 17px 8px;
            box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
            border-radius: 0!important;
            .event-slot{
                min-height: auto;
            }
        }
    }
                    
  }
  .widget{
    .bookClasssingle{
        background-color: red !important;
        height:100% !important;
        cursor: default;
    }
    
      .h3{
        margin-top: 105px;
        display: block;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        @media screen and (max-width:992px){
            margin-top: 130px;
        }
      }
        .calendar
        { margin: 30px 180px 10px !important;
            @media screen and (min-width:1360px) {
                margin: 30px 400px 10px !important;  
            }
            @media screen and (min-width:1024px) {
                margin: 30px 250px 10px !important;  
            }
            @media screen and (max-width:767px){
                margin: 30px 75px 10px !important;  
            }
            @media screen and (max-width:520px){
                margin:10px !important;  
            }
            .day{
                height: 80px !important;
                @media screen and (max-width:992px){
                    height: 48px !important
                }
              }
              .weekname{
                  padding: 20px 0px !important;
              }
              .headesearch_container{
                p,b{
                    word-wrap: break-word;
                }
              }
                    
              .topEventNav{
                  Button{
                    @media screen and (max-width:767px){
                       width: auto !important;
                    }
                  }
              }
        }
        .calendar_instruction{
            padding-left: 115px;
        }
        .blockClassRight {
            width: 0;
            height: 0;
            border-top: 5rem solid grey;
            border-right: 112px solid transparent;
            background: transparent !important;
        
        }      
      }
      .widget-place-hold{
        Button{
            @media screen and (max-width:767px){
               width: auto !important;
               min-width: 85px !important;
            }
        }
      }
      .widget-header{
        .topEventMenu , .topEventNav{
            margin-right: 10px !important;
            @media screen and (max-width:767px){
                margin-right: 4px !important;
             }
        }
      }
        .widget {
            .holdClassLeft{
                width: 100% !important;
                height: 100% !important;

                // @media screen and (min-width:1900px){
                //     border-left: 219px solid transparent !important;
                // }
                // @media screen and (min-width:1800px){
                //     border-left: 187px solid transparent !important;
                // }
                @media screen and (min-width:1200px){
                    // border-left: 114px solid transparent;
                    border-bottom: 80px solid #ccebf9 !important;

                }
                @media screen and (min-width:1300px){
                    // border-left: 123px solid transparent;
                    border-bottom: 80px solid #ccebf9 !important;

                }
                // @media screen and (min-width:1400px){
                //     border-left: 145px solid transparent;
                // }
                // @media screen and (min-width:1650px){
                //     border-left: 172px solid transparent;
                // }

                @media screen and (max-width:1199px){
                    // border-left: 81px solid transparent;
                    border-bottom: 80px solid #ccebf9 !important;       //right
                }
                @media screen and (max-width:1024px){
                    // border-left: 81px solid transparent;
                    border-bottom: 80px solid #ccebf9 !important;       //right
                }
                @media screen and (max-width:991px){
                    border-left: 30px solid transparent !important;
                    border-bottom: 51px solid #ccebf9 !important; 
                }
                @media screen and (max-width:860px){
                    // border-left: 58px solid transparent;
                    border-bottom: 51px solid #ccebf9 !important;          //right
                }
                // @media screen and (max-width:610px){
                //     border-left: 65px solid transparent;
                // }
                // @media screen and (max-width:575px){
                //     border-left: 50px solid transparent;
                // }
                // @media screen and (max-width:460px){
                //     border-left: 33px solid transparent;
                // }
            }
            .blockClassLeft{
                width: 100%;
                height: 100%;
                @media screen and (min-width:1300px){
                    border-bottom: 80px solid grey !important;
                }
                @media screen and (max-width:1300px){
                    border-bottom: 80px solid grey !important;
                }
                @media screen and (max-width:991px){
                    border-bottom: 50px solid grey !important;
                    border-left: 20px solid transparent !important;
                }
            }
            .blockClassRight {
                width: 100%;
                height: 100%;
                @media screen and (max-width:1300px){
                    border-top: 5rem solid grey;
                    border-right: 50px solid transparent !important;
                    background: transparent !important;
                }
                @media screen and (max-width:991px){
                    border-top: 3.1rem solid grey;
                    border-right: 50px solid transparent !important;
                    background: transparent !important;
                
                }
                @media screen and (max-width:991px){
                    border-right: 30px solid transparent !important;
                } 
            }
     
        }
    .widget{
        .holdClassRight{
            width: 100% !important;
            height: 100% !important;

            // @media screen and (min-width:1920px) {
            //     border-right: 220px solid transparent;
            // }
            // @media screen and (min-width:1800px) {
            //     border-right: 190px solid transparent;
            // }
            // @media screen and (min-width:1700px) {
            //     border-right: 190px solid transparent;
            // }
            // @media screen and (min-width:1600px){
            //     border-right: 205px solid transparent;
            // }
            // @media screen and (min-width:1500px) {
            //     border-right: 150px solid transparent;
            // }
            @media screen and (min-width:1399px){
                border-right: 100px solid transparent !important;
                border-top: 80px solid #69c7ee;
            }
            // @media screen and (min-width:1399px){
            //     border-right: 176px solid transparent;
            //     border-top: 80px solid #69c7ee;
            // }
            @media screen and (min-width:1200px){
                // border-right: 123px solid transparent;
                border-top: 80px solid #69c7ee;
            }
            @media screen and (max-width:1199px){
                // border-right: 81px solid transparent;
                border-top: 80px solid #69c7ee;       //right
            }
            @media screen and (max-width:1024px){
                // border-right: 81px solid transparent;
                border-top: 80px solid #69c7ee;       //right
            }
            @media screen and (max-width:991px){
                border-right: 50px solid transparent;
            }
            @media screen and (max-width:991px){
                // border-right: 58px solid transparent;
                border-top: 51px solid #69c7ee !important;          //right
            }
            @media screen and (max-width:610px){
                border-right: 65px solid transparent;
            }
            @media screen and (max-width:575px){
                border-right: 50px solid transparent;
            }
            @media screen and (max-width:460px){
                border-right: 33px solid transparent;
            }
        }
    }
      
// 3 and 4 slot ui in widget calender
.blockClassRight,
.holdClassRight{
    clip-path: polygon(100% 0, 0 0, 0 100%);
}
.blockClassLeft,
.holdClassLeft{
    clip-path: polygon(100% 0, 0 100%, 100% 99%);
}
.widget{
    @media screen and (min-width:1400px){
        .blockClassLeft {
            border-bottom: 80px solid grey !important;
        }
    }
    .holdClass3,
    .blockClass3 {
        height: 28px !important;
        top: 0px !important;
    }
    .holdClassOne3,
    .blockClassOne3 {
        top: 26px !important;
        height: 28px !important;
    }
    .holdClassTwo3,
    .blockClassTwo3 {
        top: 54px !important;
        height: 27px !important;
    }
    .holdClass,
    .blockClass {
        height: 20px !important;
        top: 0px !important;
    }
    .holdClassOne4,
    .blockClassOne4 {
        top: 20px !important;
        height: 20px !important;
    }
    .holdClassTwo4,
    .blockClassTwo4 {
        top: 40px !important;
        height: 20px !important;
    }
    .holdClassThree4,
    .blockClassThree4 {
        top: 60px !important;
        height: 20px !important;
    }
    @media screen and (max-width:991px){
        .holdClass3,
        .blockClass3 {
            height: 16px !important;
            top: 0px !important;
        }
        .holdClassOne3,
        .blockClassOne3 {
            top: 16px !important;
            height: 16px !important;
        }
        .holdClassTwo3,
        .blockClassTwo3 {
            top: 34px !important;
            height: 16px !important;
        }
        .holdClass,
        .blockClass {
            height: 12px !important;
            top: 0px !important;
        }
        .holdClassOne4,
        .blockClassOne4 {
            top: 12px !important;
            height: 12px !important;
        }
        .holdClassTwo4,
        .blockClassTwo4 {
            top: 24px !important;
            height: 12px !important;
        }
        .holdClassThree4,
        .blockClassThree4 {
            top: 36px !important;
            height: 12px !important;
        }
    }
}
  
@media screen and (max-width:520px){
    
    .MuiDialog-paper-209,
    .jss123.jss149.jss124.jss258.jss286.jss259.jss262 {
        margin: 10px !important;
        max-width: 100% !important;
    }
    .dialog-container .login-container{
        input#phone {
            max-width: 230px !important;
        }
        .button-box {
            max-width: 200px !important;
            margin: 0 auto;
            margin-top: 20px !important;
        }
    } 
}