$dark-grey: #62717a;
$light-grey: #9aa7af;
$white: #ffffff;
$off-white:#f0f0f0;
$water-blue:#1397e1;
$off-black:#36434b;
$greyish-white: #f7f7f7;

.browse_venue_page{
    background-color: $greyish-white;
    a:hover{
        text-decoration: none;
    }
    a{
        text-decoration: none;
    }
    margin-top: 62px;
    @media screen and (max-width:767px){
        margin-top:49px;
    }
    // overflow-x: hidden;
    .right_panel{
        background-color: $greyish-white;
        height: 100%;
        min-height: auto;
    }
    
    .browse_venue_search{
        background-color: $white;
        padding-bottom: 30px;
        @media screen and (max-width:768px){
            padding-bottom: 17px;
        }
         .search{
            padding-top: 17px;
            @media screen and (max-width:768px){
                // padding-bottom: 27px;
                width: 100%;
                margin: 0;
            }
            .starting-address{
                font-size: 11px;
                font-weight: 500;
                line-height: 1.45;
                text-align: left;
                color: $dark-grey;
                padding-bottom: 7px;
            }
            .start-search{
                div:nth-child(1){
                    width: 100%;
                    
                    
                #states-autocomplete{
                    position: relative;
                    width: 100%;
                    height: 36px;
                    font-size: 14px;
                    border: solid 1px #e1e1e1;
                    padding: 0px 15px;
                    background-color: #ffffff;
                    border-radius: 6px;
                    line-height: 30px;
                    z-index: 3;
                }
            }
            .suggestionDiv {
                width: 100% !important;
                position: relative;
                font-size: 12px;
                line-height: 2.3;
                word-break: break-word;
                border: solid 1px #e1e1e1;
                margin-top: -3px;
                div{
                    border: 0px;
                    border-radius: 0px;
                    padding: 0px 15px;
                    cursor: pointer;
                }
              
              }
            }
            .start-search input[role="combobox"] + div {
                z-index: 9999;
                top: 45px !important;
                left: 0px !important;
                position: absolute !important;
                width: 100% !important;
                min-width: 80% !important;
                min-height: 144px !important;
                background: #fff;
                overflow-y: scroll;
                height: 130px;
                box-shadow: 0px 15px 10px -15px #ccc;
            }
            .address{
                width: 100%;
                height: 36px;
                border-radius: 6px;
                border: solid 1px $off-white;
                background-color: $white;
                font-family: Roboto;
                font-size: 14px;
                line-height: 1.71;
                text-align: left;
                color: #36434b;
                padding-left: 12px;
                padding-right: 12px;
                @media screen and (max-width: 320px){
                    width:100%;
                }
                &::placeholder{
                    color: #b0b0b0;
                }
                @media screen and (min-width:376px) and (max-width: 414px){
                    width: 100%;
                    
                }
                @media screen and (min-width:785px) and (max-width:1024px){
                    width:100%;
                     
                }

            }
            .save_search{
                height: 36px;
            }
            .clearfilter{
                height: 36px;
                @media screen and (min-width:320px){
                    margin-left:10px;
                     
                }
            }

            .to{
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #36434b;
            }

            .fieldJustification{
                padding-bottom: 5px;
                padding-top: 5px;
            }
            .dateTimeFieldJustification{
                padding-bottom: 7px;
                padding-top: 7px;
@media screen  and (max-width:767px){
    .time_select{
        >div{
            padding: 0 10px;
        }
        svg{
            right: -7px;
        }
    }
}
            }
        }
    }
    .browse_venue_right_panel{
        margin: 0 15px;
        @media screen and (min-width:1024px){
            padding-left: 23px;   
        }
        .pick_a_category,.venue_heading {
            font-size: 16px;
            font-weight: bold;
            text-align: left;
            color: #36434b;
            margin: 20px auto;
            padding-left: 0px;
            @media screen and (max-width:768px){
                text-align: center;
                padding-left: 0px;
                margin: 20px auto 0px;
            }
        }
        .venues{
            @media screen and (max-width: 768px) {
                justify-content: center;
                width: 100%;
                margin: 0px;
            }
            .end .react-datepicker-popper {
                    left: auto !important;
                    right: 0px !important;
            }
        }
        .venue_card{
            padding: 10px 20px;
            :hover .big_image {
                box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7);
            }
        }
    }
    .venue {
        .venue_image {
            width: 266px !important;
        }
    }
    .sign-up1{
        width: 100% !important;
      }
    
}
.react-datepicker-popper{
    z-index: 9999 !important;
}
.save_search button[disabled]{
    background-color: #bfbfbf !important;
}
.browse_venue_search .custom-select{
    background-color: #ffffff !important;
}
.sign-up1{
    @media screen and (max-width:767px) {
        width:100% !important;
        min-width: 100%;
    }
}

@media screen and (max-width:405px) {
    .save_search {
        margin-bottom: 10px !important;
        text-align: center;
    }
    .save_search.clearfilter {
        margin: 0 !important;
        text-align: center;
        padding: 0 10px;
    }
}
