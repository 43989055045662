::-webkit-scrollbar {
    width: 5px !important;
}

.MuiDialog-paperWidthSm-226 {
  max-width: 100% !important;
  width: 100% !important;
  max-height: 100% !important;
}

.MuiPaper-elevation24-146 {
  box-shadow: none !important;
}

.MuiBackdrop-root-308 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.MuiDialog-paper-235 {
   display: flex;
  position: relative;
  overflow-y: auto;
  flex-direction: column;
}
.MuiPaper-root-120 {
  background: none !important;
}
.contact_modal{
  overflow-y: scroll !important;
}
.dialog-content{
  overflow-y: scroll !important;
}
.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
}

.datepiker {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: rgb(98, 113, 122);}

.dialogActions {
  height: 40px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  position: absolute;
  right: 0;
  span {
    cursor: pointer !important;
  }
}

.closeDialog {
  width: 22px;
  height: 22px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

.dialog-content {
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0;
}

.modalBox_content{
  height: 100%;
  overflow: hidden;
}

.main_place_hold_section {
  width: 600px;
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 2px solid white !important;
  height: 100%;
}

.header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.contact_modal_container {
  // overflow-x: auto !important;
  width: 100% !important;
  min-height: auto !important;

  .tell-us-about-your-event{
    @media(max-width:767px){
     margin-bottom: 0px !important;
    }
    .form-content{
      max-height: 377px !important;
      @media(max-width:767px){
        max-height: 370px !important;
       }
    }
    .datepiker{
      padding: 0px 10px;
      font-size: 12px !important;
      height: 36px;
      border-radius: 6px;
      background-color: transparent;
      border: solid 1px #e1e1e1;
    }

    .text-area{
      height: auto !important;
    }

    .select-down-icon::before{
      content: 'arrow_drop_down';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 0.8;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      top: 8px;
      right: 5px;
      color: #62717a;
    }

    .select-down-icon{
      position: relative;
    }
    .input-field-section{
      position: relative;
    }

    .custom_select_with_scroll{
      >div{
        padding: 0px 10px;
      }
    }
        .react-datepicker{
          button{
            background-color: transparent;
          }
        }
        .form {
          .input-field{
            font-size: 14px;
            background-color: transparent;
          }
        }
        textarea{
          font-size: 14px;
        }
        .input-label{
          padding-bottom: 0 !important;
        }
        .field-div {
          position: relative;
          margin-bottom: 5px;
        }
        .align-send-button{
          @media screen and (max-width:767px){
            text-align: center !important;
          }
          button{
            @media screen and (max-width:767px){
              float: none;
              margin-top: 10px;
            }
          }
        }
        .error{
          position: absolute;
          display: block;
          bottom: -15px;
          @media screen and (max-width:767px){
            bottom:-18px;
          }
        }
        // .select-down-icon{
        //   .error{
        //     bottom: -15px;
        //   }
        // }
        .form-content{
          margin: 5px 0px 15px;
          padding: 10px 15px !important;
        }
  }


  .react-datepicker__navigation {
    padding: 0 !important;
  }

  .MuiDialog-paperWidthSm-226 {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
  }

  .MuiPaper-elevation24-146 {
    box-shadow: none !important;
  }

  .MuiBackdrop-root-308 {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .MuiPaper-root-120 {
    background: none !important;
  }

  .MuiDialog-paper-222 {
    margin: 0 !important;
    position: absolute;
    top: 0;
  }

  .dialogActions {
    height: 40px;
    font-family: material;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
  }

  .closeButton {
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    top: -70px;
    right: -38px;
    color: #ffffff;
    font-weight: bold;
    .closeButton:hover {
      color: #ee2738;
  }
  }

  .dialog-content {
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 0;
    
  }

  .modalBox_content{
    height: 100%;
    overflow: hidden;
  }

  .main_place_hold_section {
    width: 600px;
    border-radius: 6px;
    background-color: #f0f0f0;
    border: 2px solid white !important;
    height: 100%;
  }

  .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px;
  }

  .header-button-div {
    float: right;
  }
  .align-send-button{
    float: right;
    width: 105px;
  }
  .uploader {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-image: url('../images/perm_contact_calendar.svg');
    background-repeat: no-repeat;
    background-size:cover;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: #f0f0f0;
  }

  .contact_modal_container {
    min-height: 100px;
  }

  .uploader-content {
    width: 300px;
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto;
  }

  .red-text-content {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin: 140px 0 5px 0;
  }

  .black-text-content {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin-top: 5px;
  }

  .white-button-big {
    // height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    // float: left;
    cursor: pointer;

    span {
      width: 100%;
    }
  }

  .select_field {
    padding: 8px 12px !important;
    border-radius: 6px;
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020 !important;
    text-transform: none !important;
  }

  /* create new event form */

  .form {

    .form-content {
      padding: 20px;
      max-height: 350px;
      overflow: auto !important;
    }

    .header {
      width: 100%;
      height: 60px;
      background-color: #ffffff;
      margin-top: 0;
      padding: 15px;
      border-bottom-style: solid !important;
      border-bottom-width: 1px !important;
      border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
      border-image-slice: 1;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }

    .header-text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      float: left;
      margin-top: 5px;
    }

    .header-button-div-form {
      float: right;
      width: 210px;
    }

    .dialogActions {
      height: 120px;
      font-family: material;
      font-size: 22px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      padding: 20px;
      margin: 0;
      display: block;
      position: absolute;
    right: 0;

      span {
        cursor: pointer;
      }
    }

    .field-div {
      width: 100%;
      height: 70px;
      float: left;
    }

    .input-label {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.45;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
      width: 100%;
      float: left;
      padding-bottom: 5px;
    }

    .input-field {
      width: 100%;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #f7f7f7;
      float: left;
      margin: 0 !important;
      font-size: 16px;
      font-family: Raleway;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      padding-left: 10px;
    }

    .input-field-section {
      width: 100%;
      float: left;
    }

    .big {
      width: 100% !important;
    }

    .dark-black {
      color: #36434b !important;
    }

    .light-black {
      color: #202020 !important;
    }

    .lighter-black {
      color: #62717a !important;
    }

    .small {
      width: 46% !important;

      select {
        font-size: 15px !important;
      }

      input {
        font-size: 15px;
      }
    }

    .smaller {
      width: 40% !important;

      select {
        font-size: 12px !important;
        background-color: #ffffff !important;
        text-transform: uppercase !important;
      }

      input {
        font-size: 12px;
        color: #1397e1 !important;
        text-align: left;
        height: 36px;
        border-radius: 6px;
        border: solid 1px #f0f0f0;
        background-color: #ffffff;
      }
    }

    .tall {
      height: 140px;
    }

    .spacer {
      width: 20%;
      padding: 25px 0 0 0;
      overflow: hidden;
      text-align: center;
    }

    select.select-field {
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #f7f7f7;
      text-transform: none;
      padding: 5px;
      -webkit-appearance: menulist;
      text-align: left;
      padding-left: 5px;
      width: 100%;
      font-family: Raleway;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }

    .float-right {
      float: right;
    }

    .white-button-small {
      width: 100px !important;
      height: 30px;
      border-radius: 6px;
      border: solid 1px #e1e1e1 !important;
      background-color: #ffffff;
      padding: 0 !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      text-align: center;
      color: #ee2738 !important;
      float: left;
      cursor: pointer;

      span {
        width: 100%;
      }
    }

    .red-button-small {
      width: 100px;
      height: 30px;
      border-radius: 6px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      float: right;
      cursor: pointer;
    }

    @media screen and (max-device-width:500px){
      .modalBox_content{
        height: 100%;
        display: flex;
        overflow: hidden;
      }

      .newEventPopupContainer {
        width: 100% !important;
      }

      .small {
        width: 100% !important;
      }

      .header {
        height: 45px;
        padding: 0;
      }

      .header-text {
        width: 100%;
        text-align: center;
      }

      .header-button-div-form {
        width: 100%;
        margin: 10px auto;
        padding: 0 0px;
      }

      .uploader-content {
        margin-top: 20px;
        height: 100%;
        min-height: 250px;
        display: grid;
      }

      .red-text-content {
        margin-top: 100px;
      }

      .MuiDialog-paperWidthSm-226 {
        max-width: 100% !important;
        width: 100% !important;
      }
    }

  }

  /* Events */

  .eventDiv {
    width: 98%;
    height: 80px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    margin: 15px auto 0px;
    padding: 20px 10px;

      .blue-text {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #1397e1;
        margin: 0;
      }
  }

  .events-content {
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto;
  }

  .checkBoxDiv {
    width: 5%;
    float: left;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .detailsDiv {
    width: 95%;
    float: left;
    display: flex;
    height: 100%;

    .actionDiv {
      width: 20%;
      float: left;
      display: flex;
      height: 100%;
      align-items: center;

      .hand-icon {
        width: 9.5px;
        height: 19.7px;
        background-color: #62717a;
        margin-right: 10px;
      }

      .grey-text {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #62717a;
        margin: 0;
      }
    }
  }

  .checkBox {
    width: 20px;
    height: 20px;
    background-color: #9aa7af !important;
  }

  .large-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    width: 80%;
  }

  .eventsSelected {
    width: 100%;
    height: 380px;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 30px;

    .footerButtonDiv {
      width: 45% !important;
      margin: 20px auto;
    }

    .grey-button:disabled {
      height: 36px;
      border-radius: 6px;
      background-color: #9aa7af !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: none;
    }

    .grey-button:enabled {
      height: 36px;
      border-radius: 6px;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    }

    .image {
      width: 38px;
      height: 38px;
      object-fit: contain;
      display: inline-block !important;
    }

    .large-text {
      font-family: Raleway;
      font-size: 24px !important;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 10px auto;
    }

    .description {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #202020;
      margin: auto;
    }

    .blue-text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1397e1;
      margin: 15px auto 0 auto;
    }

    .grey-text {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9aa7af;
      margin: 20px auto 10px auto;
      text-transform: uppercase;
    }

    .optionsDiv {
      width: 90%;
      margin: auto;
      display: block;
      overflow: auto;

      .option {
        width: 33%;
        float: left;
        text-align: center;
      }

      .option-text {
        width: 60%;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #62717a;
        margin: auto;
      }

      .grey {
        color: #62717a !important;
      }

      .blue {
        color: #1f9ce3 !important;
      }

      .blue-circle {
        width: 20px;
        height: 20px;
        border: solid 2px #1397e1;
        background-color: #ffffff;
        margin-top: 30px;
      }
    }
  }

  .dialogFooter {
    height: 50px;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 0 var(--white);
    background-color: #f7f7f7;
    display: flex;
    align-items: center;

    .buttonDiv {
      width: 20%;
      margin: auto;
    }
  }

  .red-button {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer;
  }

  @media screen and (max-width:500px){

    .dialog-content {
      height: 100%;
      width: 90% !important;
      margin: auto;
    }

    .modalBox_content{
      height: 100%;
      overflow: hidden;
    }

    .header {
      height: 80x;
      margin: auto;
    }

    .header-text {
      width: 100%;
      text-align: center;
    }

    .header-button-div {
      width: 100%;
      text-align: center;
      margin: 10px 0;
    }

    .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid;
    }

    .red-text-content {
      margin-top: 100px;
    }

    .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important;
    }

    .eventDiv {
      margin: 0;
      padding: 10px;
      height: 100px;
    }

    .checkBoxDiv {
      width: 10% !important;
    }

    .detailsDiv {
      width: 90% !important;
      position: relative;
     }

    .large-text {
      width: 100% !important;
    }

    .actionDiv {
      width: 100% !important;
      height: 70% !important;
    }

    .eventsSelected {
      height: auto;
    }

    .optionsDiv {
      margin: 0 !important;
      width: 100% !important;
    }

    .option {
      width: 80% !important;
      float: left;
      align-items: center;
      display: flex;
      margin: auto;

      .option-text {
        float: left;
        text-align: left !important;
      }

      input {
        float: left;
        margin: 0 !important;
      }
    }

    .footerButtonDiv {
      width: 130px !important;
      margin: 20px auto;
    }

    .main_place_hold_section {
      margin: 5px auto 25px;
      .form-content {
        padding: 0px 0px;
        textarea{
          @media screen and (max-width:767px){
            font-size:13px;
          }
        }
    }
    .date-picker{
      font-size: 12px;
      color: #1397e1 !important;
      text-align: left;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
    }
    .error{
      position: absolute;
    }
    }
  }
}
.input-label{
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a;
  width: 100%;
  float: left;
  padding-bottom: 5px;
}
.form-content{
  padding: 20px;
}
.contact_modal_container button {
  padding: 6px 30px;
      padding-left: 30px;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  padding-left: 30px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: #ee2738;
  // margin-bottom: 20px;
  // margin-top: 20px;
}
.textarea{
  width: 100%;
   border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #f7f7f7;
}
.managerDiv .MuiButton-outlined-207 {
 
  color: #ee2738 !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  padding: 8px 15px;
}
@media screen and (max-device-width:1024px){
  .header-text{
    font-size: 13px !important;
  }
}
@media(max-width:767px){
  .start-date .react-datepicker-popper{
    left: 0px !important; 
  }
  .end-date .react-datepicker-popper{
    right: 0px !important;
    left: auto !important;
  }
  .end-date .react-datepicker__triangle{
    left: auto !important;
    right: 0px;
  }
  .start .react-datepicker__triangle{
    left: 20px !important;
  }
}
.contact_modal_container{
  .dialog-content{
  @media(max-width:767px){
    width: 100% !important;
  }}
}

// .contact_modal_container > div > div {
//   overflow-y: scroll !important;
//   overflow-x: hidden !important;
// }


.contact_modal_container .eventsSelected .grey-button {
  margin-top: 30px !important;
}