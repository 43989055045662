.venue_image_upload{
    border: dashed 1px #e1e1e1;
    background-color: #f0f0f0;
    width: 100%;
    text-align: center;
    overflow: hidden;
    .venue_image{
        margin: auto;
        display: flex;
        // object-fit: cover;
        // height: 250px !important;
        object-position: center;
        width: 100% !important;
    }
    .image_upload{
        padding-top: 86px;
    }
    .file_type_field{
        display: none;
    }
    .file_name{
        text-align: center;
        padding-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
        color: #36434b;
    }
    .button_on_image{
        width: 100%;
        height: 300px;
        margin-top: -315px;
        cursor: pointer;
        padding-left: 35%;
        // display: none;
        padding-top: 143px;
        position: absolute;
        // z-index: 10;
        // background-color: #ff0000;
    }
    // .button_on_image:hover + .button_on_image_div{
    //     opacity: 1.0;
    // }
    .button_on_image_div{
        opacity: 0.0;
        -webkit-transition: all 500ms ease-in-out;
        -moz-transition: all 500ms ease-in-out;
        -ms-transition: all 500ms ease-in-out;
        -o-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
        // margin-top: -170px;
        // margin-left: 35%;
        width: 100%;
        height: 300px;
        // margin-top: -315px;
        cursor: pointer;
        // padding-left: 35%;
        // display: none;
        // padding-top: 143px;
        position: absolute;
        top: 43%;
        @media screen and (max-width:767px){
            top: 40%;
            opacity: 1 !important;
        }
            
        button{
            @media screen and (max-width:767px) {
                height: 30px !important;
                padding: 0px 15px;
                width: auto !important;
            }
        }
    }
    .button_on_image_div:hover{
        opacity: 1.0;
    }
}
@media screen and (max-width:767px){
    .venue_image_upload .file_name button {
        width: auto;
        padding: 5px 10px;
    }
}