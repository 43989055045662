.divider{
  opacity: 0.1;
}
.aboutUs{
  p{
    margin: 1rem 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  h3{
    font-size: 16px;
  }
}