@import "react-alice-carousel/lib/scss/alice-carousel.scss";
span{
    font-family:'Raleway',sans-serif;
}
#nprogress .spinner {
  z-index: 99999999999999 !important;
}
#nprogress .bar {
  z-index: 99999999999999 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
