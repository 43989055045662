$water-blue: #1397e1;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;
$off-white: #f7f7f7;

.contact-dialog-container {
    padding: 20px;
    p {
        width: 100%;
    }
    .heading-1 {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
        color: $water-blue;
        text-transform: uppercase;
    }
    .heading-2 {
        margin-top: 8px;
        font-size: 16px;
        text-align: center;
    }
    .left-container{
        padding-right: 8px;
        @media screen and (max-width:800px) {
            padding-right: 0;
        }
    }
    .bottomSpacing{
        margin-top: 8px;
        
    }
    .button-box {
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .contact-btn {
            background-color: #ee2738;
            font-weight: 600;
        }
    }
    .select_field{
      padding:8px 12px !important;
      border-radius: 6px;
      border: solid 1px $off-white;
      background-color: #f7f7f7 !important;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.67;
      text-align: left;
      text-transform: capitalize;
        width: 100%;
      option {
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.67;
          letter-spacing: normal;
          color: #62717a;
          border: 0;
          border: 0;
          padding: 8px !important;
          min-height: 50px !important;
          height: 30px !important;
          padding: 0px 12px;
        }  
   }
   .inputField{
    width: 100%;
     font-size: 12px;
     &::-webkit-input-placeholder {
        font-size: 12px;
        line-height: 1.67
    }
   }
   .error{
       width: auto;
   }
  
   .label{
     font-size: 12px;
     padding:4px 0 0 0 !important;
   }
   @media screen and (max-width:620px) {
        .select_field,
        .inputField{
            width: 320px;
        }
    }
    @media screen and (max-width:450px) {
        // overflow-x: hidden;
        .select_field,
        .inputField{
            width: 200px;
        }
        .bottomSpacing{
            iframe {
                width: 210px;
                border: 1px solid #d3d3d3;
            }
        }
        .bottomSpacing > div > div > div {
            width: 210px !important;
            height: 100px !important;
        }
        .rc-anchor {
            width: 200px !important;
        }
    }
    @media screen and (max-width:340px) {
        // overflow-x: hidden;
        .select_field,
        .inputField{
            max-width: 160px;
            width: 100%;
        }
        .bottomSpacing{
            iframe {
                width: 160px;
                border: 1px solid #d3d3d3;
            }
        }
        .bottomSpacing > div > div > div {
            width: 160px !important;
            height: 100px !important;
        }
        .rc-anchor {
            width: 200px !important;
        }
    }
}

