.payment {
    width: 60%;
    margin: 60px auto auto;
    @media screen and (max-width:767px) {
        width: 100%;
        padding: 0 20px;
    }

    * {
        box-sizing: border-box;
    }

    body,
    html {
        background-color: #f6f9fc;
        font-size: 18px;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    }

    h1 {
        color: #32325d;
        font-weight: 400;
        line-height: 50px;
        font-size: 40px;
        margin: 20px 0;
        padding: 0;
    }

    .Checkout {
        margin: 0 auto;
        max-width: 800px;
        box-sizing: border-box;
        padding: 0 5px;
    }

    label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
    }

    button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #6772e5;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
    }

    form {
        margin-bottom: 40px;
    }
    button:hover {
        color: #fff;
        cursor: pointer;
        background-color: #7795f8;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
    }

    .StripeElement,
    input {
        display: block;
        margin: 10px 0 20px;
        max-width: 100%;
        padding: 10px 14px;
        font-size: 1em;
        font-family: 'Source Code Pro', monospace;
        box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px, rgba(0, 0, 0, 0.0196078) 0 1px 0;
        border: 0;
        outline: 0;
        border-radius: 4px;
        background: white;
    }

    input::placeholder {
        color: #b0b0b0;
    }
    .StripeElement--focus,
    input:focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0 4px 6px, rgba(0, 0, 0, 0.0784314) 0 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.PaymentRequestButton {
        padding: 0;
    }

    .StripeElement {
        background-color: #f7f7f7;
        padding: 20px 15px;
        margin: 20px 0;
        border-bottom: 3px solid #e6ebf1;
    }

    .payment-process-text {
        display: block;

        svg {
            max-width: 16px;
            float: left;
            padding-top: 5px;
        }

        p {
            display: inline-block;
            padding-left: 5px;
            @media screen and (max-width:767px) {
                font-size: 13px;
            }

            span {
                font-size: 20px;
                font-weight: bold;
                color: #6671e5;
            }
        }
    }

    .inputlabel {
        display: inline-block;
        padding: 5px 15px 10px;
        background-color: #f7f7f7;
        width: 100%;
        border-bottom: 3px solid #e6ebf1;

        label {
            color: #202020;
            font-weight: 500;
        }

        input {
            width: 100%;
            box-shadow: none;
            padding-left: 0;
            padding-top: 3px;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;
            background-color: #f7f7f7;
            font-size: 16px;
            ::placeholder {
                color: #202020 !important;
            }
        }
    }

    .credit-card {
        padding: 15px 10px;
        border-radius: 0;
        border: 2px solid #1397e1;
        background-color: #1397e114 !important;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;

        .circle {
            display: block;
            width: 25px;
            height: 25px;
            background-color: #fff;
            border-radius: 50%;

            .small-circle {
                display: block;
                width: 15px;
                height: 15px;
                background-color: #1397e1;
                border-radius: 50%;
                margin: 5px;
            }
        }

        div {
            div {
                background-color: transparent;
                text-align: center;
            }
        }

        svg {
            width: 59px;
        }
    }

    .coupon-applied{
        padding: 4px 4px 0px 0px;
        margin-bottom: 25px;
        p{
            font-weight: 500;
            font-size: 14px;
            color: red;
            
            span{
                color:#ee2738;
                font-weight: 600;
            }
        }
    }

    .coupon-card {
        margin-top: 12px;
        border: 1px solid #949494;
        background-color: #f7f7f7 !important;
        display: flex;
        align-items:flex-end;
        justify-content: flex-end;
        .lable{
          padding: 8px 8px 12px 8px;
          img {
            float: left;
            width: 32px;
          }
          p {
            font-weight: 600;
            font-size: 16px;
            padding: 4px;
            float: left;
           
          }
        }
        .apply-box{
          float: right;
          padding: 8px;
          button{
            background-color: #fff;
            border: 1px solid #ee2738;
            color: #ee2738;
            float: inherit;
            margin-top: 0;
            width: 124px;
            font-size: 14px;
          }
        }     
      
    }

    .pay-btn {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width:767px) {}

        .pay-now {
            background-color: #ee2738;
            height: 50px;
            padding: 0 120px;
            text-transform: capitalize;
            border-radius: 0;
            @media screen and (max-width:767px) {
                padding: 0 30px;
                height: auto;
            }
        }

        .cancel {
            background-color: transparent;
            border: 1px solid #ee2738;
            color: #ee2738;
            text-transform: capitalize;
            border-radius: 0;

            a {
                color: #ee2738;
            }
        }

    }

    .payment-heading {
        display: block;
        padding: 10px 0;
        font-weight: bold;
        font-size: 18px;
    }
}

.tier-info {
    padding: 10px 0;
    color: #20b2ea;
    font-weight: bold;
}
.navbar-link:not(.is-arrowless)::after,
.select:not(.is-multiple):not(.is-loading)::after {
    display: none !important;
}

.plan-information,
.checkbox-text-alignment label .text-color{
    padding: 10px 0;
    font-size: 14px;
    font-weight: 500;
    color: #202020;
}
.plan-information{
    span {
        color: #ee2738;
    }
}
.checkbox-text-alignment{
    .checkboxLabel{
        align-items: flex-start;
    }
    .checkboxLabel > *:first-child{
        margin-top: -8px;
    }
}
button:disabled { cursor: not-allowed !important; }
