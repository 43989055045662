$white:#ffffff;
$red: #ee2738;

.matter {
  font-family: Raleway !important;
}

.venue-name{
  font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    word-break: break-word;
    color: #ffffff;
}

.venue_page {
  overflow-x: hidden;
  margin-top: 62px;
  @media screen and (max-device-width:500px) {
    margin-top: 66.5px;
  }

  @media screen and (min-device-width:501px) and (max-device-width:1023px) {
    margin-top: 74px;

  }
.h{
  display: none;
}
  .buttons-header {
    padding: 20px 30px 20px;
    .title{
      margin-bottom: 0px;
      @media screen and (max-width:767px){
        display: none;
      }
    }
}
  .space-planner-edit-outer-grid {
    .bordered{
      @media screen and (max-width:767px){
          padding: 5px;      
          overflow: hidden;
        }
      ul{
        li{
          overflow: visible;
          padding-left: 5px !important;
          padding-right: 5px !important;
          justify-content:space-between;
          display: flex;
          // @media screen and (max-width:767px){
          //   display: flex;
          // }
          .delete {
           background-color: transparent;
          }
          .text{
            @media screen and (max-width:991px){
              width:65%;
            }
            @media screen and (max-width:767px){
              width:70%;
            }
          }
        }
      }
    }
  }
   
  .right-event-placehold{
    @media screen and (max-width:767px){
          padding-top: 15px;
    }    
  }

  .venue_details_image{
    position: relative;
  }

  .aboutHead {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
  }

  .aboutMatter {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    word-break: break-word;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
  }

  .EventPlannerHeader-appbar-106 {
    color: #36434b;
    box-shadow: inset 0 -1px 0 0 #fff !important;
    background-color: #ffffff !important;
  }

  .MuiTypography-body1-208 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
  }

  .MuiTypography-h4-214 {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
  }

  .leftPanel {
    width: 80%;

    .topImage {
      border: #000 1px solid;
      height: 394px;
    }
  }

  .rightPanel {
    width: 20%;

    .rightMap {
      border: #000 1px solid;
      height: 394px;
    }
  }

  .midContainer {
    // width: 97%;
    margin: 10px 25px 25px 25px;
    .justify{
      @media screen and (max-width:767px){
        justify-content: space-between;
        }    
    }

    .venue-available {
      width: 100%;
      font-family: Raleway !important;
      .mr-2{
        padding-right: 25px;
      }


      .avilableDescriptionDiv {
        width: 80%;

        .avilableDescription {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #62717a;
          padding-top: 15px;
        }

      }

      .dayTimeDiv {
        width: 100%;
        color: #202020;

        .mondFriDiv {
          width: 13%;
          float: left;
        }

        .satSunfDiv {
          width: 26%;
          float: left;
        }

      }

      .availabilitytext {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.12;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
      }
    }

  }

  .spaceVanueDiv {
    width: 100%;
    min-height: 385px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;

    // .venue {
    //   width: 48%;
    //   height: auto;


    //   .venue_image {
    //     width: 100%;
    //     min-width: 150px;
    //   }

    //   .venue_content {
    //     margin-left: 3px;
    //     margin-top: -34px;
    //     margin-bottom: 49px;
    //   }

    //   .venue_name {
    //     font-size: 12px;
    //     font-weight: 500;
    //     font-style: normal;
    //     font-stretch: normal;
    //     line-height: 2;
    //     letter-spacing: normal;
    //     text-align: left;
    //     color: #202020;

    //   }

    //   .VenueCardWithFav-activeFav-363 {
    //     font-size: 24px;
    //     text-shadow: 0px 0px #ff0000;

    //     .active_icon_over_image {
    //       left: -2px !important;
    //     }

    //     .active_icon_over_image {
    //       top: -118px;
    //       position: relative !important;
    //     }
    //   }

    // }
  }

  .similarDiv {
    width: 100%;

    .similarDiv .active_icon_over_image {
      left: -44px;
    }

    .similarDiv .active_icon_over_image {
      color: red;
      position: absolute;
      width: 100%;
      top: 2px;
    }

    .similarContainer {
      width: 100%;
      padding: 10px;

      .similerText {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
        padding-bottom: 10px;
        @media screen and (max-width:767px){
          padding-bottom:0px;
        }
      }
    }

  }

  .what-we-love-block{
    padding-right: 25px;
    margin-top: 25px;
    @media screen and (max-width:767px){
      margin: 15px auto 10px;
    }
    .what-we-love-listing{
      display: block;
      margin-left: 8px;
      width: 100%;
      ul{
        display: block;
        width: 50%;
        float: left;
        list-style: none;
        padding-left: 20px;
        @media screen and (max-width:767px){
          width:100%;
        }
        li{
          position: relative;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          
        }
        li:before{
          content: "\2022";
          color:#3C97E1;
          font-weight: bold;
          position: absolute;
          display: inline-block; 
          width: 1em;
          margin-left: -1em;
          font-size: 25px;
          top: 0;
          margin-top: -8px;
        }
      }
    }
  }

  .venue-available-right {
    width: 100%;
    position: relative;
    padding-left: 10px;
    .buttonAdd{
      position: absolute;
      right: 5px;
      top: 7px;
    }
    h3 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      float: left;
      margin: 5px 0px 20px;
    }

    .help_icon{
      float: left;
      // padding: 17px 5px;
      padding: 6px 0px 11px!important;

      .material-icons{
        font-size: 13px;
        color: #1397e1;
      }
    }

    .venueRightContainer {
      width: 100%;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      padding: 8px 8px;
      // overflow-y: scroll;
      // height: -webkit-fill-available;

      .spaceImage {
        width: 100%;
      }

      // .venue {
      //   width: 50%;
      //   height: auto;
      //   padding-left: 14px;
      //   padding-right: 14px;
      //   padding-top: 14px;
      //   height: auto;

      //   .venue_image {
      //     width: 100%;
      //     min-width: 120px;
      //     height: 76%;

      //     .VenueCardWithFav-activeFav-363 {
      //       font-size: 24px;
      //       text-shadow: 0px 0px #ff0000;

      //       .venueRightContainer .active_icon_over_image {
      //         color: red;
      //         position: relative !important;
      //         width: 100%;
      //         top: -108px;
      //         left: -5px !important;
      //       }
      //     }
      //   }

      //   .venue_name {
      //     font-size: 12px;
      //     font-weight: 500;
      //     font-style: normal;
      //     font-stretch: normal;
      //     line-height: 2;
      //     letter-spacing: normal;
      //     text-align: left;
      //     color: #202020;

      //   }

      //   .venue_content {}



      //   .MuiIcon-root-205 {
      //     width: 1em;
      //     height: 1em;
      //     overflow: hidden;
      //     font-size: 24px;
      //     user-select: none;
      //     flex-shrink: 0;
      //   }
      // }
    }
  }
  .photoCredit{
    font-size: 12px;
    font-style: italic;
    padding:0px 0px 0px 12px;
    span{
      text-transform: capitalize;
    }
    a{
      text-transform: capitalize;
      font-weight: 600;
      color: #62717a;
    }
  }
}


@media only screen and (min-width: 320px) and (max-width: 991px) {

  .venue_page {

    .EventPlannerHeader-appbar-106 {
      color: #36434b;
      box-shadow: inset 0 -1px 0 0 #fff !important;
      background-color: #ffffff !important;

    }

    .MuiTypography-body1-208 {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
    }

    .MuiTypography-h4-214 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
    }

    .leftPanel {
      width: 80%;

      .topImage {
        border: #000 1px solid;
        height: 394px;
      }
    }

    .rightPanel {
      width: 20%;

      .rightMap {
        border: #000 1px solid;
        height: 394px;
      }
    }

    .midContainer {
      // width: 89%;
      margin: 14px;
      padding-left: 10px;
      padding-right: 10px;

      .venue-available {
        width: 100%;
        font-family: Raleway !important;



        .avilableDescriptionDiv {
          width: 80%;

          .avilableDescription {
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #62717a;
            
          }

        }

        .dayTimeDiv {
          width: 100%;

          .mondFriDiv {
            width: 40%;
            float: left;
          }

          .satSunfDiv {
            width: 100%;
            float: left;
          }

        }


        .availabilitytext {
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.12;
          letter-spacing: normal;
          text-align: left;
          color: #36434b;
        }
      }

    }

    .spaceVanueDiv {
      width: 100%;
      min-height: 385px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;

      // .venue {
      //   width: 48%;
      //   height: auto;

      //   .venue_image {
      //     width: 100%;
      //     min-width: 150px;
      //   }

      //   .venue_name {
      //     font-size: 12px;
      //     font-weight: 500;
      //     font-style: normal;
      //     font-stretch: normal;
      //     line-height: 2;
      //     letter-spacing: normal;
      //     text-align: left;
      //     color: #202020;

      //   }

      //   .VenueCardWithFav-activeFav-363 {
      //     font-size: 24px;
      //     text-shadow: 0px 0px #ff0000;


      //   }

      // }
    }

    .similarDiv {
      width: 100%;

      .similarContainer {
        width: 100%;
        padding: 10px;

        .similerText {
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #36434b;
        }
      }

    }

    .venue-available-right {
      width: 100%;
      margin-top: 10px;
      padding-left: 0px;
    .buttonAdd{
      position: absolute;
      right: 0px;
    }
      h3 {
        font-family: Raleway;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
        margin: 5px 0px 10px;
      }

      .venueRightContainer {
        width: 100%;
        border-radius: 6px;
        border: solid 1px #e1e1e1;
        background-color: #ffffff;

        .spaceImage {
          width: 100%;
        }
      }
    }   
  }
}

.venue_space_venue_card {
  padding: 8px 8px;
  width: 50%;
}

.similar_venue_card {
  padding-left: 20px;
  padding-right: 20px;
}

// start of venue details image css
.venue_details_image {
  .image_of_venue {
    width: 100%;
    height: 394px;
    object-fit: cover;
    @media screen and (max-width:767px) {
      height:auto;
    }
  }

  .venue_details_over_image {
    width: 100%;
    // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(6, 6, 6, 0.2));
    position: absolute;
    bottom: 3px; /* attaches the element to the bottom */
    left: 0; /* attaches the element to the left-side */
    right: 0; /* attaches the element to the right-side */
    padding: 20px 0 20px 20px;
    // @media screen and (min-device-width:768px) and (max-device-width:1023px) {
    //   position: relative;
    //   margin-top: -65px;
    // }
    @media screen and (max-width:767px) {
      position:relative;
      background-image: none;
    }
    .div1{
      width:45%;
    }
  }

  @media screen and (min-width: 1023px) and (max-width: 1240px) {
    .venue_details_over_image {
      margin-top: -62px;

    }

  }

  .venue_name_in_venue_details_image {
    padding-left: 0px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    text-align: left;
    color: #fff !important;

    @media screen and (max-width: 767px) {
      color: #42434b !important;
    }
  }

  .detail_image {
    .venue_address_details_in_venue_details_image span {
      margin-top: 10px;
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    .venue-gallery {
      button {
        color: #42434b;
        border: 1px solid #42434b;

      }
    }
  }

  .venue_address_details_in_venue_details_image {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    width: 40%;
    height: auto;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 767px) {
      color: #42434b;
      // padding-right: 20px;
      text-align: left !important;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 10px;
      padding-left: 20px;
    }

    .icon_container {
      margin-top: 4px;
      padding-left: 23px;
    }

  }

  .place_icon_in_venue_details_image {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ee2738;
    margin-right: 5px;
    margin-top: 5px;
      @media screen and (max-device-width:767px){
      position: absolute;
      left: -3px;
      top: 0px;
      }
  }

  .view_photos {
    border: $white 1px solid;
  }

  .view_photos_container {
    // margin-top: 5px;

    @media screen and (max-device-width:500px) {
      padding: 10px 15px 10px 0px;
    }
  }
}

.white-button {
  // width: 100px;
  // height: 30px;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  color: #ee2738;
  cursor: pointer;
  padding: 6px 12px;
  span {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.VenueCardWithFav-activeFav-295,
.VenueCardWithFav-favIcon-294 {
  margin: 10px;
}

.calendar_instruction {
  padding: 30px 0px 10px 0px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #36434b;
}

textarea {
  resize: none;
  height: 70px;
}

@media screen and (min-device-width:501px) and (max-device-width:767px) {
  .venue_details_image .view_photos_container {
    padding: 10px 15px 10px 25px;

    button {
      border: solid 1px black;
      color: #000;
    }

    .venue_details_over_image {
      position: absolute;
    }

  }

}

.venue-gallery {
  button {
    border: 1px solid #fff;
  }
}

.satSunfDiv {
  p {
    font-weight: bold;
  }
}
.space-planner-edit-outer-grid
ul li .delete::before , .space-planner-edit-outer-grid
ul li .delete::after{
  display: none;
} 

.venue_page {
    .space-planner-edit{
        .planner-title-block{
            h3{
            font-size: 13.5px;
            }
            .help_icon{
            display:inline-flex;
            //padding-left: 5px;
            vertical-align: middle;
            @media screen and (max-width:767px) {
                
                padding-left: 10px;
            }
            }
        }
    }
    .add-btn {
        margin-top: 25px;
        padding: 7px 30px;
    }
}