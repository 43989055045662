.press-page-ui{
    margin-top: 65px;
    .press-banner-images{
        background-repeat: no-repeat;
        background-position: center, center;
        background-size: cover;
        height: 355px;
        object-fit: cover;
        position: relative;
        background-image: url("../images/press-banner.jpg");
        p{
            font-size: 62px;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-shadow: 0 0 7px #000000;
        }
    }
    .press-description-section{
        text-align: center;
        padding: 20px 0 60px;
        h3 {
            color: #000000;
            font-weight: 600;
            font-size: 25px;
        }
        span.black-bold {
            color: #000000;
        }
        p.light-text {
            margin-top: 50px;
        }
        p.blue-text {
            font-weight: 700;
            font-size: 16px;
            color: #525151;
        }
    }
    .search-btn a {
        background-color: #d80e2a;
        color: #ffffff;
        padding: 10px 20px;
        border: 1px solid #d80e2a;
        margin-top: 5px;
        width: 200px;
        font-size: 15px;
        border-radius: 0px;
        font-weight: 600;
        display: inline-block;
        text-align: center;
    }
    .press-release-section{
        text-align: center;
        background-color: #cccccc36;
        padding: 50px 0;
        height: 250px;
        h3 {
            color: #000000;
            font-weight: 700;
            font-size: 30px;
            margin-bottom: 15px;
        }
        a,
        a:hover {
            font-size: 15px;
            color: #000000;
            font-weight: 600;
        }
        span.light-text {
            font-weight: 400;
        }
    }
    .press-launch-ui{
        padding-top: 100px;
        p{
            font-size: 30px;
            color: #000000;
            font-weight: 500;
        }
        .banner-wrapper{
            display: flex;
            align-items: center;
        }
        .search-wrapper {
            max-width: 392px;
        }
        .logo-image img {
            max-width: 200px;
        }
        .press-launch-description{
            padding: 50px 0 100px;
            p{
                font-size: 17px;
                margin-bottom: 20px;
                font-weight: 500;
            }
            a,
            a:hover {
                color: #1397e1;
            }
        }
        .banner-section {
            padding: 80px 0;
        }
        .social-block{
            margin-top: 20px;
            border-top: 1px solid #cccccc;
            border-bottom: 1px solid #cccccc;
            padding: 30px 0;
            p.light-text {
                font-size: 15px;
                color: #8e8e8e;
                font-weight: 600;
                text-transform: uppercase;
            }
            p.bold-text {
                font-size: 15px;
                font-weight: 600;
            }
        }
        .social-icon-block{
            padding: 0;
            margin-top: 5px;
            li, 
            a {
                display: inline-block;
                margin-right:5px;
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    @media screen and (max-width:991px){
        .press-launch-ui {
            padding: 100px 20px 20px 20px;
        }
        .press-description-section {
            padding: 20px 15px 60px;
        } 
        .press-release-section {
            padding: 50px 20px;
        }
    }
    @media screen and (max-width:620px){
        .press-launch-ui{
            .banner-section {
                text-align: center;
                padding: 50px 0;
            }
             .search-wrapper {
                max-width: 392px;
                margin: 0 auto;
            }
            .logo-image {
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .main{
        a,
        a:hover {
            color: #1397e1;
        }
    }
    .eventTemple{
        max-width: 410px !important;
    }
}