$white: #ffffff;
$primary: #ee2738;
$water_blue: #1397e1;
.home_page {
    overflow-x: hidden;
}

.search_page{
    background: $primary;
    text-align: center;
    color: $white;
    padding: 2% 38% 4%;
    border-radius: 0 0 100% 100% / 112px;
    overflow: hidden;
    // margin-left: -10%;
    width: 100% !important;
    margin-top: 65px;
  
    h1{
        text-align: left;
        font-size: 48px;
        font-weight: 200;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $white;
        @media screen  and (max-width:1599px) {
            font-size: 33px;
        }
    }
    p{
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $white;
        &.blue-text{
            color: $water_blue;
        }
    }
    .material-icons{
        font-size: 48px;
    }
    @media screen and (max-width:768px){
        padding: 7% 20%;

        h1{
            font-size: 38px;
        
        }
    }
  
}

//calendar css

$black: #202020;
$water_blue: #1397e1;
$dark_grey: #36434b;
$off_white: #f7f7f7;
$white: #ffffff;
$primary: #ee2738;
$light_grey: #747474;
$off_black: #1a181e;
.syncing_calendar{
    .CustomSelectWithScroll-selectContainer-99 {
         border: solid 1px #e1e1e1;
        height: 36px;
        padding: 0px 4px;
        background: #f7f7f7 !important;
        margin-top: 4px;
        border-radius: 6px;
    }
    .custom_checkbox{
        float: right;
    }
}
.main-form{
    .sync-year-container{
        text-align: right;
        border-bottom: 1px solid #c4d0d9;
        padding:8px;
        .select-control{
            min-width: 100px;
            .sync-select{
                line-height: 36px;
                font-size: 14px;
            }
        }
        .select-label{
            font-size: 16px;
            padding:8px;
        }

    }
    .main-form .custom_checkbox {
        float: right;
    }
    .custom_select_with_scroll {
        width: 40%;
        float: right;
        text-align: end;
    }
    .custom_select_with_scroll>div{
        max-width: 200px;
        @media  screen and (max-width:768px){
            max-width:100%;
        }
    }
    .custom_checkbox {
        padding-top: 10px;
    }
    .custom_select{
        width: 50%;
    }
    .second-line-text{
        font-size: 13px;
    }
    .third-line-text{
        font-size: 13px;
        color:#747474;
    }
    .search_page{
        background: $primary;
        text-align: center;
        color: $white;
        padding: 4% 42%;
        border-radius: 0 0 100% 100% / 112px;
        overflow: hidden;
        margin-left: -10%;
        width: calc(100% + 20%) !important;
      
        h1{
            text-align: left;
            font-size: 48px;
            font-weight: 200;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $white;
        }
        p{
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: $white;
        }
        .material-icons{
            font-size: 48px;
        }
       
      
    }
    .top-next{
        text-align: right;
        .red-button{
            padding: 6px;
            min-width: 100px;
        }
    }
    padding: 2% 10%;
    p{
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: $dark_grey;
    }
    h3{
        text-align: right;
        color: $dark_grey;
        font-size: 16px;
        font-weight: 600;
        margin: 0
    }
    h5{
        font-size: 16px;
        font-weight: 500;
         line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $dark_grey;
        margin: 0;

    }
    .small{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
        margin: 0;
    }
    h6{
        font-size: 16px;
        font-weight: bold;
         line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $dark_grey;
        margin: 0;
    }
    .MuiSelect-root-123 {

        border-radius: 0px;
     }
    .MuiSelect-select-124 {
        width: calc(100% - 100px);
        height: 1.1875em;
        cursor: pointer;
        min-width: 100px;
        user-select: none;
        padding-right: 32px;
        border-radius: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    
    .select_field {
        background-color: $off_white;
    
    }
    .data-table{
        border: 1px solid #c4d0d9;
        padding: 20px 20px 10px;
        margin-top: 10px;
    }
    .custom_checkbox{
        font-size: 13px;
    }
    button{
        border-radius: 6px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f1435e), to(#ee2738));
        background-image: linear-gradient(to bottom, #f1435e, #ee2738);
        border: 0;
        padding: 1% 6%;
        color: $white;
        margin-top: 20px;
    }
    @media screen and (max-width:767px){

          .CustomSelectWithScroll-selectContainer-99 {
                width: 100%;
            }
         p {
            font-size: 16px;        
        }
        button{
            padding: 15% 100%;
        }
        .small {
            margin: 8px auto;
        
        }
        .vertical_align_date{
            margin: 0px auto 0px 0px !important;
        }
        .vertical_align_reason{
            margin: 20px auto !important;
        }
        .custom_select_with_scroll {
            width: 80%;
            float: left;
        }
        .data-table .custom_checkbox {
            width: 80%;
            float: left;
        }
    }
    .vertical_align_date{
        margin: 10px 0;
    }
    .vertical_align_reason{
        margin: 20px 0;
    }
    .vertical_align_date_holiday{
        margin: 21px 0;
    }
    button{
        cursor: pointer;
    }
}


.main-form {
    .loadmore-btn-block{
        button {
            background: transparent;
            color: #62717a !important;
            box-shadow: none;
            border: 1px solid #cccccc;
            font-weight: 700;
            padding: 15px 50px;
        }
    }
}
.data-table{
    .custom_checkbox {
        margin-left: 15px;
    }
}
.disabled-block{
    .custom_select_with_scroll div,
    .custom_checkbox .container {
        cursor: not-allowed !important;
    }
} 

.main-form .custom_checkbox {
    margin: 0;
}