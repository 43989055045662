.explore-ui-wrapper{
    .topspace{
        margin-top: 120px;
    }
    .heading-text h1 {
        color: #595959;
        font-weight: 700;
        margin-bottom: 40px;
    }
    .heading-text.main h1 {
        font-size: 35px;
        line-height: 45px;
    }
    .heading-text.main p {
        color: #a6a6a6;
            font-size: 14px;
            margin-bottom: 40px;
    }
    .heading-text.main.event h1{
        font-size: 45px;
        line-height: 45px;
    }
    .search-field{
        position: relative;
        input[type="search"] {
            height: 45px;
            max-width: 400px;
            width: 100%;
            border: 1px solid #cccccc59;
            border-radius: 4px;
            padding: 0 16px 0 40px;
        }
        .icon-search {
            position: absolute;
            top: 6px;
            left: 10px;
        }
        img {
            width: 30px;
        }
    }

    input::placeholder {
        font-size: 14px !important;
        padding-left: 10px;
        color:#a6a6a6;
        font-weight:400;
        opacity:0.4;
    }
    .search-btn a,.search-btn button {
        background-color: #d80e2a;
        color: #ffffff;
        padding: 10px 20px;
        border: 1px solid #d80e2a;
        margin-top: 20px;
        width: 100%;
        font-size: 15px;
        border-radius: 4px;
        font-weight: 600;
        display: inline-block;
        text-align: center;
    }
    .MuiGrid-container-1 {
        align-items: center;
    }
    .search-wrapper{
        max-width: 400px;
        // margin: 0 auto;
    }

    .search-wrapper-block {
        margin-left: 50px;
    }
    .heading-text h1 {
        font-size: 26px;
    }

    .search-field h2{
        color: #595959;
        font-size: 16px;
        font-weight: 600;
    }
    // .search-field{
    //     margin-bottom: 10px;
    // }
    .started a{
        width:200px;
    }
    .search-btn.started {
        text-align: center;
        margin-top: 20px;
    }
    .box-wrapper{
        // margin:50px 0;
        padding-top: 120px;
    }

    .search-wrapper-block.two-box{
        margin-right: 100px;
        margin-left: 0;
        margin-top: 30px;
    }

    $dark_grey: #36434b;
    .venueTypeCard{
        @media screen and (max-width:767px){
        margin: auto 15px;
        width: 100%;
        }
        a{
            text-decoration: none;
            &:hover{
                text-decoration: none;
            }
        }
        .find_feature_venue_image{
            &:hover {
                box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7);
            }
            @media screen and (min-width:1150px) {
                width: 290px;
                max-height: 200px;
                object-fit: cover;
                object-position: bottom;
            }
            @media screen and (max-width:1151px){
                width: 265.3px;
                max-height: 160.8px;
                object-fit: cover;
                object-position: bottom;
            }
            @media screen and (max-width:991px){
                width: 100%;
                max-height: 200px;
            }
        }
        .find_venue_event{
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            text-align: center;
            color: $dark_grey;
            padding-top: 3px;
        }
        .venues{
            padding: 10px 20px !important;
            display: inline-block;
        }
    }
    .venueTypeCard {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .slider-heading-box h1{
        margin-bottom: 0;
        margin-top:20px;
    }
    .slider-heading-box p{
        margin-bottom: 20px;
    }

    .location-block a {
        display: block;
        margin-bottom: 10px;
        color: rgb(19, 151, 225);
        font-weight: 600;
        font-size: 14px;
    }

    .heading-text.block h1{
        margin-bottom: 0;
    }

    .box-wrapper.location {
        align-items: start;
        width: 700px;
        margin: 0 auto;
        padding: 30px 0 80px 0;
        .heading-text.block h1{
            font-size: 18px;
        }
        .location-block {
            padding-left: 100px;
        }
    }

    @media screen and (max-width:991px){
        .search-btn {
            margin-bottom: 15px;
        }
        .search-wrapper-block.two-box,
        .search-wrapper-block,
        .slider-heading-box,
        .box-wrapper.location,
        .search-wrapper,
        .articles-section {
            margin: 0 25px;
        }
        .text-center-image {
            text-align: center;
        }
        .search-btn.started {
            margin-top: 0px;
        }
        .location-address .heading-text.block {
            margin: 0 20px;
        }
    }

    .heading-text.event h1{
        margin-bottom: 0;
        font-size: 55px;
    }
    .image-block, 
    .image-text-block {
        display: inline-block;
        vertical-align: middle;
    }
    .image-block {
        max-width: 200px;
        height: 189px;
    }
    .image-wrapper {
        background-color: #cccccc30;
        padding:20px;
        margin-bottom: 30px;
    }

    .image-text-block {
        padding-left: 50px;
        h1 {
            color: #767171;
            font-weight: 700;
            font-size: 22px;
        }
        p {
            color: #767171;
            font-size: 15px;
            font-weight: 500;
        }
    }

    @media screen and (max-width:1024px){
        .image-text-block {
            padding-left: 10px;
        }
    }

    @media screen and (max-width:768px){
        .btn-right {
            text-align: left  !important;
        }
        .heading-text.event h1{
            font-size: 40px;
        }
        .image-block {
            margin: 0 auto;
            display: block;
        }
        .box-wrapper.location {
            .location-block {
                padding-left: 10px;
            }
        }
        .box-wrapper,
        .need-more-section {
            padding-top: 60px !important;
        }
        .featured-venue-bay{
            padding-bottom: 60px !important;
        }
        .need-more-section{
            margin-top: 60px !important;
            .heading-text h1 {
                text-align:center;
            }
        }
        .box-wrapper.location {
            padding: 30px 0 80px 0;
        }
    }
    .text-with-btn,
    .need-more-section {
        background-color: #cccccc69;
        vertical-align: middle;
        padding: 5px 20px;
    }

    .text-with-btn h1{
        color: #36434b;
        font-size: 17px;
        font-weight: 500;
    }
    .article-btn {
        background: #ffffff;
        color: #36434b;
        padding: 5px 20px;
        border: 1px solid #00000059;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        text-align: center;
    }
    .btn-right{
        text-align:right;
    }
    .articles-section p {
        padding: 30px 20px;
        font-size: 14px;
        color: #a6a6a6;
    }
    .need-more-wrapper {
        padding: 50px 0;
        button {
            width: auto !important;
        }
        .search-btn {
            text-align: center;
        }
    }
    .search-wrapper-block,
    .slider-heading-box {
        p,
        ul li {
            color: #a6a6a6;
            font-size: 14px;
        }
        ul{
            padding-left: 35px;
            li {
                list-style: disc;
            }
        }
    }
    // .articles-section {
    //     margin-top: 30px;
    // }
    .bold {
        color: #7f7f7f;
    }
    .text-center-image img {
        min-height: 566px;
    }
    .need-more-section {
        margin-top: 100px;
    }
    .third-section{
        max-width: 400px;
        margin: 0 auto;
        height: 500px !important;
        margin-top: 30px;
    }
    .heading-text.ask{
        margin-bottom: 20px;
    }
    .heading-text.main.event p {
        color: #595959;
        font-size: 18px;
        font-weight: 600;
        margin-top: 10px;
    }
    .need-more-wrapper{
        .search-btn a{
            width: 60%;
        }
    }

    .text-center-image{
        background-repeat: no-repeat;
        background-position: center, center;
        background-size: cover;
        height: 400px;
        object-fit: cover;
        position: relative;
    }
    .meeting-main{
        background-image: url("../images/Meeting2.jpg");
    }
    .meeting-main-1{
        background-image: url("../images/Meeting.jpg");
    }
    .meeting-main-2{
        background-image: url("../images/Meeting1.jpg");
    }
    .corporate-main{
        background-image: url("../images/corporate-event.jpg");
    }
    .corporate-main-1{
        background-image: url("../images/Corporate Venue - 2nd Photo.jpg");
    }
    .corporate-main-2{
        background-image: url("../images/Corporate Venue - 3rd.jpg");
    }
    .event-space-main{
        background-image: url("../images/Blog - Wedding - Photo7.jpg");
    }
    .event-space-main-1{
        background-image: url("../images/Blog - Wedding - Photo1.jpg");
    }
    .event-space-main-2{
        background-image: url("../images/Unique - Photo3.jpg");
    }
    .bay-area-main{
        background-image: url("../images/Bay Area - Main Photo.jpg");
    }
    .bay-area-main-1{
        background-image: url("../images/Blog - Wedding - Photo2.jpg");
    }
    .bay-area-main-2{
        background-image: url("../images/San Francisco - Bay Area1.jpg");
    }
    .event-main{
        background-image: url("../images/New Landing Page - Main.jpg");
    }
    .weeding-main{
        background-image: url("../images/Wedding Page - Main.png");
    }
    .weeding-main-1{
        background-image: url("../images/Blog - Wedding - Photo3.jpg");
    }
    .weeding-main-2{
        background-image: url("../images/wedding-043.jpg");
    }
    .winery-main{
        background-image: url("../images/Blog - Interesting venue 2.jpg");
    }
    .winery-main-1{
        background-image: url("../images/Wineries.jpg");
    }
    .winery-main-2{
        background-image: url("../images/Wineries2.jpg");
    }
    .silicon-valley-main-2{
        background-image: url("../images/Silicon Valley - Bottom.jpg");
        height: 570px !important;
        width: 380px;
    }
    .silicon-valley-main-1{
        background-image: url("../images/Silicon Valley - Middle.jpg");
        
    }
    .silicon-valley-main{
        background-image: url("../images/Silicon Valley - Top.jpg");
        
    }
    .wine-country-main-2{
        background-image: url("../images/Wine Country - Bottom.jpg");
        height: 570px !important;
        width: 380px;
    }
    .wine-country-main-1{
        background-image: url("../images/Wine Country - Middle.jpg");
        
    }
    .wine-country-main{
        background-image: url("../images/Wine Country - Top.jpg");
        
    }
    .san-francisco-main-2{
        background-image: url("../images/San Francisco - Bottom.jpg");
        height: 578px !important;
        width: 400px;
    }
    .san-francisco-main-1{
        background-image: url("../images/San Francisco - Middle.jpg");
        
    }
    .san-francisco-main{
        background-image: url("../images/San Francisco - Top.jpg");
       
    }
    .sonoma-county-main-2{
        background-image: url("../images/Sonoma County - Bottom.jpg");
    }
    .sonoma-county-main-1{
        background-image: url("../images/Sonoma County - Middle.jpg");
        
    }
    .sonoma-county-main{
        background-image: url("../images/Sonoma County - Top.jpg");
       
    }
    .sonoma-main-2{
        background-image: url("../images/Sonoma - Bottom.jpg");
        height: 576px !important;
        width: 380px;
    }
    .sonoma-main-1{
        background-image: url("../images/Sonoma - Middle.jpg");
        
    }
    .sonoma-main{
        background-image: url("../images/Sonoma - Top.jpg");
        
    }
    .napa-valley-main-2{
        background-image: url("../images/Napa Valley - Bottom.jpg");
        height: 570px !important;
        width: 392px !important;
    }
    .napa-valley-main-1{
        background-image: url("../images/Napa Valley - Middle.jpg");
        
    }
    .napa-valley-main{
        background-image: url("../images/Napa Valley - Top.jpg");
       
    }
    .napa-main-2{
        background-image: url("../images/Napa - Bottom.jpg");
        height: 569px !important;
        width: 376px !important;
    }
    .napa-main-1{
        background-image: url("../images/Napa - Middle.jpg");
    }
    .napa-main{
        background-image: url("../images/Napa - Top3.jpg");
    }
    .sonoma-valley-main-2{
        background-image: url("../images/Sonoma Valley - Bottom.jpg");
        height: 569px !important;
        width: 381px !important;
    }
    .sonoma-valley-main-1{
        background-image: url("../images/Sonoma Valley - Middle.jpg");
    }
    .sonoma-valley-main{
        background-image: url("../images/Sonoma Valley - Top.jpg");

    }
    .banner-wrapper{
        display:flex;
        align-items: center;
    }
    .location-address-wrapper {
        margin-top: 60px;
        border-top: 1px solid #cccccc;
        padding-top: 100px;
    }
    .border-line {
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
    }
    .text-center-image a {
        position: absolute;
        right: 0;
        bottom: 20px;
        background:#ffffff;
        color: #a6a6a6;
        font-weight: 700;
        padding: 0 6px 0 10px;
        font-size: 11px;
    }
    .text-center-image.left-side a {
        left: 0;
        right: unset;
    }
    .need-more-wrapper h1 {
        margin-bottom: 0;
    }
    .margin-top{
        margin-top: 40px;
    }
    .featured-venue-bay{
        .heading-text {
            background: hsla(0,0%,80%,.19);
            padding: 30px 20px;
        }
        .heading-text h1 {
            margin: 0;
        }
    } 
    .margin-10{
        margin-top: 10px;
    }
    .slider-heading-box.search {
        text-align: center;
        padding-bottom: 20px;
    }
    .slider-heading-box.frequently {
        max-width: 900px;
    }
    .featured-venue-bay {
        padding-bottom: 70px;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 70px;
    }
    .search-wrapper-block.two-box .search-field.margin-top {
        margin-top: 20px;
    }
    .need-more-wrapper{
        .search-btn a{
            margin-top: 0 !important;
        }
    }
    .margin-top-5{
        margin-top: 15px;
    }
    .box-wrapper-padding{
        padding-top: 20px;
    }
    .contact.search-btn{
        width: 200px;
        margin: 0 auto;
        padding-bottom: 120px;
    }
    .googleMap{
        //display:inline-block;
        text-align: center;
    }
}