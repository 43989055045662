.matter{ font-family: Raleway !important; }
.space-planner-edit {
  overflow-x: hidden;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  overflow-x: hidden;
  padding-left: 90px;

  .black-heading {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0;
    float: left;
    width: 100%;
  }

  .space-planner-edit-outer-grid {
    padding: 0px 0 10px;

    .are-there-restrictions{
      border: solid 1px #f0f0f0;
      border-radius: 6px;
      padding: 10px 15px;
    }
  }

  .planner-title-block {
    display: inline-flex;
    padding-bottom: 15px;
    .help_icon{
      width: 10px;
      text-align: left;
      margin-top: -4px;
      button{
        background-color: transparent;
        box-shadow: none;
        padding: 0px;
        width: auto;
        span{
          justify-content: flex-start;
        }
      }
    }
}

  .make-annual {
    padding-left: 94px;
    @media screen and (min-width:1400px){
      padding-left: 103px;
    }
    @media screen and (max-width:1300px) and (min-width:1024px){
      padding-left: 85px;
    }
    @media screen and (max-width:991px) and (min-width:768px){
      padding-left: 0px;
    }
    @media screen and (max-width:767px){
      padding-left: 2px;
    }
    }
  .grid-section-vertical.pt-2{
      padding-top: 10px;

      .select-field:before {
        content: 'arrow_drop_down';
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 0.8;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        position: absolute;
        top: 10px;
        right: 5px;
        color: #62717a;
        z-index: 1;
        cursor: pointer;
    }
    }
  .grid-section-vertical {
    width: 100%;
    overflow: auto;
    text-align: left;
    display: block;
    .view {
      button{
      // margin-right: 10px;
    }
  }
    .other-info-label{
      text-align: left;
    }
    .left-space{
      width: 23%;
      display: block;
      float: left;
      @media screen and (max-width:991px) and (min-width:768px){
        display: none;
      }
    }
    .d-none{
      @media screen and (max-width:767px){
        display: none;
      }
    }
    .end-p{
      width: 59px;
      display: inline-block;
      @media screen and (max-width:767px){
        width: 30%;
      }
    }
    .select-end{
      display: inline-block;
      position: relative;
      width: 100px;
      @media screen and (max-width:767px){
        width: 70%;
      }
      .error{
        width:100%;
      }
      
      select{
        width: 100px !important;
        border: 1px solid #e1e1e1;
        font-weight: 500;
        color: #48494a !important;
        @media screen and (max-width:767px){
          width: 100% !important;
        }
      }
    }
    .block-left{
      display: block;
      float: left;
      width: auto;
      padding-right: 15px;
      padding-top: 10px;
      @media screen and (max-width:767px){
        width:46%;
      }
      p{
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
      }
      label{
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      .radioButton{
        padding-top: 18px;
      }
      div{
        display: block;
        float: left;
        padding-top: 0px;
        span{
          padding-left: 0px;
        }
      }
      .error{
        display: block;
        float: none;
        width: 120px;
      }
    }
    .block-right{
      display: block;
      float: left;
      width: 41.5%;
      padding-top: 10px;
      @media screen and (max-width:991px) and (min-width:768px){
        width: 48%;
      }
      @media screen and (max-width:767px){
        width:54%;
        padding-bottom: 5px;
      }
      p{
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
      }
      label{
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      div{
        display: block;
        float: left;
        padding-top: 0px;
        span{
          padding-left: 0px;
        }
      }
      .radioButton{
        padding-top: 18px;
      }
    }
   
    .box-left{
          display: inline-block;
          width: auto;
          @media screen and (max-width:767px) {
            width: 100%;           
           }
          p{
            float: left;
            display: inline-block;
            padding-right:10px;
            margin: 11px auto;
            width: 37%;
            @media screen and (max-width:767px) {
              width: 30%;            
            }
          }
          ::placeholder { /* Safari, Chrome and Opera */
            color: #b0b0b0 !important;
          }
          .datePicker{
            display: inline-block;
            float: left;
            width: auto;
            max-width: 100px;
            @media screen and (max-width:767px){
              max-width:100%;
              width: 70%;
            }
            .datepiker.select_field{
              border: 1px solid #e1e1e1;
              font-weight: 500;
            }
          }
    }
    .box-right{
      display: inline-block;
      width: 100%;
      vertical-align: top;
      padding-top: 10px;
      p{
        // padding-top: 10px;
        float: left;
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        padding-right:0px;
        // margin: 11px auto;
        text-align: center;
        width: 30%;
        @media screen and (max-width:767px) {
          text-align: left;
        }
      }
      button{
        float: left;
        display: inline-block;
        width: auto;
        max-width: 100px;
        padding-left: 0px;
      }
      .current-date{
        color: #1397e1;
        border: 0px;
        font-size: 14px;
        padding-top: 0px;
        background-color: transparent;
        font-weight: 500;
        cursor: pointer;
        :hover{
          transition:  0.3s ease-in-out;
          color: #e20d0d;
        }
        Icon{
          margin-left: -2px;
        }
      }
    }
  }
  .restriction-button{
    display: block;
    width: 100%;
    text-align: center;
    .red-button{
      width: 120px;
      margin-bottom: 5px;
    }
  }

  .other-info-label {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: var(--black);
    float: left;
    width: 20%;
    padding-right: 20px;
    margin-top: 10px;
  }

  select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    font-weight: normal;
    padding: 5px;
    -webkit-appearance: menulist;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: black;
    padding-left: 5px;
    width: 40%;
  }

  .small-text {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    margin: 0;
  }

  .grey {
    color: #62717a;
  }

  .sub-section {
    width: 100%;
    display: block;
    margin: 15px 0;
  }

  .sync_calendar_popup{
    .event_info_popup{
      background: #ffffff;
      width: auto;
      .cal-box{
        padding:10px;
        .calendar-icon{
          display: inline-block;
          border: 1px solid #1497e1;
          border-radius: 8px;
          padding: 8px;
          cursor: pointer;
          &.microsoft{
            margin-left: 8px;
          }
          img{
            width: 48px;
            height: 44px;
          }
          p{
              cursor: pointer;
              font-size: 13px;
              font-weight: 600;
              width: 64px;
              height: 66px;              
              border-radius: 8px;  
              display: flex;
              position: absolute;
              margin: -57px 0 0 -8px;
              background-color: #4d4d4d;
              opacity: 0.9;
              color: #fff;
              justify-content:center;
              align-items: center;
              &.EventTemple{
                text-align: center;
              }
          }
         } 
      }
    }
   }

  .subsec-btn{
    display: flex;
    justify-content: space-between;
    overflow: visible;
    height: auto;
   @media screen and (max-width:1100px) and (min-width:1024px) {
     .white-button-div , .red-button-div{
       width: 120px;
       height: 30px;
       overflow: hidden;
       .calendar-icon{
        display: inline-block;
        border: 1px solid #1497e1;
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;
        &.microsoft{
          margin-left: 8px;
        }
        img{
          width: 48px;
          height: 44px;
        }
        p{
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            width: 64px;
            height: 66px;              
            border-radius: 8px;  
            display: flex;
            position: absolute;
            margin: -57px 0 0 -8px;
            background-color: #4d4d4d;
            opacity: 0.9;
            color: #fff;
            justify-content:center;
            align-items: center;
        }
       }      
     }
  }
  .red-button-div , .white-button-div{
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width:767px) {
      width: 83px !important;
     }
     @media screen and (max-width:500px) {
      width: 100px !important;
     }
     button{
      @media screen and (max-width:767px) {
        height: auto !important;
        padding: 5px 5px;
        width: 100%;
        line-height: normal;
       }
     }
     .calendar-icon{
      display: inline-block;
      border: 1px solid #1497e1;
      border-radius: 8px;
      padding: 8px;
      cursor: pointer;
      &.microsoft{
        margin-left: 8px;
      }
      img{
        width: 48px;
        height: 44px;
      }
      p{
          cursor: pointer;
          font-size: 13px;
          font-weight: 600;
          width: 64px;
          height: 66px;              
          border-radius: 8px;  
          display: flex;
          position: absolute;
          margin: -57px 0 0 -8px;
          background-color: #4d4d4d;
          opacity: 0.9;
          color: #fff;
          justify-content:center;
          align-items: center;
      }
     }
  }
  }

  .bordered {
    border-radius: 6px;
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    // width: 288px;
    width: 100%;
    height: auto;
    padding: 22px;
    font-size: 15px;
    line-height: 1.07;
    @media screen and (max-width:767px) {
      padding: 15px;
    }
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .red-button {
    width: auto;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border:0 !important;
    cursor: pointer;
    line-height: 2;
    padding: 0px 15px;
    min-height: 33px;
    @media screen and (max-width:991px) and (min-width:768px){
      width: auto;
      height: auto;
      min-height: 33px;
    }
    span{
      font-size: 11px;
    }
  }

  .white-button {
    width: auto;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    width: auto;
    min-height: 33px;
    @media screen and (max-width:991px) and (min-width:768px){
      width: auto;
      min-height: 33px;
      height: auto;
    }
    span{
      font-size: 11px;
    }
    a{
      color: #ee2738;
    }
    
  }

  .plan_description {
    height: auto;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #62717a;
    list-style: none;
    margin: auto;
    text-align: right;
    padding-inline-start: 0 !important;
    @media screen and (max-width:767px){
      padding: 0px 15px !important;
    }

    li {
      overflow: auto;
      width: 100% !important;
      // padding: 0px !important;
    }
  }

  .text {

    display: inline-flex;
    width: 70%;
    padding: 0px 0 0 5px;
    text-align: left;
    vertical-align: top;
    a{
      color: #36434b;
    }
  }
  .plan-descp-icons{
    display: inline-flex;
    width: 22%;
    justify-content: space-between;
    @media screen and (max-width:767px){
      width: 30%;
    }
    a{
      color: #62717a;
      .material-icons{
        @media screen and (max-width:991px){
          font-size:16px;
        }
      }
    }
    .red_icon_div{
      margin-top: -2px;
      padding-top: 0px;
      @media screen and (max-width:767px){
        margin-top: 0px;
      }
      span{
        @media screen and (max-width:991px){
          font-size:16px;
        }
      }
    }
  }

  .blue_icon_div {
    display: inline-flex;
    padding-top: 2px;
    color: #1397e1;
    .picture_as_pdf{
      color: #e20d0d;
    }
  }

  .red_icon_div {
    display: inline-block;
    padding-top: 2px;
    color: #e20d0d;
    cursor: pointer;
    .delete{
      @media screen and (max-width:767px){
        font-size: 22px;
      }
    }
  }

  .blue_icon {
    width: 20px;
    height: 20px;
    border: 0;
    background-color: #1397e1;
  }

  .react-calendar {
    margin: 15px 0;
    border: solid 1px #f0f0f0;
    border-radius: 6px;
    width: 90%;
    background-color: #f0f0f0;
  }

  abbr[title] {
    text-decoration: none !important;
  }

  .react-calendar__navigation {
    margin: 0;
    background-color: #ffffff;
  }

  .react-calendar__navigation__prev-button {
    font-family: MaterialIcons;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ee2738;
  }

  .react-calendar__navigation__next-button {
    font-family: MaterialIcons;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ee2738;
  }

  .react-calendar__navigation__label {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1397e1;
  }

  .react-calendar__viewContainer {
    box-shadow: inset 0 1px 0 0 #f0f0f0, inset 0 -1px 0 0 #f0f0f0;
    background-color: var(--white);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    width: 50px;
    height: 50px;
    box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
    background-color: #f7f7f7 !important;
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e1e1e1 !important;
  }

  .react-calendar__month-view__days__day {
    width: 50px;
    height: 50px;
    box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
  }

  .react-calendar__month-view__weekdays__weekday {
    width: 26px;
    height: 26px;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    text-decoration: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    padding-top: 20px;

    .venue-space-edit-inner-container {
      width: 100% !important;
      position: relative;
    }

    .grid-section-vertical {
      .red-button-div {
        width: 100%;
        // margin: auto;
        display: flex;
        padding: 10px 0;
      }

      .white-button-div {
        width: 100%;
        // margin: auto;
        display: flex;
        padding: 10px 0;
      }
    }
  }

}


.button-calendar > div{
    display: inline-block;
}
.button-calendar{  
    input.today {
        border: 0 !important;
        text-align: center;
        width: 100px;
    }
    .react-datepicker__header.react-datepicker-year-header {
        font-weight: 400;
        padding: 5px 0;
        background: linear-gradient(to bottom, #EF0927, #ee2738);
        color: #ffffff;
    }
    
    .react-datepicker__month-wrapper {
        padding: 5px 10px;
    }
    .react-datepicker__month .react-datepicker__month-text {
        width: 80px;
        font-weight: 500;
    }
    .react-datepicker__month--selected {
      background: lightblue;
  }
}