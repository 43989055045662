a{color:#36434b;}


.venue-header{
  button:hover{
    background-color: transparent;
    color: #ee2738;
  }
  header {
    border-bottom-style: solid !important;
    border-bottom-width: 1px!important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    box-shadow: none;
    height: 65px;
    background-color: #ffffff !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    .name{
      .top-header-name{
        :nth-child(2){
          @media screen and (max-width:767px) and (min-width:600px){
            padding: 13px 10px 0px 30px;
          }
        }
      }
    }
  }
  .rightMenuDiv{
    padding: 5px 15px 0px 0px;
  }
  margin-top: -8px;
  .link{
    color: #36434b;
    &:focus{
      color: #0000EE;
    }
    &:hover{
      color: #0000EE;
    }
    img{
      width: 30px;
      margin-top: 8px;
    }
  }
}
input{
  font-family: 'Raleway', sans-serif !important;
}

// H icon scss start
.top-header-name{
    a.link.background{
        line-height: 49px;
        span {
            background-color: #36434b !important;
            color: #ffffff !important;
            font-weight: 600 !important;
            border-radius: 4px;
            font-size: 20px;
        }
    } 
}
.sync_calendar_popup.event_calendar {
  margin-top: 70px;
}

.venue-header.space-planner-edit.webCalendar{
  .event_info_popup{
      z-index: 1101;
      height: 270px;
      max-width: 360px;
      width: 360px;
      @media screen and (max-width: 767px){
        height: 270px;
        max-width: 282px;
        .cal-box {
          padding: 30px 10px !important;
          // .calendar-icon {
          //   margin: 3px !important;
          // }
        }
        label.bold-text {
          font-size: 13px;
      }
      }
      @media screen and (max-width: 480px){
        max-width: 265px;
        label.bold-text {
          font-size: 12px;
        }
        .p-5 {
          padding: 30px 10px 15px 10px;
      }
      }
    }
    .cal-box {
      padding:30px 40px !important;
      .calendar-icon {
        padding: 15px;
        margin: 10px 25px !important;
      }
    }

    .p-5 {
        padding: 30px 15px 15px 15px;
    }
    .mt-5 {
      margin-top: 15px;
  }
    label.bold-text {
        font-weight: 600;
        line-height: 55px;
    }
    
    .input-field-section {
        margin: 10px 0;
    }
    button:hover{
        background-color: #d80e2a;
        color: #ffffff;
    }
    @media screen and (max-width:991px){
        .sync_calendar_popup.event_calendar {
            margin-top: 0px;
        }
    }
    @media screen and (max-width: 767px){
        .event_info_popup{
            margin-top:0px;
            left: 50px;
        }
        .sync_calendar_popup.event_calendar {
            margin-top: 70px;
        }
    }
} 
