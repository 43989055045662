.termsPage{
width: 100% !important;
@media screen and (min-width:1920px){
    width:88% !important;
    margin-left: auto;
    margin-right: auto;
}
.myhold-header {
    display: block;
    width: 95%;
    text-align: center;
    margin: auto;
    .filter-tabs{
        width: 100% !important;
        float: left;
        @media screen and (max-width:767px) {
            width: 100% !important;
        }
        div{
            // @media screen and (max-width:767px) {
            //     display: block;
            // }
        }
        Button{
            max-width: 100%;
            // @media screen and (max-width:767px) {
            //    min-width: 350px;
            // }
            span{
                font-size: 16px !important;
                // @media screen and (max-width:767px) {
                //     font-size: 9px !important;
                // }
            }
        }
    } 
}
}
.para-content{
    width: 100% !important;
    display: block;
    padding: 15px 35px;
}
.terms{
    h3{
        font-weight: bold;
        margin: 1rem 0px;
    }
    p{
        margin: 1rem 0px;
    }
    ul{
        list-style-type: disc;
        margin-left: 58px;
        @media screen and (max-width:767px) {
           margin-left: 0px;
         }
        li{
            padding-left: 20px;
        }
        margin-bottom: 1rem;
    }
    .filter-tabs{
        width:100% !important;
        Button{
            @media screen and (max-width:767px) {
           align-items: flex-start;
        text-align: left;
    justify-content: left;}
            span{
                @media screen and (max-width:767px) {
                   text-align: center;
                }
            }
        }
    }
  
}
.tabs{
    :nth-child(2){
        div{
            // @media screen and (max-width:767px) {
            //     width: 250px;
            //  }
    }
}
}
.box-tab {
    div
  {  :nth-child(2)
{
    @media screen and (max-width:767px) {
        width:100%;
     }
}
}
}
.tabs{
    :nth-child(2){
        Button{
            @media screen and (max-width:767px)
          {  display: block;
            min-width: 35px !important;
            width: 35px !important;}
        }
        div{
            Button{
                @media screen and (max-width:767px)
                {width: 100%;
                min-width: 270px !important;}
            }
        }
    }
}