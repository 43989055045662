::-webkit-scrollbar {
  width: 5px !important;
}

.MuiDialog-paperWidthSm-226 {
max-width: 100% !important;
width: 100% !important;
max-height: 100% !important;
}

.MuiPaper-elevation24-146 {
box-shadow: none !important;
}

.MuiBackdrop-root-308 {
background-color: rgba(0, 0, 0, 0.7) !important;
}

.MuiPaper-root-120 {
background: none !important;
}

.react-datepicker-popper {
position: absolute;
will-change: transform;
top: 0px;
left: 0px;
}

.datepiker {
padding: 15px;
font-size: 12px;
font-weight: 600;
font-style: normal;
font-stretch: normal;
line-height: 1.67;
letter-spacing: normal;
text-align: left;
color: rgb(98, 113, 122);}

.dialogActions {
span {
  cursor: pointer !important;
}
}

.closeDialog {
width: 22px;
height: 22px;
font-family: material;
font-size: 22px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
text-align: right;
color: #ffffff;
}

.dialog-content {
height: 100%;
width: 60%;
margin: auto;
}

.modalBox_content{
height: 492px;
}

.main_place_hold_section {
width: 600px;
border-radius: 6px;
background-color: #f0f0f0;
border: 2px solid white !important;
height: 100%;

.error {
    display: inline-block;
    width:auto;
}
}

.header {
width: 100%;
height: 60px;
background-color: #ffffff;
margin-top: 0;
padding: 15px;
border-bottom-style: solid !important;
border-bottom-width: 1px !important;
border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
border-image-slice: 1;
border-top: 0;
border-left: 0;
border-right: 0;
}
.success_modal_container .dialog-content {
height: 100% !important;
width: 100% !important;
margin: auto !important;
}
.success_modal_container {
// overflow-x: auto !important;
width: 100% !important;
min-height: auto !important;
margin-top: 30px;

.react-datepicker__navigation {
  padding: 0 !important;
}

.MuiDialog-paperWidthSm-226 {
  max-width: 100% !important;
  width: 100% !important;
  max-height: 100% !important;
}

.MuiPaper-elevation24-146 {
  box-shadow: none !important;
}

.MuiBackdrop-root-308 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.MuiPaper-root-120 {
  background: none !important;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  margin: 0px;
}

.MuiDialog-paper-222 {
  margin: 0 !important;
  position: absolute;
  top: 0;
}

.dialogActions {
  height: 40px;
  font-family: material;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0px;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  font-size: 30px;
  top: -70px;
  right: -38px;
  color: #ffffff;
  font-weight: bold;
}

// .dialog-content {
//   height: 100%;
//   width: 60%;
//   margin: auto;
// }

.modalBox_content{
  height: auto;
  background-color: #ffffff;
}

.main_place_hold_section {
  width: 600px;
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 2px solid white !important;
  height: 100%;

  .end-date-sm{
    @media screen and (max-width:767px){
      padding-top:20px;
    }
  }
}

.follow-inner-popup{
  display: block;
  padding: 15px;
  max-width:450px;
  font-size: 15px;
  line-height: 23px;
  button{
    margin-top: 15px;
    width: auto !important;
    padding: 5px 15px ;
  }
}

.header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.header-text {
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #36434b;
  float: left;
  margin-top: 5px;
}

.header-button-div {
  float: right;
}

.button-left {
  // margin-right: 25px;
}

.uploader {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
  border-image-slice: 1;
  background-image: url('../images/perm_contact_calendar.svg');
  background-repeat: no-repeat;
  background-size:cover;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-color: #f0f0f0;
}

.success_modal_container {
  min-height: 100px;
  overflow-y: auto !important
}

.uploader-content {
  width: 300px;
  height: 300px;
  font-family: material;
  font-size: 300px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #eae9e9;
  margin: 30px auto;
}

.red-text-content {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ee2738;
  margin: 140px 0 5px 0;
}

.black-text-content {
  font-family: Raleway;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #202020;
  margin-top: 5px;
}

.white-button-big {
  // height: 30px;
  border-radius: 6px;
  border: solid 1px #e1e1e1 !important;
  background-color: #ffffff;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  text-align: center;
  color: #ee2738 !important;
  float: left;
  cursor: pointer;

  span {
    width: 100%;
  }
}

.select_field {
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  font-family: Raleway;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #202020 !important;
  text-transform: none !important;
  width: 100%;
  height: 36px;
  margin: 0 !important;
  padding-left: 10px;
}

/* create new event form */

.form {

  .form-content {
    padding: 20px;
    display: inline-block;
  }

  .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px;
  }

  .header-button-div-form {
    float: right;
    width: 210px;
  }

  .dialogActions {
    height: 120px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    padding: 20px;
    margin: 0;
    display: block;

    span {
      cursor: pointer;
    }
  }

  .field-div {
    width: 100%;
    height: 80px;
    float: left;
    // position: relative;
    @media screen and (max-width:767px) {
          float: none !important;  
          // position: relative;
        }
  }
  .spacer.disp-none-sm{
    @media screen and (max-width:767px) {
          display: none;
    }
  }
  .sm-pd-start{
    @media screen and (max-width:767px) {
      padding-top: 20px;
      }
  }
  .sm-pd-req-up{
    @media screen and (max-width:767px) {
      margin-top: 20px;
      }
  }
  .sm-pd-end{
    @media screen and (max-width:767px) {
      margin-top: 20px;
      }
  }
  .sm-pd-req-down{
    @media screen and (max-width:767px) {
      padding-top: 20px;
      }
  }
  .sm-pd-req-seating ,.sm-pd-venue{
    @media screen and (max-width:767px) {
      padding-top: 20px;
      }
  }
  .sm-pd-req{
    @media screen and (max-width:767px) {
      padding-top: 5px;
      }
  }
  .disp-none-big {
    display: none;
    @media screen and (max-width:767px){
      display:block;
    }
    .sm-pd-seating{
      @media screen and (max-width:767px){
        padding-top: 20px !important;
      }
    }
  }

  .input-label {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    position: relative;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: 100%;
    float: left;
    padding-bottom: 5px;
  }

  .input-field {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: transparent;
    float: left;
    margin: 0 !important;
    font-size: 16px;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px;
    @media screen and (max-width:767px){
      padding-left: 15px;
      font-size: 15px !important;
    }
  }

  .input[role="combobox"]+div {
    z-index: 99;
    top: 35px !important;
    left: 0px !important;
    position: absolute !important;
    min-height: 95px !important;
    background: #fff;
    overflow-y: scroll;
    height: 150px;
    padding-left: 15px;
    box-shadow: 0px 15px 10px -15px #ccc;
    border: 1px solid #e1e1e1;
    font-weight: 500;
    cursor: pointer;
  }
  .city-sm{
    .suggestionDiv{
      padding-left: 15px;
    }
  }

  .input-field-section {
    width: 100%;
    float: left;
    color: #202020;
    position: relative;
  }

  .big {
    width: 100% !important;
  }

  .dark-black {
    color: #36434b !important;
  }

  .light-black {
    color: #202020 !important;
  }

  .lighter-black {
    color: #62717a !important;
  }

  .small {
    width: 46% !important;

    select {
      font-size: 15px !important;
    }

    input {
      font-size: 15px;
    }
  }

  .smaller {
    width: 40% !important;

    select {
      font-size: 12px !important;
      background-color: #ffffff !important;
      text-transform: uppercase !important;
      @media screen and (max-width:767px) {
        text-transform: capitalize !important;
        font-size: 15px !important;
        border: 1px solid #e1e1e1 !important;
  }
    }

    input {
      font-size: 12px;
      color: #1397e1 !important;
      text-align: left;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      @media screen and (max-width:767px) {
            font-size:15px;
            font-weight: normal;
            color: #202020;
            text-transform: capitalize;
            border: solid 1px #e1e1e1 !important;
      }
    }
  }

  .tall {
    height: 140px;
  }

  .spacer {
    width: 20%;
    padding: 25px 0 0 0;
    overflow: hidden;
    text-align: center;
  }

  select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    padding: 5px;
    -webkit-appearance: menulist;
    text-align: left;
    padding-left: 5px;
    width: 100%;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  .float-right {
    float: right;
  }

  .white-button-small {
    width: 100px !important;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    padding: 0 !important;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer;

    span {
      width: 100%;
    }
  }

  .red-button-small {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer;
  }

  @media screen and (max-device-width:767px){
    .smaller {
      width: 100% !important;
    }
    .small{
      width: 100%;
    }
    .modalBox_content{
      height: 100%;
      display: flex;
    }

    .newEventPopupContainer {
      width: 100% !important;
    }

    .small {
      width: 100% !important;
    }

    .header {
      height: 60px;
    }

    .header-text {
      width: 100%;
      text-align: center;
    }

    .header-button-div-form {
      width: 100%;
      margin: 10px auto;
      padding: 0 0px;
    }

    .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid;
    }

    .red-text-content {
      margin-top: 100px;
    }

    .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important;
    }
  }

}

/* Events */

.eventDiv {
  width: 98%;
  height: 80px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  margin: 10px auto 15px !important;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  .checkBoxDiv{
    display: flex;
    width: auto;
    align-items: center;
    label{
      .radio{
        margin: 0px 0px 50px 0px !important;
        :hover{
          color: #42a5f5;
        }
      }
    }
  }

    .blue-text {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      margin: 0;
    }
    .grey-date{
      color: #62717a;
    }
}

.events-content {
  height: 300px;
  font-family: material;
  font-size: 300px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #eae9e9;
  margin: 30px auto;
  overflow-y: scroll;
}

.checkBoxDiv {
  width: 5%;
  float: left;
  display: flex;
  height: 100%;
  align-items: center;
}

.detailsDiv {
  width: 95%;
  float: left;
  display: flex;
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width:767px){
    align-items:center;
  }

 

  .actionDiv {
    width: 22%;
    float: left;
    display: flex;
    height: 100%;
    align-items: center;

    .hand-icon {
      width: 9.5px;
      height: 19.7px;
      background-color: #62717a;
      margin-right: 10px;
    }

    .grey-text {
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      color: #62717a;
      margin: 0;
      min-width: 63px;
    }
  }
}

.checkBox {
  width: 20px;
  height: 20px;
  background-color: #9aa7af !important;
}

.large-text {
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #62717a;
  width: auto;
  word-break: break-word;
  cursor:pointer;
}
    .edit-icon{
      display: inline-flex;
      cursor: pointer;
    }

.eventsSelected {
  width: 100%;
  height: 380px;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 30px;

  .footerButtonDiv {
    width: 45% !important;
    margin: 20px auto;
  }

  .grey-button:disabled {
    height: 36px;
    border-radius: 6px;
    background-color: #9aa7af !important;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase !important;
    box-shadow: none;
  }

  .grey-button:enabled {
    height: 36px;
    border-radius: 6px;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  }

  .image {
    width: 38px;
    height: 38px;
    object-fit: contain;
    display: inline-block !important;
  }

  .large-text {
    font-family: Raleway;
    font-size: 24px !important;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin: 10px auto;
    cursor:pointer;
  }

  .description {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin: auto;
  }

  .blue-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1397e1;
    margin: 15px auto 0 auto;
  }

  .grey-text {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #9aa7af;
    margin: 20px auto 10px auto;
    text-transform: uppercase;
    @media screen and (max-width:767px){
      min-width:60px;
    }
  }

  .optionsDiv {
    width: 90%;
    margin: auto;
    display: block;
    overflow: auto;

    .option {
      width: 33%;
      float: left;
      text-align: center;
      label{
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    .option-text {
      width: 60%;
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #62717a;
      margin: auto;
    }

    .grey {
      color: #62717a !important;
    }

    .blue {
      color: #1f9ce3 !important;
    }

    .blue-circle {
      width: 20px;
      height: 20px;
      border: solid 2px #1397e1;
      background-color: #ffffff;
      margin-top: 30px;
    }
  }
}

.dialogFooter {
  height: 50px;
  border-radius: 6px;
  box-shadow: inset 0 1px 0 0 var(--white);
  background-color: #f7f7f7;
  display: flex;
  align-items: center;

  .buttonDiv {
    // width: 20%;
    margin: auto;
  }
}

.red-button {
  width: 150px;
  height: 30px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding-top: 6px;
  color: #ffffff;
  float: right;
  cursor: pointer;
  
}

@media screen and (max-width:767px){

  .dialog-content {
    height: 100%;
    width: 90% !important;
    margin: auto;
  }

  .modalBox_content{
    height: 100%;
  }

  .header {
    height: 60px;
    margin: auto;
  }

  .header-text {
    width: 100%;
    text-align: center;
  }

  .header-button-div {
    width: 80%;
    text-align: center;
    margin: 10px 0;
  }

  .uploader-content {
    margin-top: 20px;
    height: 100%;
    min-height: 250px;
    display: grid;
  }

  .red-text-content {
    margin-top: 100px;
  }

  .MuiDialog-paperWidthSm-226 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .eventDiv {
    margin: 0;
    padding: 10px;
    height: 100px;
  }

  .checkBoxDiv {
    width: 10% !important;
  }

  .detailsDiv {
    width: 90% !important;
    position: relative;
   }

  .large-text {
    width: 100% !important;
    cursor:pointer;
  }

  .actionDiv {
    width: 100% !important;
    height: 70% !important;
    justify-content: flex-end;
  }

  .eventsSelected {
    height: auto;
    padding: 10px;
  }

  .optionsDiv {
    margin: 0 !important;
    width: 100% !important;
  }

  .option {
    width: 80% !important;
    float: left;
    align-items: center;
    display: flex;
    margin: auto;

    .option-text {
      float: left;
      text-align: left !important;

    }

    input {
      float: left;
      margin: 0 !important;
    }
  }

  .footerButtonDiv {
    width: 130px !important;
    margin: 20px auto;
  }

  // .main_place_hold_section {
  //   margin: 5px auto;
  // }
  // #eventsContent{
  //   height: auto !important;
  // }
}
}
.MuiDialog-paper-235 {
margin: 20px !important;
}
.event-loading{
  .load-box{
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding-top: 50px;
    padding-bottom: 50px;
    @media screen and (max-width:767px) {
      
    }
  }
}
input#city{
border-radius: 6px;
border: solid 1px #e1e1e1 !important;
background-color: #f7f7f7;
width: 100%;
padding: 9px 15px;
font-size: 15px;
line-height: 1.07;
text-align: left;
color: #202020;
}
.suggestionDiv {
width: 100% !important;
position: relative;
font-size: 12px;
line-height: 2.3;
word-break: break-all;
}
.success_modal_container div:nth-child(2) div:first-child{
max-width: 600px !important;
}
.detailsDiv .actionDiv .delete::before , .detailsDiv .actionDiv .delete::after{
display: none;
}
.detailsDiv .actionDiv .delete{
background-color: transparent;
margin-top: -5px;
}
.Contact-Venue-popup {
padding-bottom: 20px;
.header{
  border: 0px;
  text-align: center;
  display: block;
  height: auto;
  padding-bottom: 0px;
  .header-text{
    text-align: center;
    float: none;
    color: #1397e1;
    @media screen and (max-width:767px) {
        font-size: 15px !important;
    }
  }
}
button{
  span{
    color: #ffffff;
  }
}
.message-section{
  padding: 10px 15px;
  line-height: 1.7;
  @media screen and (max-width:767px) {
    font-size: 13px !important;
}
}
.contact-box{
  padding:16px 12% 4px 12%;
  .dont_p{
    width: 100%;
    text-align: center !important;
  }
}
}
.place-hold-popup div:nth-child(2) div:first-child{
@media screen and (max-width:767px){
  max-width: 280px !important;
}
}
.place-hold-popup
{
.modalBox_content{
  .add-new-form-block{
  .custom_select_with_scroll>div{
    padding: 0px 5px 0px 15px;
  }
}
}
.modalBox_content{
  .add-new-form-block{
    @media screen and (max-width:767px){
      padding: 10px 20px 20px;
      height: 312px;
  }

  .disp-none-small{
    @media screen and (max-width:767px) {
      display: none;
      }
  }
  
  .field-div{
    @media screen and (max-width:767px){
     height: auto;
  }
  }
  .smaller{
    input{
      padding: 0px 14px;
    }
    svg{
      right: -7px;
      @media screen and (max-width:767px){
          right: 0px;
      }
    }
  }
}
}
.error{
  display: block;
  position: absolute;
  bottom: -19px;
}
.add-new-form-block{
  height: 440px;
  @media screen and (min-width:1600px){
    height: 560px;
  }
  @media screen and (max-width:767px){
    height: 326px;
  }
}
.grey-text{
  text-align: center;
  cursor: pointer;
}
.select-down-icon::before{
  content: 'arrow_drop_down';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 0.8;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 8px;
  right: 5px;
  // z-index: 3;
  color: #62717a;
}

.select-down-icon{
  position: relative;
}
.form{
.smaller {
  select{
    background-color: transparent !important;
  }
}
}
.events-content{
 height: 292px;
}
#eventsContent{
@media screen and (max-width:767px){
  margin: 18px auto 0px !important;
}
}
.header-button-div{
@media screen and (max-width:767px){
  margin: 10px 0 0px;
  width: 85%;
}
}
}
.add-new-form-block
{
overflow-y: scroll;
height: 422px;
}
.follow-date-popup div:nth-child(2) div:first-child{
@media screen and (max-width:767px){
  max-width: 280px !important;
}
}
.follow-date-popup
{
.detailsDiv{
  @media screen and (max-width:767px){
    width: 100% !important;
  }
}
#eventsContent{
  padding-left: 10px;
  padding-right: 6px;
@media screen and (max-width:767px){
  margin: 5px auto 0px !important;
}
.-text {
  @media screen and (max-width:767px){
    font-size: 15px;
  }
}
button{
  @media screen and (max-width:767px){
    width:90px !important;
  }
}
}
.events-content {
margin: 5px auto 10px;
height: 290px;
}
}

::-webkit-input-placeholder {
/* Edge */
color: rgb(98, 113, 122) !important;
}


:-ms-input-placeholder {
/* Internet Explorer 10-11 */
color: rgb(98, 113, 122) !important;
}
.follow-up-signin > div{
width: 100%;
}
.follow-date-popup  div:nth-child(2) div:first-child{
width: 100%;
}
.city-section{
input[role="combobox"]+div {
  z-index: 99;
  top: 35px !important;
  left: 0px !important;
  position: absolute !important;
  min-height: 95px !important;
  background: #fff;
  overflow-y: scroll;
  height: 160px;
  box-shadow: 0px 15px 10px -15px #ccc;
  border: 1px solid #e1e1e1;
  font-weight: 500;
  cursor: pointer;
}
.suggestionDiv{
  width: 100% !important;
  padding-left: 15px;
}
}

.what-we-love-block{
padding-right: 25px;
margin-top: 25px;
@media screen and (max-width:767px){
  margin: 15px auto 10px;
}
.what-we-love-listing{
  display: block;
  margin-left: 45px;
  width: 100%;
  margin-bottom: 25px;

  ul{
    display: block;
    width: 50%;
    float: left;
    list-style: none;
    padding-left: 20px;
    @media screen and (max-width:767px){
      width:100%;
    }
    li{
      position: relative;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      
    }
    li:before{
      content: "\2022";
      color:#3C97E1;
      font-weight: bold;
      position: absolute;
      display: inline-block; 
      width: 1em;
      margin-left: -1em;
      font-size: 25px;
      top: 0;
      margin-top: -8px;
    }
  }
}
}
.placehold-event{
.detailsDiv{
  width: 20% !important;
  @media screen and (max-width:767px) {
    width: 40% !important;
  }
  .actionDiv{
      width: 100%;
      .grey-text{
        @media screen and (max-width:767px) {
          min-width: 48px;
        }
      }
  }
}
.detailsDivLeft{
  display: flex;
  width: auto;
  align-items: center;
  .edit-icon{
    @media screen and (max-width:767px) {
     padding-left: 5px;
    }
  }
}
.checkBoxDiv{
  display: flex;
  width: auto;
  align-items: center;
  @media screen and (max-width:767px) {
    width:50% !important;
  }
  .radioTextLeft{
   width: 100%;
   display: flex;
   align-items: center;
   div{
      width: 10%;
      display: inline-block;
      label{
        .radio{
          margin: 0px 0px 0px 0px !important;}}
   }
   .radioText{
     width: 90%;
     display: inline-block;
     padding-left: 10px;
     p{
      @media screen and (max-width:767px) {
        font-size: 13px;
      }
     }
   }
   .large-text{
     display: flex;
   }
   .grey-date{
     display: flex;
   }
  label{
    .radio{
      margin: 0px 0px 50px 0px !important;
      :hover{
        color: #42a5f5;
      }
    }
  }
}
}
}
.venue-link{
  color: #f12331;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.link-container{
width: 168px;
position: absolute;
left: 47%;
margin-left: -50px;
}

.add-new-event-popup{
    .modalBox_content {
        padding: 0;
    }
    .form-content.add-new-form-block {
        height: 380px !important;
        overflow-y: scroll !important;
    }
    .smaller input {
        font-size: 12px !important;
        padding: 6px !important;
    }
    .input-label {
        font-size: 11px !important;
    }
    .custom_select_with_scroll > div {
        padding: 0px 5px 0px 5px !important;
    }
    // .input-label.sm-pd-req-up,
    // .input-label.sm-pd-seating{
    //     height: 35px;
    // }
    @media screen and (max-width:767px) {
        .red-button-small,
        .white-button-small {
            width: 66px !important;
        }
        .add-new-form-block {
            height: 250px !important;
        }
        .place-hold-popup div:nth-child(2) div:first-child {
            max-width: 500px !important;
        }
    }
    @media screen and (max-width:480px) {
        .place-hold-popup div:nth-child(2) div:first-child {
            max-width: 250px !important;
        }
    }
    
}

.eventsSelected{
  .optionsDiv .option-text {
    width: 90% !important;
  }
  p.powerby {
    font-size: 12px;
    width: 100%;
    }
}
.datepiker.color-blue{
  color: #1397e1 !important;
}

.eventsSelected{
    .optionsDiv{
            label {
            margin: 0 !important;
        }
    }
    .footerButtonDiv{
        .grey-button:disabled{
            margin-top: 30px;
        }
    }
    .mt-0{
      margin-top: 0 !important;
    }
}

.eventsSelected .footerButtonDiv .grey-button {
  margin-top: 30px;
}

@media screen and (max-width:767px) {
  .place-hold-popup div:nth-child(2) div:first-child {
        max-width: 600px !important; 
    }
}